import { useEffect, useState } from "react";
// import { useLocation } from"react-router-dom";
import { ISidebarContainerProps } from "./Sidebar.interfaces";
import SidebarView from "./Sidebar.view";
import { DashboardUserService } from "../../Services";

export default function SidebarContainer(props: ISidebarContainerProps) {
    const [btnColor, setBtnColor] = useState("Dashboard");
    const [branch, setBranch] = useState(false);
    const [metaverses, setMetaverses] = useState<any[]>([]);

    const ButtonColor = (i: string) => {
        setBtnColor(i);
    };
    const SetBranch = (i: boolean) => {
        setBranch(i);
    };

    const fetchMetaverses = async () => {
        // Add this to redux, useSelector, if array is empty fetch and store to redux => TP | 2024-01-30 16:53:21
        try {
            const data = await DashboardUserService.fetchMetaverses(); // Add pagination => TP | 2024-01-30 17:12:40
            setMetaverses(data.data.metaverses);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchMetaverses();
    }, []);

    return (
        <>
            <SidebarView
                metaverses={metaverses}
                ButtonColor={ButtonColor}
                SetBranch={SetBranch}
                branch={branch}
                btnColor={btnColor}
            />
        </>
    );
}
