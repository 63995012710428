import { useEffect, useState } from "react";
import { INavigationContainerProps } from "./Navigation.interfaces";
import NavigationView from "./Navigation.view";

export default function NavigationContainer(props: INavigationContainerProps) {
    const [showMenu, setShowMenu] = useState(false);
    const [showDemo, setShowDemo] = useState(false);
    const [showProducts, setShowProducts] = useState(false);
    const [scroll, setScroll] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        window.addEventListener("scroll", function () {
            if (window.scrollY > 0) {
                setScroll(true);
            } else {
                setScroll(false);
            }
        });
        return () => {
            if (screenWidth <= 768) {
                setShowProducts(false);
                setShowDemo(false);
            }
        };
    });

    const menuController = () => setShowMenu(!showMenu);
    const demoController = () => setShowDemo(!showDemo);
    const productController = () => setShowProducts(!showProducts);
    const scrollController = () => setScroll(!scroll);
    const dropdownController = () => setShowDropdown(!showDropdown);
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };
    return (
        <>
            <NavigationView
                menuController={menuController}
                demoController={demoController}
                productController={productController}
                showMenu={showMenu}
                showDemo={showDemo}
                showProducts={showProducts}
                scroll={scroll}
                scrollController={scrollController}
                showDropdown={showDropdown}
                dropdownController={dropdownController}
                scrollToTop={scrollToTop}
            />
        </>
    );
}
