import { ILoginViewProps } from "./DashboardLoginBox.interfaces";
import Message from "../Message";
export default function LoginBoxView(props: ILoginViewProps) {
    return (
        <>
            {props.showMessage && (
                <Message
                    messageText={props.messageText}
                    messageClass={props.messageClass}
                    demoController={props.messageController}
                />
            )}
            <div
                className="bg-cover h-screen font-['roboto']"
                style={{
                    backgroundImage: `url('../images/login/loginbackground.webp')`,
                }}
            >
                <section className="max-w-screen-2xl px-2 mx-auto mx-2 px-8 max-md:px-1">
                    <div className="grid grid-cols-[6fr_4fr] h-screen items-center max-md:grid-cols-1">
                        <div></div>
                        <div className="max-md:px-2 text-left">
                            <div>
                                <img
                                    alt=""
                                    src="../images/whitelogo.webp"
                                    className="w-[300px]"
                                />

                                <h5 className="text-[#489BF6] my-10">Log In</h5>
                            </div>
                            <div>
                                <div className="grid grid-cols-1 loginform">
                                    <div>
                                        <label>Email</label>
                                        <input
                                            type="text"
                                            name="email"
                                            placeholder="Email"
                                            onChange={props.emailController}
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label>Password</label>
                                        <input
                                            type="password"
                                            name="password"
                                            placeholder="Password"
                                            onChange={props.passwordController}
                                            onKeyDown={props.handleLogin}
                                        />
                                    </div>

                                    <button
                                        className="bg-blue-100 text-white w-full py-3 font-[600] hover:scale-[1.05] transition duration-300 ease-in-out hover:bg-gradient-to-l rounded-md mr-4 bg-gradient-to-r from-blue-500 to-purple-600"
                                        onClick={props.handleLogin}
                                    >
                                        Login
                                    </button>
                                </div>
                            </div>
                            <div className="font-barlow font-bold cursor-pointer hover:scale-[1.05] transition duration-300">
                                <p className="text-white text-center">
                                    Forgot your Password? &nbsp;{" "}
                                    <u className="text-[#489BF6]">
                                        Reset Password
                                    </u>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}
