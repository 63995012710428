import { IUserLoginViewProps } from "./UserLoginBox.interfaces";
import Message from "../Message";
import data from "../CountryCodes.json";
export default function UserLoginBoxView(props: IUserLoginViewProps) {
    return (
        <>
            {props.showMessage && (
                <Message
                    messageText={props.messageText}
                    messageClass={props.messageClass}
                    demoController={props.messageController}
                />
            )}
            <div className="fixed left-0 top-0 z-[99] h-screen w-full grid items-center justify-center">
                <div
                    className="absolute left-0 top-0 bg-[#00000060] z-[999] w-full h-screen"
                    onClick={() => props.loginController()}
                ></div>
                <div className="z-[9999999] h-[650px] max-w-full w-[500px] max-md:w-full max-md:h-full max-md:py-20 max-md:px-4">
                    <div className="border shadow-2xl rounded-xl bg-white max-md:mt-0 py-5 text-left">
                        <div className="flex justify-end">
                            <div
                                className="w-[30px] bg-[#6B6B6B7a] mr-5 mt-5 rounded-full pl-[7px]"
                                onClick={() => props.loginController()}
                            >
                                <i className="fa fa-close text-xl text-white cursor-pointer"></i>
                            </div>
                        </div>
                        <h3 className="text-[#1C2347] text-[27px] font-[700] leading-[32px] pb-8 px-10 max-md:px-4">
                            Log in{" "}
                            <span className="text-[#136CC5]">Eternity</span>
                        </h3>

                        {props.otpStatus === false && (
                            <>
                                <form
                                    className="px-10 max-md:px-4 grid"
                                    onSubmit={props.signinWithPhoneNumber}
                                >
                                    {" "}
                                    <label className="text-[#1C2347] font-[700] text-[14px]">
                                        Phone Number
                                    </label>
                                    <div>
                                        <div className="relative flex items-center bg-[#E7E7E7DB] text-sm font-normal border border-grey-500 rounded-lg">
                                            <div
                                                onClick={props.toggleDropdown}
                                                className="flex items-center p-4 cursor-pointer w-[90px] hover:bg-[#489BF6] group hover:rounded-lg"
                                            >
                                                <p className="font-[600] text-gray-700 group-hover:text-white">
                                                    {props.country}
                                                </p>
                                                <p className="font-[600] text-gray-700 group-hover:text-white pl-1">
                                                    {props.phoneCode}
                                                </p>

                                                <i className="fa fa-angle-down pl-1 group-hover:text-white"></i>
                                            </div>
                                            {props.isOpen && (
                                                <div className="absolute z-[9999] mt-56 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 h-[150px] w-[200px] overflow-y-auto">
                                                    <input
                                                        id="search-input"
                                                        className="block w-full px-4 py-2 text-gray-800 border rounded-md border-gray-300 focus:outline-none"
                                                        type="text"
                                                        placeholder="Search items"
                                                        onChange={
                                                            props.handleSearchInput
                                                        }
                                                    ></input>
                                                    {data
                                                        .filter((item) =>
                                                            item.name
                                                                .toLowerCase()
                                                                .includes(
                                                                    props.searchterm.toLowerCase()
                                                                )
                                                        )
                                                        .map((item, i) => (
                                                            <li
                                                                key={i}
                                                                className="block px-6 py-2 text-[14px] text-gray-700 text-center hover:bg-[#489BF6] hover:text-white font-[600] cursor-pointer rounded-md"
                                                                value={
                                                                    item.dial_code
                                                                }
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    props.togglePhone(
                                                                        "+" +
                                                                            e.currentTarget.value.toString()
                                                                    );
                                                                    props.toggleCountry(
                                                                        item.code
                                                                    );
                                                                    props.toggleDropdown();
                                                                }}
                                                            >
                                                                {item.name}(
                                                                {item.dial_code}
                                                                )
                                                            </li>
                                                        ))}
                                                </div>
                                            )}
                                            <input
                                                className="w-full text-grey py-3 px-4 max-md:px-2 bg-[#E7E7E7DB] focus:outline-none input-number"
                                                type="number"
                                                placeholder="Enter phone no."
                                                required
                                                value={props.phoneNumber}
                                                onChange={props.handleEntry}
                                                onKeyDown={
                                                    props.signinWithPhoneNumber
                                                }
                                            />
                                        </div>
                                    </div>
                                    <button className="py-3 w-full my-3 font-[700] text-white transition duration-300 ease-in-out hover:scale-[1.05] rounded-lg mr-4 hover:bg-gradient-to-l bg-gradient-to-r from-blue-500 to-purple-600">
                                        Send OTP
                                    </button>
                                </form>
                                <div
                                    id="recaptcha-container"
                                    className="px-10 max-md:px-4 flex justify-center"
                                ></div>
                            </>
                        )}
                        {props.otpStatus === true && (
                            <form
                                className="px-10 max-md:px-4"
                                onSubmit={props.OtpConfirmation}
                            >
                                <label className="text-[#1C2347] font-[700] text-[14px]">
                                    OTP
                                </label>
                                <input
                                    className="w-full text-grey bg-[#E7E7E7DB] text-sm font-normal border border-grey-500 rounded-lg py-3 px-4 max-md:px-2 focus:outline-none"
                                    type="text"
                                    placeholder="Enter OTP"
                                    required
                                    value={props.otp}
                                    onChange={(e) =>
                                        props.handleOTPEntry(e.target.value)
                                    }
                                />
                                <button className="border-[1px] border-[#000000] bg-[#FFF8F840] text-[#1C2347] py-3 w-full my-3 font-[700] hover:text-white transition duration-300 ease-in-out hover:scale-[1.05] rounded-lg mr-4 hover:bg-gradient-to-r from-blue-500 to-purple-600">
                                    Submit
                                </button>
                            </form>
                        )}
                        <div className="py-5 px-10 max-md:px-4 text-[#6B6B6B8F] font-[700]">
                            <hr />
                            <p className="text-center -mt-[14px]">
                                <span className="bg-[#FFFFFF] px-2">Or</span>
                            </p>
                        </div>
                        <div className="px-10 max-md:px-4">
                            <button
                                onClick={props.signinWithGoogle}
                                className="border-[1px] border-[#000000] bg-[#FFF8F840] text-[#1C2347] font-[700] mb-3 w-full max-md:mt-2 transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-gradient-to-r from-blue-500 to-purple-600 hover:text-white px-8 py-3 rounded-lg"
                            >
                                <i className="fa fa-google pr-2"></i>Login with
                                Google
                            </button>
                            <button className="border-[1px] border-[#000000] bg-[#FFF8F840] text-[#1C2347] font-[700] mb-3 w-full max-md:mt-2 transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-gradient-to-r from-blue-500 to-purple-600 hover:text-white px-8 py-3 rounded-lg">
                                <i className="fa fa-apple pr-2"></i>Login with
                                Apple ID
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
