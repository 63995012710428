import { createContext, useEffect, useState } from "react";
import { SessionService } from "../Services";

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [user, setUser] = useState<any>(null);

    useEffect(() => {
        const userType = SessionService.get("user-type");
        const accessToken = SessionService.get("accessToken");
        const refreshToken = SessionService.get("refreshToken");
        if (!userType || !accessToken || !refreshToken) {
            SessionService.clear();
            setUser(null);
            return;
        }
        const _user = {
            data: JSON.parse(SessionService.get("user") ?? "{}"),
            userType,
            accessToken,
            refreshToken,
        };
        setUser(_user);
    }, []);

    return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};
