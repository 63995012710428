import { useState } from "react";

import { ILoginContainerProps } from "./DashboardLoginBox.interfaces";
import LoginBoxView from "./DashboardLoginBox.view";
import { DashboardUserService, SessionService } from "../../Services";

export default function LoginContainer(props: ILoginContainerProps) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleLogin = async (e: any) => {
        if (e.key && e.key !== "Enter") return;
        const data = await DashboardUserService.login(email, password);
        if (data && data.data) {
            SessionService.set("user-type", "dashboard-user");
            SessionService.set("user", JSON.stringify(data.data.dashboardUser));
            SessionService.set("accessToken", data.data.accessToken);
            SessionService.set("refreshToken", data.data.refreshToken);
            setShowMessage(true);
            setMessageClass("success");
            setMessageText("Login successfully");
            setTimeout(function () {
                window.location.href = "/dashboard/home";
            }, 1000);
        } else {
            setShowMessage(true);
            setMessageClass("error");
            setMessageText(
                data.response.data.message === "DashboardUser not found"
                    ? "User not found!"
                    : data.response.data.message === "Unauthorized"
                      ? "Wrong Password!"
                      : data.response.data.errors.details[0].message
            );
            setTimeout(function () {
                setShowMessage(false);
            }, 2000);
        }
    };

    const emailController = (event: React.ChangeEvent<HTMLInputElement>) =>
        setEmail(event.target.value);
    const passwordController = (event: React.ChangeEvent<HTMLInputElement>) =>
        setPassword(event.target.value);
    const [messageText, setMessageText] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const messageController = () => setShowMessage(!showMessage);

    return (
        <>
            <LoginBoxView
                handleLogin={handleLogin}
                emailController={emailController}
                passwordController={passwordController}
                messageController={messageController}
                messageText={messageText}
                messageClass={messageClass}
                showMessage={showMessage}
            />
        </>
    );
}
