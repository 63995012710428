import Navigation from "../Navigation";
import { IVrBankViewProps } from "./VrBank.interfaces";
import BookDemo from "../BookDemo";
import UserLoginBox from "../UserLoginBox";
export default function VrBankView(props: IVrBankViewProps) {
    return (
        <>
            {props.showlogin === true && (
                <UserLoginBox
                    redirectionURL="/metaverse/65b741def8309762720ad53f"
                    loginController={props.loginController}
                /> // Since the line 122, says Login to Eternity City, but can be changed later => TP | 2024-01-30 14:39:44
            )}
            {props.showDemo === true && (
                <BookDemo demoController={props.demoController} />
            )}
            <div className="font-['roboto']">
                <section className="relative h-auto">
                    <video
                        autoPlay
                        muted
                        loop
                        playsInline
                        className="absolute top-0 left-0 w-full h-full object-cover"
                    >
                        <source
                            src="../images/vrbank/vrbankheadervideo.webm"
                            type="video/webm"
                        />
                        Your browser does not support the video tag.
                    </video>
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                    <Navigation />
                    <div className="relative z-10 flex flex-col items-center justify-center h-full text-white">
                        <div className="text-left max-w-screen-2xl mx-auto max-md:px-2">
                            <div className="text-center py-72 max-md:py-48">
                                <div className="mx-20 max-md:m-2 max-md:text-center">
                                    <h3 className="my-10 max-md:mt-10 max-md:mb-5 text-5xl max-md:text-2xl text-white">
                                        <span className="text-[#136CC5] bg-[#136CC5]/[0.30] shadow-inner">
                                            VR Bank
                                        </span>{" "}
                                        : A Digital Banking Revolution
                                    </h3>
                                    <p className="mb-20 max-md:mb-2 text-white mx-20 max-md:mx-2 max-md:text-xs text-[18px]">
                                        Where banking meets the future. We're
                                        thrilled to introduce you to a
                                        groundbreaking way of banking that's set
                                        to revolutionize your financial
                                        experience. Step into the virtual world
                                        of VRBank and discover a new era of
                                        banking convenience, accessibility, and
                                        innovation.
                                    </p>

                                    <button
                                        onClick={props.loginController}
                                        className="border-2 bg-[#FFF8F840] text-white max-md:mt-2 transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-[#FFF8F860] px-8 py-3 rounded-md"
                                    >
                                        Login to Eternity City
                                        <i className="fa fa-long-arrow-right ml-4"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="text-center mt-14 max-w-screen-2xl mx-auto max-md:px-2 my-10 max-md:my-4">
                        <h3 className="text-darkblue text-4xl">
                            Your Virtual Banking Experience
                        </h3>
                        <p className="text-darkblue pt-5">
                            With VRBank, you'll experience
                        </p>
                    </div>
                </section>
                <section className="bg-[#37AEF108]">
                    <div className="grid grid-cols-2 items-center max-md:grid-cols-1 max-w-screen-2xl mx-auto max-md:px-2 max-md:p-5">
                        <div>
                            <img
                                src="../images/vrbank/virtualbanking.webp"
                                alt=""
                            />
                        </div>
                        <div className="text-darkblue mt-10 w-9/12 mx-14 text-left">
                            <ul className="list-disc">
                                <li className="lists">
                                    {" "}
                                    <h3>Account Management</h3>{" "}
                                    <p>
                                        Easily manage your accounts, check
                                        balances, and view transaction history.
                                    </p>
                                </li>
                                <li className="lists">
                                    <h3>Digital Banking Services</h3>{" "}
                                    <p>
                                        Open accounts, apply for loans, and
                                        access a wide range of banking services
                                        with a few clicks
                                    </p>
                                </li>
                                <li className="lists">
                                    <h3>Interactive ATM Service</h3>{" "}
                                    <p>
                                        Locate and use virtual ATMs within the
                                        Metaverse for cash withdrawals and
                                        deposits.
                                    </p>
                                </li>
                                <li className="lists">
                                    <h3>Personalized Financial Advice</h3>{" "}
                                    <p>
                                        Get tailored financial advice from our
                                        virtual bankers to help you make
                                        informed decisions
                                    </p>
                                </li>
                                <li className="lists">
                                    <h3>Educational Resource</h3>{" "}
                                    <p>
                                        Access financial literacy resources,
                                        investment insights, and money-saving
                                        tips
                                    </p>
                                </li>
                                <li className="lists">
                                    <h3>Virtual Branch Event</h3>{" "}
                                    <p>
                                        Attend virtual branch events, seminars,
                                        and webinars to enhance your financial
                                        knowledge
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="text-center max-w-screen-2xl mx-auto max-md:px-2 mt-10 max-md:mt-5">
                        <h3 className="text-darkblue text-4xl">Why VRBank?</h3>
                        <p className="mx-48 max-md:mx-2 my-5 font-['roboto'] text-darkblue">
                            VRBank isn't just another banking platform; it's a
                            digital banking revolution. We've harnessed the
                            power of the Metaverse to create a banking
                            experience like no other. Here's why you should make
                            the switch to VRBank:
                        </p>
                    </div>
                </section>
                <section
                    className="bg-no-repeat bg-cover"
                    style={{
                        backgroundImage: `url('./images/vrbank/backdropvrbank.webp')`,
                    }}
                >
                    <section className="max-w-screen-2xl mx-auto max-md:px-2 my-20 py-20 max-md:py-2 max-md:my-2">
                        <div className="grid max-lg:grid-cols-2 grid-cols-3 max-sm:grid-cols-1">
                            <div className="div-cards">
                                <img
                                    src="../images/vrbank/logos/first.svg"
                                    alt=""
                                />
                                <h5>Immersive Banking</h5>
                                <p>
                                    Say goodbye to the limitations of
                                    traditional banking. With VRBank, you can
                                    explore a virtual branch that feels just
                                    like the real thing. Engage with our digital
                                    bankers in a 3D environment, making your
                                    banking interactions more engaging and
                                    personal.
                                </p>
                            </div>
                            <div className="div-cards">
                                <img
                                    src="../images/vrbank/logos/second.svg"
                                    alt=""
                                />
                                <h5>Anywhere, Anytime Access</h5>
                                <p>
                                    VRBank goes where you go. Access your
                                    accounts, make transactions, and manage your
                                    finances from the comfort of your home or on
                                    the go. Our virtual branch is open 24/7,
                                    providing you with ultimate flexibility
                                </p>
                            </div>
                            <div className="div-cards">
                                <img
                                    src="../images/vrbank/logos/third.svg"
                                    alt=""
                                />
                                <h5>Next-Level Security</h5>
                                <p>
                                    Security is our top priority. VRBank employs
                                    cutting-edge security measures to ensure
                                    your financial data is protected. Feel
                                    confident knowing your transactions are safe
                                    and your information is secure
                                </p>
                            </div>
                            <div className="div-cards">
                                <img
                                    src="../images/vrbank/logos/fourth.svg"
                                    alt=""
                                />
                                <h5>Personalized Service</h5>
                                <p>
                                    We believe in combining technology with the
                                    human touch. Our virtual bankers are here to
                                    assist you just like at a physical branch.
                                    They'll provide personalized advice and
                                    support to help you reach your financial
                                    goals
                                </p>
                            </div>
                            <div className="div-cards">
                                <img
                                    src="../images/vrbank/logos/fifth.svg"
                                    alt=""
                                />
                                <h5>Innovative Tools</h5>
                                <p>
                                    VRBank isn't just a virtual branch; it's a
                                    financial toolbox. Access innovative
                                    financial management tools, interactive
                                    financial planning, and detailed account
                                    insights—all at your fingertips.
                                </p>
                            </div>
                            <div className="div-cards">
                                <img
                                    src="../images/vrbank/logos/sixth.svg"
                                    alt=""
                                />
                                <h5>Virtual Community</h5>
                                <p>
                                    Connect with other VRBank users in the
                                    virtual community. Share financial tips,
                                    join discussions, and attend financial
                                    literacy events—all within the Metaverse.
                                </p>
                            </div>
                        </div>
                    </section>
                </section>

                <section className="relative h-auto">
                    <video
                        autoPlay
                        muted
                        loop
                        playsInline
                        className="absolute top-0 left-0 w-full h-full object-cover"
                    >
                        <source
                            src="../images/vrbank/subvideoofbank.webm"
                            type="video/webm"
                        />
                        Your browser does not support the video tag.
                    </video>
                    <div className="absolute inset-0"></div>
                    <div className="relative z-10 flex flex-col items-center justify-center h-full text-white">
                        <div className="text-left max-w-screen-2xl mx-auto max-md:px-2 grid grid-cols-2 max-md:block">
                            <div></div>
                            <div className="bg-darkblue opacity-80">
                                <div className="py-20">
                                    <h5 className="text-4xl font-bold text-white mx-5 max-md:mx-2">
                                        Join the Banking Revolution
                                    </h5>
                                    <p className="font-['roboto'] text-white font-medium py-5 mx-5 max-md:mx-2">
                                        VRBank is more than a digital banking
                                        platform; it's a virtual banking
                                        experience that's reshaping the future
                                        of finance. Join us on this exciting
                                        journey into the Metaverse, where your
                                        financial convenience and empowerment
                                        are our top priorities.
                                    </p>
                                    <button
                                        onClick={props.demoController}
                                        className="mx-5 max-md:mx-2 mt-10 bg-blue-100 font-[700] px-10 py-3 text-white transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-gradient-to-l rounded-md mr-4 bg-gradient-to-r from-blue-500 to-purple-600"
                                    >
                                        Book a Demo
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section
                    className="bg-cover p-24 max-md:p-10 text-center"
                    style={{
                        backgroundImage: `url('./images/vrbank/studentbackpack.webp')`,
                    }}
                >
                    <div className="bg-[#030303B0] text-white max-w-screen-2xl mx-auto max-md:px-2 p-24 max-md:p-4">
                        <h3 className="text-3xl px-24 max-xl:px-0">
                            Ready to Get Started?
                        </h3>
                        <p className="py-10 px-36 max-xl:px-2 max-xl:py-5 font-['roboto']">
                            Take the first step into the future of banking with
                            VRBank. Sign up today and unlock a world of
                            possibilities for your finances. Your digital
                            banking revolution begins here, at VRBank.
                        </p>

                        <button
                            onClick={props.loginController}
                            className="border-2 bg-[#FFF8F840] text-white max-md:mt-2 transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-[#FFF8F860] px-8 py-3 rounded-md"
                        >
                            Login to Eternity City
                            <i className="fa fa-long-arrow-right ml-4"></i>
                        </button>
                    </div>
                </section>
            </div>
        </>
    );
}
