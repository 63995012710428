import { ISuperAdminMyAccountViewProps } from "./SuperAdminMyAccount.interfaces";

export default function SuperAdminMyAccountView(
    props: ISuperAdminMyAccountViewProps
) {
    return (
        <>
            <div>
                <div className="border-2 border-gray-300 rounded-lg bg-white font-['roboto'] m-4 h-[calc(100vh-150px)] max-md:h-[auto]">
                    <div className="grid grid-cols-[250px_1fr] max-md:block h-full">
                        <div className="border-r-2 max-md:border-r-0 max-md:border-b-2 max-md:flex max-md:justify-between ml-5 mr-2 max-md:mx-0 p-3 max-md:p-1">
                            <div
                                className={`${
                                    props.selection === "basic-details"
                                        ? "bg-[#489BF633] duration-300 border-l-8 max-md:border-l-0 text-[#136CC5] rounded-r-full max-md:rounded-none border-l-[#136CC5]"
                                        : "border-l-8 border-l-white max-md:border-l-0"
                                } my-4 py-2 px-2 max-md:px-1 max-md:my-2 text-grey font-medium font-['roboto'] flex items-center cursor-pointer`}
                                onClick={() =>
                                    props.handleSelection("basic-details")
                                }
                            >
                                <i className="fa fa-user-o"></i>
                                <p className="mx-4 max-md:mx-2">
                                    Basic details
                                </p>
                            </div>
                            <div
                                className={`${
                                    props.selection === "reset-password"
                                        ? "bg-[#489BF633] duration-300 border-l-8 max-md:border-l-0 text-[#136CC5] rounded-r-full max-md:rounded-none border-l-[#136CC5]"
                                        : "border-l-8 border-l-white max-md:border-l-0"
                                } my-4 py-2 px-2 max-md:px-1 max-md:my-2 text-grey font-medium font-['roboto'] flex items-center cursor-pointer`}
                                onClick={() =>
                                    props.handleSelection("reset-password")
                                }
                            >
                                <i className="fa fa-key"></i>
                                <p className="mx-4 max-md:mx-2">
                                    Reset password
                                </p>
                            </div>
                        </div>
                        <div>
                            {props.selection === "basic-details" && (
                                <>
                                    <h5 className="font-semibold font-['roboto'] text-2xl text-darkblue p-3 my-7 max-md:my-5 max-md:p-2">
                                        Basic details
                                    </h5>
                                    <form className="form-dashboard">
                                        <div className="grid grid-cols-2 max-md:block">
                                            <div>
                                                <label>Name</label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={
                                                        props.superAdminUser
                                                            .name
                                                            ? props
                                                                  .superAdminUser
                                                                  .name
                                                            : "Not Available"
                                                    }
                                                    className="text-[#909090] font-[500]"
                                                    readOnly
                                                ></input>
                                            </div>
                                            <div>
                                                <label>Email</label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    value={
                                                        props.superAdminUser
                                                            .email
                                                            ? props
                                                                  .superAdminUser
                                                                  .email
                                                            : "Not Available"
                                                    }
                                                    className="text-[#909090] font-[500]"
                                                    readOnly
                                                ></input>
                                            </div>
                                            <div>
                                                <label>User Id</label>
                                                <input
                                                    type="text"
                                                    name="userId"
                                                    value={
                                                        props.superAdminUser._id
                                                            ? props
                                                                  .superAdminUser
                                                                  ._id
                                                            : "Not Available"
                                                    }
                                                    className="text-[#909090] font-[500]"
                                                    readOnly
                                                ></input>
                                            </div>
                                            <div>
                                                <label>Created At:</label>
                                                <input
                                                    type="text"
                                                    name="createdAt"
                                                    value={
                                                        props.superAdminUser
                                                            .createdAt
                                                            ? new Date(
                                                                  props.superAdminUser.createdAt
                                                              ).toLocaleString()
                                                            : "Not available"
                                                    }
                                                    className="text-[#909090] font-[500]"
                                                    readOnly
                                                ></input>
                                            </div>
                                            <div>
                                                <label>Updated At:</label>
                                                <input
                                                    type="text"
                                                    name="updatedAt"
                                                    value={
                                                        props.superAdminUser
                                                            .updatedAt
                                                            ? new Date(
                                                                  props.superAdminUser.updatedAt
                                                              ).toLocaleString()
                                                            : "Not Available"
                                                    }
                                                    className="text-[#909090] font-[500]"
                                                    readOnly
                                                ></input>
                                            </div>
                                        </div>
                                    </form>
                                </>
                            )}
                            {props.selection === "reset-password" && (
                                <>
                                    <h5 className="font-semibold font-['roboto'] text-2xl text-darkblue p-3 my-7 max-md:my-5 max-md:p-2">
                                        Reset Password
                                    </h5>
                                    <form className="form-dashboard p-4">
                                        <div>
                                            <label>Current Password</label>
                                            <input
                                                autoComplete="new-password"
                                                type={
                                                    props.showPassword
                                                        ? "text"
                                                        : "password"
                                                }
                                                placeholder="Enter Current Password"
                                                value={props.currentPassword}
                                                onChange={(e) =>
                                                    props.handleSetCurrentPassword(
                                                        e.target.value
                                                    )
                                                }
                                            ></input>
                                            <i
                                                className={`float-right mr-2.5 cursor-pointer -mt-9 text-black fa fa-eye${
                                                    props.showPassword
                                                        ? ""
                                                        : "-slash"
                                                }`}
                                                onClick={() =>
                                                    props.handleSetShowPassword(
                                                        !props.showPassword
                                                    )
                                                }
                                            ></i>
                                        </div>
                                        <div>
                                            <label>New Password</label>
                                            <input
                                                autoComplete="new-password"
                                                type={
                                                    props.showNewPassword
                                                        ? "text"
                                                        : "password"
                                                }
                                                placeholder="Enter New Password"
                                                value={props.newPassword}
                                                onChange={(e) =>
                                                    props.handleSetNewPassword(
                                                        e.target.value
                                                    )
                                                }
                                            ></input>
                                            <i
                                                className={`float-right mr-2.5 cursor-pointer -mt-9 text-black fa fa-eye${
                                                    props.showNewPassword
                                                        ? ""
                                                        : "-slash"
                                                }`}
                                                onClick={() =>
                                                    props.handleSetShowNewPassword(
                                                        !props.showNewPassword
                                                    )
                                                }
                                            ></i>
                                        </div>
                                        <div>
                                            <label>Confirm Password</label>
                                            <input
                                                autoComplete="new-password"
                                                type={
                                                    props.showConfirmPassword
                                                        ? "text"
                                                        : "password"
                                                }
                                                placeholder="Enter Confirm Password"
                                                value={props.confirmPassword}
                                                onChange={(e) =>
                                                    props.handleSetConfirmPassword(
                                                        e.target.value
                                                    )
                                                }
                                            ></input>
                                            <i
                                                className={`float-right mr-2.5 cursor-pointer -mt-9 text-black fa fa-eye${
                                                    props.showConfirmPassword
                                                        ? ""
                                                        : "-slash"
                                                }`}
                                                onClick={() =>
                                                    props.handleSetShowConfirmPassword(
                                                        !props.showConfirmPassword
                                                    )
                                                }
                                            ></i>
                                        </div>
                                        <div className="my-8 text-left">
                                            <button className="bg-[#489BF6] rounded-md text-white font-[500] hover:scale-[1.05] duration-500 px-24">
                                                Save
                                            </button>
                                        </div>
                                    </form>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
