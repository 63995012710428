import Faq from "../FaqComponent";
import Navigation from "../Navigation";
import { IFaqPageViewProps } from "./FaqPage.interfaces";
import UserLoginBox from "../UserLoginBox";
import { Link } from "react-router-dom";
export default function FaqPageView(props: IFaqPageViewProps) {
    return (
        <>
            {props.showlogin === true && (
                <UserLoginBox
                    redirectionURL="/metaverse/65b741def8309762720ad53f"
                    loginController={props.loginController}
                /> // Since the line 122, says Login to Eternity City, but can be changed later => TP | 2024-01-30 14:39:44
            )}
            <div className="font-['roboto']">
                <div className="">
                    <div
                        className="bg-cover"
                        style={{
                            backgroundImage: `url('./images/faq/background.webp')`,
                        }}
                    >
                        <div className="bg-black/[0.50]">
                            <Navigation />
                            <div className="max-w-screen-2xl mx-auto max-md:block text-center px-10 max-md:px-2 text-white">
                                <div className="py-56 max-md:py-24">
                                    <h3 className="my-5 text-5xl font-bold">
                                        FAQs
                                    </h3>
                                    <p className="font-['roboto'] font-[500] text-[18px] text-lg max-md:text-[14px] max-md:pt-5 max-md:font-[400] max-w-screen-2xl mx-auto">
                                        FAQs InvincibleMeta.ai, a company that
                                        provides SaaS-based solutions for the
                                        metaverse: If you have any additional
                                        inquiries or need further assistance,
                                        please don't hesitate to{" "}
                                        <Link to="/contactus">
                                            <span className="text-[#136CC5] underline">
                                                Contact us
                                            </span>
                                        </Link>
                                    </p>
                                </div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                    <div className="max-w-screen-2xl mx-auto">
                        <div className="grid grid-cols-[2fr_8fr] max-md:grid-cols-1 justify-items-center">
                            <div className="faq-sidebar">
                                <div
                                    className={
                                        props.selected === 1
                                            ? "text-[#136CC5]"
                                            : "text-grey"
                                    }
                                    onClick={() => props.handleSelected(1)}
                                >
                                    General questions
                                </div>
                                <div
                                    className={
                                        props.selected === 2
                                            ? "text-[#136CC5]"
                                            : "text-grey"
                                    }
                                    onClick={() => props.handleSelected(2)}
                                >
                                    Banking Metaverse
                                </div>
                                <div
                                    className={
                                        props.selected === 3
                                            ? "text-[#136CC5]"
                                            : "text-grey"
                                    }
                                    onClick={() => props.handleSelected(3)}
                                >
                                    Automotive Metaverse
                                </div>
                                <div
                                    className={
                                        props.selected === 4
                                            ? "text-[#136CC5]"
                                            : "text-grey"
                                    }
                                    onClick={() => props.handleSelected(4)}
                                >
                                    Manufacturing Metaverse
                                </div>
                                <div
                                    className={
                                        props.selected === 5
                                            ? "text-[#136CC5]"
                                            : "text-grey"
                                    }
                                    onClick={() => props.handleSelected(5)}
                                >
                                    Retail Metaverse
                                </div>
                            </div>
                            <div>
                                {props.selected === 1 ? (
                                    <Faq category={"General Questions"} />
                                ) : (
                                    ""
                                )}
                                {props.selected === 2 ? (
                                    <Faq category={"Banking Metaverse"} />
                                ) : (
                                    ""
                                )}
                                {props.selected === 3 ? (
                                    <Faq category={"Automotive Metaverse"} />
                                ) : (
                                    ""
                                )}
                                {props.selected === 4 ? (
                                    <Faq category={"Manufacturing Metaverse"} />
                                ) : (
                                    ""
                                )}
                                {props.selected === 5 ? (
                                    <Faq category={"Retail Metaverse"} />
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="py-10 bg-gradient-to-b from-[#2059C5] to-black text-white">
                        <div className="text-center py-24 max-w-screen-2xl mx-auto">
                            <h5 className="text-3xl mb-7 max-md:mb-5">
                                {" "}
                                Still need help? Send us a note!{" "}
                            </h5>
                            <p className="mb-10 px-2 font-['roboto'] text-[18px]">
                                {" "}
                                For any other questions, please write us at{" "}
                                <a href="mailto:contact@inivincibleocean.com">
                                    <span className="text-[#136CC5] underline cursor-pointer">
                                        contact@inivincibleocean.com{" "}
                                    </span>
                                </a>{" "}
                                or call us at 9599066061
                            </p>

                            <button
                                onClick={props.loginController}
                                className="border-2 bg-[#FFF8F840] text-white max-md:mt-2 transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-[#FFF8F860] px-8 py-3 rounded-md"
                            >
                                Login to Eternity City
                                <i className="fa fa-long-arrow-right ml-4"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
