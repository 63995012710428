import BookDemo from "../BookDemo";
import Navigation from "../Navigation";
import { IIndustriesViewProps } from "./Industries.interfaces";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 3,
        partialVisibilityGutter: 20,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1388 },
        items: 3,
        partialVisibilityGutter: 10,
    },
    tablet: {
        breakpoint: { max: 1388, min: 687 },
        items: 2,
        partialVisibilityGutter: 30,
    },
    mobile: {
        breakpoint: { max: 687, min: 0 },
        items: 1,
        partialVisibilityGutter: 10,
    },
};
export default function IndustriesView(props: IIndustriesViewProps) {
    return (
        <>
            {props.showDemo === true && (
                <BookDemo demoController={props.demoController} />
            )}
            <div className="font-['roboto'] text-left">
                <div>
                    <div
                        className="text-white bg-cover bg-center"
                        style={{
                            backgroundImage: `url('./images/industries/backdrop.webp')`,
                        }}
                    >
                        <div className="bg-black/[0.50]">
                            <Navigation />
                            <div className="px-10 py-28 max-md:py-20">
                                <div className="max-w-screen-2xl mx-auto max-md:px-2">
                                    <div className="">
                                        <div>
                                            <h3 className="text-white text-center pt-48 pb-14 font-['roboto'] text-[58px] leading-[68px] max-sm:text-4xl font-bold my-5">
                                                Revolutionizing industries with
                                                Invincible Meta
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-[#136CC530]">
                    <div className="max-w-screen-2xl mx-auto max-md:px-2 flex justify-center">
                        <button
                            onClick={() => props.scrollToTarget("Finance")}
                            className="cursor-pointer px-10 max-md:px-2 font-[400] text-[22px] leading-[26px] text-[#000000] hover:bg-[#81C0FFC4] py-5 max-md:py-2 w-[200px] max-md:w-auto max-md:text-[16px] max-[348px]:text-[14px] text-center"
                        >
                            Finance
                        </button>
                        <button
                            onClick={() => props.scrollToTarget("Education")}
                            className="cursor-pointer px-10 max-md:px-2 font-[400] text-[22px] leading-[26px] text-[#000000] hover:bg-[#81C0FFC4] py-5 max-md:py-2 w-[200px] max-md:w-auto max-md:text-[16px] max-[348px]:text-[14px] text-center"
                        >
                            Education
                        </button>
                        <button
                            onClick={() => props.scrollToTarget("Retail")}
                            className="cursor-pointer px-10 max-md:px-2 font-[400] text-[22px] leading-[26px] text-[#000000] hover:bg-[#81C0FFC4] py-5 max-md:py-2 w-[200px] max-md:w-auto max-md:text-[16px] max-[348px]:text-[14px] text-center"
                        >
                            Retail
                        </button>
                        <button
                            onClick={() => props.scrollToTarget("Automobile")}
                            className="cursor-pointer px-10 max-md:px-2 font-[400] text-[22px] leading-[26px] text-[#000000] hover:bg-[#81C0FFC4] py-5 max-md:py-2 w-[200px] max-md:w-auto max-md:text-[16px] max-[348px]:text-[14px] text-center"
                        >
                            Automobile
                        </button>
                    </div>
                </div>
                <div
                    className="bg-cover"
                    style={{
                        backgroundImage: `url('./images/industries/backdrop2.webp')`,
                    }}
                    id="Finance"
                >
                    <div className="max-w-screen-2xl mx-auto max-md:px-2">
                        <h3 className="font-[600] text-[38px] leading-[45px] text-[#1C2347] text-center py-20">
                            Finance Sector: Navigating the Future of Banking and
                            Investments
                        </h3>
                        <p className="text-center text-[22px] leading-[26px] font-[600] pb-20">
                            Virtual Banking Experience{" "}
                            <span
                                onClick={props.demoController}
                                className="bg-gradient-to-r from-blue-500 to-purple-600 inline-block text-transparent bg-clip-text cursor-pointer font-[700]"
                            >
                                Book a demo{" "}
                                <i className="fa fa-angle-right font-[700] text-[22px]"></i>
                            </span>
                        </p>
                    </div>
                    <div className="pb-20 max-w-screen-2xl mx-auto">
                        <Carousel
                            containerClass="carousel-container"
                            responsive={responsive}
                            partialVisible={true}
                            swipeable={true}
                            draggable={true}
                            showDots={true}
                            ssr={true}
                            infinite={true}
                            autoPlay={true}
                            autoPlaySpeed={2000}
                            keyBoardControl={true}
                            removeArrowOnDeviceType={[
                                "desktop",
                                "laptop",
                                "tablet",
                                "mobile",
                            ]}
                        >
                            <div className="shadow-2xl rounded-lg max-md:max-w-[300px] h-[300px] mr-10 max-lg:mr-2 hover:scale-110 duration-500 my-10 mx-4 max-md:mx-10">
                                <img
                                    className="px-4 h-[100px] pt-2"
                                    alt=""
                                    src="./images/industries/icon1.webp"
                                />
                                <h3 className="font-[600] text-[#1C2347] text-[18px] xl:text-[24px] max-lg:text-[16px] leading-[28px] px-4 py-2 max-lg:py-1">
                                    Immersive Financial Services
                                </h3>
                                <p className="text-[#6B6B6B] text-[16px] xl:text-[18px] max-lg:text-[12px] font-[400] leading-[25px] px-4 pb-2 max-lg:pb-1">
                                    Step into our virtual banks for a
                                    personalized banking journey. Experience
                                    real-time consultations and financial
                                    planning in a fully interactive 3D world.
                                </p>
                            </div>
                            <div className="shadow-2xl rounded-lg max-md:max-w-[300px] h-[300px] mr-10 max-lg:mr-2 hover:scale-110 duration-500 my-10 mx-4 max-md:mx-10">
                                <img
                                    className="px-4 h-[100px] pt-2"
                                    alt=""
                                    src="./images/industries/icon2.webp"
                                />
                                <h3 className="font-[600] text-[#1C2347] text-[18px] xl:text-[24px] max-lg:text-[16px] leading-[28px] px-4 py-2 max-lg:py-1">
                                    Educational Simulations
                                </h3>
                                <p className="text-[#6B6B6B] text-[16px] xl:text-[18px] max-lg:text-[12px] font-[400] leading-[25px] px-4 pb-2 max-lg:pb-1">
                                    Learn complex financial concepts through
                                    engaging VR simulations. Ideal for both
                                    customers and professionals seeking to
                                    deepen their financial knowledge.
                                </p>
                            </div>
                            <div className="shadow-2xl rounded-lg max-md:max-w-[300px] h-[300px] hover:scale-110 duration-500 my-10 mx-4 max-md:mx-10">
                                <img
                                    className="px-4 h-[100px] pt-2"
                                    alt=""
                                    src="./images/industries/icon3.webp"
                                />
                                <h3 className="font-[600] text-[#1C2347] text-[18px] xl:text-[24px] max-lg:text-[16px] leading-[28px] px-4 py-2 max-lg:py-1">
                                    Virtual Investment Scenarios
                                </h3>
                                <p className="text-[#6B6B6B] text-[16px] xl:text-[18px] max-lg:text-[12px] font-[400] leading-[25px] px-4 pb-2 max-lg:pb-1">
                                    Explore investment opportunities and market
                                    dynamics in a controlled, simulated
                                    environment. Gain insights and experience
                                    without real-world risks.
                                </p>
                            </div>
                        </Carousel>
                    </div>
                </div>
                <div
                    id="Education"
                    className="bg-cover bg-center max-lg:bg-bottom py-10"
                    style={{
                        backgroundImage: `url('./images/industries/education1.webp')`,
                    }}
                >
                    <div className="max-w-screen-2xl mx-auto max-md:px-2 text-[#FFFFFF]">
                        <h3 className="font-[600] text-[38px] leading-[45px] text-[#FFFFFF] text-center py-20">
                            Education Sector: Transforming Learning and
                            Development with VR
                        </h3>
                        <p className="text-center text-[22px] leading-[26px] font-[600] pb-20">
                            Virtual Banking Experience{" "}
                            <span
                                onClick={props.demoController}
                                className="bg-gradient-to-r from-blue-500 to-purple-600 inline-block text-transparent bg-clip-text cursor-pointer font-[700]"
                            >
                                Book a demo{" "}
                                <i className="fa fa-angle-right font-[700] text-[22px]"></i>
                            </span>
                        </p>
                        <div className="py-10">
                            <div className="grid grid-cols-2 max-md:block">
                                <div className="py-5 rounded-lg shadow-xl bg-[#FFFFFF14] px-16 max-md:px-2">
                                    <h3 className="font-[600] text-[24px] leading-[29px] pt-7">
                                        Skill-based Training Simulations
                                    </h3>
                                    <p className="font-[400] text-[18px] leading-[25px] text-[#C6C6C6] py-4">
                                        Use VR for practical training
                                        simulations in various fields such as
                                        medicine, engineering, and more. These
                                        simulations provide a safe environment
                                        for students to practice and hone their
                                        skills
                                    </p>
                                    <h3 className="font-[600] text-[24px] leading-[29px] pt-7">
                                        Accessible Education for All
                                    </h3>
                                    <p className="font-[400] text-[18px] leading-[25px] text-[#C6C6C6] py-4">
                                        VR technology offers an inclusive
                                        learning environment, catering to
                                        diverse learning needs and styles. It's
                                        a step towards making education more
                                        accessible and equitable for everyone
                                    </p>
                                    <h3 className="font-[600] text-[24px] leading-[29px] pt-7">
                                        Skill-based Training Simulations
                                    </h3>
                                    <p className="font-[400] text-[18px] leading-[25px] text-[#C6C6C6] py-4">
                                        Participate in interactive workshops and
                                        seminars in a virtual setting. A great
                                        way for students and professionals to
                                        collaborate, network, and learn from
                                        experts worldwide.
                                    </p>
                                </div>
                                <div className="py-5"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="bg-[#136CC50F] px-2 py-10 max-md:py-4"
                    id="Retail"
                >
                    <div className="max-w-screen-2xl mx-auto max-md:px-2">
                        <h3 className="font-[600] text-[38px] leading-[45px] text-[#1C2347] text-center py-20">
                            Retail Industry: Redefining Shopping and Customer
                            Engagement
                        </h3>
                        <p className="text-center text-[22px] leading-[26px] font-[600] pb-20">
                            Enhanced Shopping Experience{" "}
                            <span
                                onClick={props.demoController}
                                className="bg-gradient-to-r from-blue-500 to-purple-600 inline-block text-transparent bg-clip-text cursor-pointer font-[700]"
                            >
                                Book a demo{" "}
                                <i className="fa fa-angle-right font-[700] text-[22px]"></i>
                            </span>
                        </p>
                    </div>
                    <div className="py-10 max-w-screen-2xl mx-auto max-md:px-2 grid grid-cols-[1fr_1fr] justify-items-center max-lg:grid max-lg:grid-cols-[1fr]">
                        <div className="shadow-2xl flex rounded-lg mr-20 max-xl:mr-2 hover:scale-110 max-md:hover:scale-100 duration-500 max-lg:mb-2 max-md:w-full px-2">
                            <div className="w-2/6 max-md:w-3/6">
                                <h3 className="font-[600] text-[#1C2347] text-[18px] max-md:text-[16px] leading-[28px] px-4 py-2">
                                    Virtual Stores and Showrooms
                                </h3>
                                <p className="text-[#6B6B6B] text-[14px] max-md:text-[12px] font-[400] leading-[25px] px-4 py-2">
                                    Walk through digital replicas of stores,
                                    explore products in 3D, and enjoy a seamless
                                    shopping experience that blends the digital
                                    with the physical.
                                </p>
                            </div>
                            <img
                                src="./images/industries/retail1.webp"
                                alt=""
                                className="w-4/6 max-md:w-3/6 object-cover rounded-r-xl"
                            />
                        </div>
                        <div className="shadow-2xl flex rounded-lg hover:scale-110 max-md:hover:scale-100 duration-500 max-md:w-full px-2">
                            <div className="w-2/6 max-md:w-3/6">
                                <h3 className="font-[600] text-[#1C2347] text-[18px] max-md:text-[16px] leading-[28px] px-4 py-2">
                                    Personalized Product Interactions
                                </h3>
                                <p className="text-[#6B6B6B] text-[14px] max-md:text-[12px] font-[400] leading-[25px] px-4 py-2">
                                    Customize and try products virtually. A
                                    revolution in shopping, providing a unique
                                    blend of convenience and personalization.
                                </p>
                            </div>

                            <img
                                src="./images/industries/retail2.webp"
                                alt=""
                                className="w-4/6 max-md:w-3/6 object-cover rounded-r-xl"
                            />
                        </div>
                    </div>
                    <div className="shadow-2xl rounded-xl max-w-screen-2xl mx-auto max-md:px-2 hover:scale-110 max-md:hover:scale-100 duration-500">
                        <div className="flex justify-center max-md:px-2">
                            <img
                                src="./images/industries/retail3.webp"
                                alt=""
                                className="w-full"
                            />
                        </div>
                        <div className="w-full text-left">
                            <h3 className="font-[600] text-[#1C2347] text-[18px] max-md:text-[16px] leading-[28px] px-2 py-4">
                                Consumer Behavior Analytics
                            </h3>
                            <p className="text-[#6B6B6B] text-[14px] max-md:text-[12px] font-[400] leading-[25px] px-2 pb-6">
                                Utilize VR to gain deeper insights into customer
                                preferences and behaviors, enabling tailored
                                marketing strategies and product developments.
                            </p>
                        </div>
                    </div>
                </div>
                <div id="Automobile">
                    <div className="max-w-screen-2xl mx-auto max-md:px-2">
                        <h3 className="font-[600] text-[38px] leading-[45px] text-[#1C2347] text-center py-20">
                            Automobile Industry: Transforming Car Buying and
                            Exploration
                        </h3>
                        <p className="text-center text-[22px] leading-[26px] font-[600] pb-20">
                            Virtual Showrooms and Customization{" "}
                            <span
                                onClick={props.demoController}
                                className="bg-gradient-to-r from-blue-500 to-purple-600 inline-block text-transparent bg-clip-text cursor-pointer font-[700]"
                            >
                                Book a demo{" "}
                                <i className="fa fa-angle-right font-[700] text-[22px]"></i>
                            </span>
                        </p>
                        <div className="grid grid-cols-2 max-md:block max-md:py-2">
                            <div className="py-10">
                                <h3 className="text-[#136CC5] text-[22px] font-[600]">
                                    {" "}
                                    Invinciblemeta.ai
                                </h3>
                                <ol className="font-[400] text-[18px] leading-[25px] text-[#6B6B6B] list-disc px-5 py-10">
                                    <li className="py-4">
                                        Invincible Meta's VR technology
                                        redefines the car buying experience with
                                        immersive virtual showrooms, where
                                        customers can explore and interact with
                                        a variety of car models in a realistic
                                        virtual environment.
                                    </li>
                                    <li className="py-4">
                                        Through VR, customers can take virtual
                                        test drives, experiencing the car's
                                        performance, handling, and interior
                                        features in a variety of simulated
                                        environments, enhancing their
                                        decision-making process.
                                    </li>
                                    <li className="py-4">
                                        The VR showrooms are equipped with
                                        interactive features, making the car
                                        buying experience more engaging and
                                        personalized for each customer.
                                    </li>
                                    <li className="py-4">
                                        {" "}
                                        VR is used for training dealership
                                        staff, providing them with a better
                                        understanding of the vehicles and
                                        enhancing their ability to assist
                                        customers effectively.
                                    </li>
                                    <li className="py-4">
                                        VR technology offers immersive training
                                        opportunities for automotive employees,
                                        improving skills in manufacturing,
                                        maintenance, and customer service
                                    </li>
                                    <li className="py-4">
                                        {" "}
                                        VR facilitates global collaboration
                                        among designers and engineers, allowing
                                        teams from different locations to work
                                        together on vehicle design and
                                        development in a virtual space.
                                    </li>
                                </ol>
                            </div>
                            <div className="py-10">
                                <img
                                    src="./images/industries/vehicle1.webp"
                                    alt=""
                                    className="p-4 max-md:p-0"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div
                        className="text-white bg-cover bg-center"
                        style={{
                            backgroundImage: `url('./images/industries/vehicle2.webp')`,
                        }}
                    >
                        <div className="bg-[#1370C563]">
                            <div className="px-10 py-28">
                                <div className="max-w-screen-2xl mx-auto max-md:px-2">
                                    <div className="text-center">
                                        <div>
                                            <h3 className="text-white text-center pb-14 font-['roboto'] text-[58px] leading-[68px] max-sm:text-4xl font-bold my-5">
                                                Experience Invincible Meta: A
                                                New Era of Virtual Reality
                                            </h3>
                                            <p className="pb-10 font-['roboto'] text-lg font-medium px-20 max-md:px-0">
                                                See firsthand how Invincible
                                                Meta is shaping the future of
                                                multiple industries. Our demo
                                                will provide a comprehensive
                                                overview of the possibilities
                                                and benefits of VR technology in
                                                your specific sector.
                                            </p>
                                            <button
                                                onClick={props.demoController}
                                                className="bg-blue-100 px-10 py-3 max-h-[50px] max-w-[200px] font-[600] text-white transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-gradient-to-l rounded-md mr-4 bg-gradient-to-r from-blue-500 to-purple-600"
                                            >
                                                Book a Demo
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
