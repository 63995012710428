import { useEffect, useState } from "react";
import { IIndustriesContainerProps } from "./Industries.interfaces";
import IndustriesView from "./Industries.view";
import BroswerService from "../../Services/Browser.service";
export default function IndustriesContainer(props: IIndustriesContainerProps) {
    useEffect(() => {
        BroswerService.setCustomTitle("IndustriesContainer");
    }, []);
    const scrollToTarget = (divelement: string) => {
        BroswerService.scrolltoTarget(divelement);
    };

    const [showDemo, setShowDemo] = useState(false);
    const demoController = () => setShowDemo(!showDemo);

    return (
        <>
            <IndustriesView
                scrollToTarget={scrollToTarget}
                demoController={demoController}
                showDemo={showDemo}
                //
            />
        </>
    );
}
