import ApiService from "./API.service";
import SessionService from "./Session.service";

const baseUrl = process.env.REACT_APP_SUPER_ADMIN_MICROSERVICE_BASE_URL;

const SuperAdminService = {
    /**
     * PUT /super-admin
     * @access public
     */
    create: async (name: string, email: string, password: string) => {
        try {
            const url = `${baseUrl}super-admin`;
            const { data } = await ApiService.post(url, {
                name,
                email,
                password,
            });
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * POST /super-admin
     * @access public
     */
    login: async (email: string, password: string) => {
        try {
            const url = `${baseUrl}super-admin`;
            const data = await ApiService.post(url, {
                email: email,
                password: password,
            });
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * GET /super-admin
     * @access super-admin
     */
    fetch: async () => {
        try {
            const url = `${baseUrl}super-admin`;
            const data = await ApiService.get(url);
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * PATCH /super-admin
     * @access super-admin
     */
    update: async (name: string, email: string, password: string) => {
        try {
            const url = `${baseUrl}super-admin`;
            const data = await ApiService.patch(url, {
                name,
                email,
                password,
            });
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * DELETE /super-admin
     * @access super-admin
     */
    delete: async () => {
        try {
            const url = `${baseUrl}super-admin`;
            const data = await ApiService.delete(url);
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * PATCH /super-admin/refresh-token
     * @access super-admin
     */
    refreshToken: async () => {
        try {
            const url = `${baseUrl}super-admin/refresh-token`;
            const data = await ApiService.patch(url, {
                refreshToken: SessionService.get("refreshToken"),
            });
            SessionService.set("accessToken", data.data.accessToken);
            SessionService.set("refreshToken", data.data.refreshToken);
            SessionService.set("user-type", "super-admin");
            SessionService.set("user", JSON.stringify(data.data.superAdmin));
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
};

export default SuperAdminService;
