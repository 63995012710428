import { SessionService } from "../../Services";
import { ISuperAdminSidebarViewProps } from "./SuperAdminSidebar.interfaces";
import { Link } from "react-router-dom";
export default function SuperAdminSidebarView(
    props: ISuperAdminSidebarViewProps
) {
    return (
        <>
            <div className="grid grid-rows-[1fr_9fr] h-full p-5">
                <div>
                    <img
                        src="../../images/whitelogo.webp"
                        alt="logo"
                        className="w-full"
                    />
                </div>
                <div className="grid grid-rows-[8fr_2fr] text-white">
                    <div className="grid content-between">
                        <div>
                            <Link to="/super-admin/home">
                                <div
                                    className={`${
                                        props.btnColor === "Dashboard"
                                            ? "bg-[#489BF6]"
                                            : "hover:bg-[#489BF6] duration-200"
                                    } rounded-md flex p-3 my-2`}
                                    onClick={() => {
                                        props.ButtonColor("Dashboard");
                                    }}
                                >
                                    <i className="fa fa-dashboard m-2"></i>
                                    <p className="text-lg">Dashboard</p>
                                </div>
                            </Link>
                            <div
                                className={`${
                                    props.btnColor === "Branch"
                                        ? "bg-[#489BF6]"
                                        : "hover:bg-[#489BF6] duration-200"
                                } cursor-pointer rounded-md flex items-center p-3`}
                                onClick={() => {
                                    props.ButtonColor("Branch");
                                }}
                            >
                                <i className="fa fa-random m-2"></i>
                                <p className="text-lg">Metaverses</p>
                                <i className="fa fa-angle-down ml-8 mr-2"></i>
                            </div>
                            <div
                                className={
                                    props.btnColor === "Branch"
                                        ? "grid grid-cols-0_1 ml-3 font-mormal font-barlow"
                                        : "hidden"
                                }
                            >
                                <ul className="text-left">
                                    <li
                                        className={`${
                                            props.btnColor === "Branch"
                                                ? "py-5"
                                                : ""
                                        }`}
                                    >
                                        {props.metaverses.map(
                                            (metaverse: any, index: number) => (
                                                <Link
                                                    to={`/super-admin/metaverse/${metaverse._id}`}
                                                    onClick={() =>
                                                        props.handleMetaverseClick(
                                                            metaverse
                                                        )
                                                    }
                                                    key={index}
                                                    className="flex items-center"
                                                >
                                                    <p
                                                        className={`text-lg ${
                                                            props.activeMetaverse ===
                                                            metaverse
                                                                ? "text-[#489BF6]"
                                                                : ""
                                                        } py-1`}
                                                    >
                                                        {props.activeMetaverse ===
                                                        metaverse ? (
                                                            <i className="fa fa-angle-right pr-2"></i>
                                                        ) : (
                                                            <i className="fa fa-angle-down pr-2"></i>
                                                        )}
                                                        {metaverse.name}
                                                    </p>
                                                </Link>
                                            )
                                        )}
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <Link to="/super-admin/dashboard-users">
                                    <div
                                        className={`${
                                            props.btnColor === "Dashboard User"
                                                ? "bg-[#489BF6]"
                                                : "hover:bg-[#489BF6] duration-200"
                                        } cursor-pointer rounded-md flex items-center my-2 p-3`}
                                        onClick={() => {
                                            props.ButtonColor("Dashboard User");
                                        }}
                                    >
                                        <i className="fa fa-paper-plane m-2"></i>
                                        <p className="text-lg">
                                            Dashboard Users
                                        </p>
                                        {/* <i className="fa fa-angle-down ml-8 mr-2"></i> */}
                                    </div>
                                </Link>
                            </div>
                            <div>
                                <Link to="/super-admin/metaverse-users">
                                    <div
                                        className={`${
                                            props.btnColor === "Metaverse Users"
                                                ? "bg-[#489BF6]"
                                                : "hover:bg-[#489BF6] duration-200"
                                        } cursor-pointer rounded-md flex items-center my-2 p-3`}
                                        onClick={() => {
                                            props.ButtonColor(
                                                "Metaverse Users"
                                            );
                                        }}
                                    >
                                        <i className="fa fa-paper-plane m-2"></i>
                                        <p className="text-lg">
                                            Metaverse Users
                                        </p>
                                        {/* <i className="fa fa-angle-down ml-8 mr-2"></i> */}
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div>
                            <Link to="/super-admin/myaccount">
                                <div
                                    className={`${
                                        props.btnColor === "My Account"
                                            ? "bg-[#489BF6]"
                                            : "hover:bg-[#489BF6] duration-200"
                                    } rounded-md flex p-3`}
                                    onClick={() => {
                                        props.ButtonColor("My Account");
                                    }}
                                >
                                    <i className="fa fa-user m-2"></i>
                                    <p className="text-lg">My Account</p>
                                </div>
                            </Link>
                            <div
                                className={`${
                                    props.btnColor === "Logout"
                                        ? "bg-[#489BF6] "
                                        : "hover:bg-[#489BF6] duration-200"
                                } rounded-md flex p-3 my-2`}
                                onClick={() => {
                                    props.ButtonColor("Logout");
                                    SessionService.clear();
                                    window.location.href = "/super-admin/login";
                                }}
                            >
                                <i className="fa fa-power-off m-2"></i>
                                <p className="text-lg">Logout</p>
                            </div>
                        </div>
                    </div>
                    <div className="bg-[#FFFFFF29] text-center font-['roboto'] rounded-md mt-8">
                        <p className="text-sm mt-5 font-semibold">
                            Support 24/7
                        </p>
                        <p className="text-xs font-medium">
                            Contact us anytime
                        </p>
                        <p className="text-[#489BF6] text-sm font-semibold my-5">
                            Start chat
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}
