import { ILoginWithCodeViewProps } from "./LoginWithCode.interfaces";
import Message from "../Message";
export default function LoginBoxView(props: ILoginWithCodeViewProps) {
    return (
        <>
            {props.showMessage && (
                <Message
                    messageText={props.messageText}
                    messageClass={props.messageClass}
                    demoController={props.messageController}
                />
            )}
            <div
                className="bg-cover h-screen font-['roboto']"
                style={{
                    backgroundImage: `url('../images/login/loginwithcodebackdrop.webp')`,
                }}
            >
                <section className="mx-auto mx-2  px-8 max-md:px-1 ">
                    <div className="max-md:px-2 text-left pt-10">
                        <div>
                            <img
                                alt=""
                                src="../images/whitelogo.webp"
                                className="w-[300px]"
                            />
                        </div>
                        <div className="flex items-center justify-center mt-20">
                            <div className="text-center">
                                <div className="text-white text-[56px] max-md:text-[36px]  font-semibold">
                                    Enter the Code displayed on your screen !
                                </div>
                                <div className="flex items-center justify-center">
                                    <div className="flex m-0 whitespace-nowrap align-middle py-5">
                                        {props.code
                                            .split("")
                                            .map((char, index) => (
                                                <input
                                                    key={index}
                                                    id={index.toString()}
                                                    type="tel"
                                                    maxLength={1}
                                                    className="bg-black/[.5] text-white text-[72px] max-md:text-[36px] rounded-lg outline-black mx-[6px] w-[60px] max-md:w-[30px] max-sm:w-[20px] border-b-[4px] border-b-white min-h-[38px] min-w-[38px] text-center leading-normal"
                                                    value={char}
                                                    onKeyDown={
                                                        props.handleInputChange
                                                    }
                                                    onChange={
                                                        props.handleInputChange
                                                    }
                                                />
                                            ))}
                                    </div>
                                </div>

                                <button
                                    className="bg-blue-100 text-white text-[24px] text-center w-[600px] max-md:w-[300px] py-3 my-10 font-[600] hover:scale-[1.05] transition duration-300 ease-in-out hover:bg-gradient-to-l rounded-md bg-gradient-to-r from-blue-500 to-purple-600"
                                    onClick={props.handleLogin}
                                >
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}
