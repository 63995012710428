import { ISuperAdminDashboardViewProps } from "./SuperAdminDashboard.interfaces";
export default function SuperAdminDashboardBoxView(
    props: ISuperAdminDashboardViewProps
) {
    return (
        <>
            <div className="grid grid-cols-4 max-xl:grid-cols-2 max-md:block mt-5 text-left">
                <div className="grid grid-cols-[3fr_7fr] bg-white font-['roboto'] rounded-xl border border-border-gray-300 mx-4 mt-5 mb-10 max-md:mb-5">
                    <div>
                        <div className="my-2 mx-2 rounded-full bg-[#7B6ABC2E] flex justify-center items-center w-[80px] h-[80px]">
                            <i className="fa fa-user-circle text-[30px] text-[#7B6ABC]"></i>
                        </div>
                    </div>
                    <div>
                        <p className="text-xs justify-center align-top mt-5 mx-3 font-semibold">
                            User Entered
                        </p>
                        <div className="flex">
                            <h5 className="align-center align-center text-4xl font-semibold my-2 ml-2">
                                3,870
                            </h5>
                            <p className="text-xs mt-7 mx-1">/ per day</p>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-[3fr_7fr] bg-white font-['roboto'] rounded-xl border border-border-gray-300 mx-4 mt-5 mb-10 max-md:mb-5">
                    <div>
                        <div className="my-2 mx-2 rounded-full bg-[#DB761A14] flex justify-center items-center w-[80px] h-[80px]">
                            <i className="fa fa-address-card text-[30px] text-[#DB761A]"></i>
                        </div>
                    </div>
                    <div>
                        <p className="text-xs justify-center align-top font-semibold mt-5 mx-3">
                            Dept Queries
                        </p>
                        <div className="flex">
                            <h5 className="align-center align-center text-4xl font-semibold my-2 ml-2">
                                13,860
                            </h5>
                            <p className="text-xs mt-7 mx-1">/ per day</p>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-[3fr_7fr] bg-white font-['roboto'] rounded-xl border border-border-gray-300 mx-4 mt-5 mb-10 max-md:mb-5">
                    <div>
                        <div className="my-2 mx-2 rounded-full bg-[#9013CB12] flex justify-center items-center w-[80px] h-[80px]">
                            <i className="fa fa-briefcase text-[30px] text-[#831FB1]"></i>
                        </div>
                    </div>
                    <div>
                        <p className="text-xs justify-center align-top font-semibold mt-5 mx-3">
                            No of department
                        </p>
                        <div className="flex">
                            <h5 className="align-center align-center text-4xl font-semibold my-2 ml-2">
                                13
                            </h5>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-[3fr_7fr] bg-white font-['roboto'] rounded-xl border border-border-gray-300 mx-4 mt-5 mb-10 max-md:mb-5">
                    <div>
                        <div className="my-2 mx-2 rounded-full bg-[#1714AB1A] flex justify-center items-center w-[80px] h-[80px]">
                            <i className="fa fa-random text-[30px] text-[#1714AB]"></i>
                        </div>
                    </div>
                    <div>
                        <p className="text-xs justify-center align-top font-semibold mt-5 mx-3">
                            No of branches
                        </p>
                        <div className="flex">
                            <h5 className="align-center align-center text-4xl font-semibold my-2 ml-2">
                                12
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
