import { ISuperAdminMetaverseDetailsViewProps } from "./SuperAdminMetaverseDetails.interfaces";
// import { Link } from "react-router-dom";
import Message from "../Message";
import { Link } from "react-router-dom";

export default function SuperAdminMetaverseDetailsView(
    props: ISuperAdminMetaverseDetailsViewProps
) {
    return (
        <>
            {props.showMessage && (
                <Message
                    messageText={props.messageText}
                    messageClass={props.messageClass}
                    demoController={props.messageController}
                />
            )}
            <div className="text-left font-['roboto']">
                <div className="grid bg-white m-4 rounded ">
                    {!props.createMeta && !props.updateMeta && (
                        <div className="p-4  max-lg:block">
                            <div className="py-10 p-4">
                                <button
                                    onClick={props.handleCreateMetaverse}
                                    className="my-2 bg-blue-100 font-[600] px-10 max-md:px-[78px] py-3 text-white transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-gradient-to-l rounded-md mr-4 bg-gradient-to-r from-blue-500 to-purple-600"
                                >
                                    Create a Metaverse
                                </button>
                                <button
                                    onClick={props.deleteMetaverse}
                                    className="my-2 bg-blue-100 font-[600] px-10 max-md:px-[78px] py-3 text-white transition duration-300 ease-in-out hover:scale-[1.05] hover:from-red-500 hover:to-[#FFA500] rounded-md mr-4 bg-gradient-to-r from-blue-500 to-purple-600"
                                >
                                    Delete Metaverse
                                </button>
                            </div>
                            <div>
                                <div className="">
                                    <div className="border-2 p-4 m-4">
                                        <p className="font-[600] superadmin-metaverse-details">
                                            <span>Name: </span>
                                            {props.metaverse.name}
                                        </p>
                                        <p className="text-[#489BF6] font-[600] superadmin-metaverse-details">
                                            <span className="text-black">
                                                Metaverse ID:{" "}
                                            </span>
                                            {props.metaverse._id}
                                        </p>
                                        <p className="font-[600] superadmin-metaverse-details">
                                            <span>Description: </span>
                                            {props.metaverse.description}
                                        </p>
                                        <p className="text-[#489BF6] font-[600] superadmin-metaverse-details">
                                            <span className="text-black">
                                                Admin:{" "}
                                            </span>
                                            <Link
                                                to={`/super-admin/dashboard-user/${props.metaverse.admin}`}
                                                className="hover:text-green-500 duration-300 underline"
                                            >
                                                {props.metaverse.admin}
                                                {/* Convert this to a dropdown and display props.dashboardUsers => TP | 2024-02-05 17:19:50 */}
                                            </Link>
                                        </p>
                                        <p className="text-[#489BF6] superadmin-metaverse-details">
                                            <span className="text-black">
                                                WebGL Build:{" "}
                                            </span>
                                            {props.metaverse.links?.WEBGL.build}
                                        </p>
                                        <p className="text-[#489BF6] superadmin-metaverse-details">
                                            <span className="text-black">
                                                WebGl Assests:{" "}
                                            </span>
                                            {
                                                props.metaverse.links?.WEBGL
                                                    .assets
                                            }
                                        </p>
                                        <p className="text-[#489BF6] superadmin-metaverse-details">
                                            <span className="text-black">
                                                Mac Build:{" "}
                                            </span>
                                            {props.metaverse.links?.MAC.build}
                                        </p>
                                        <p className="text-[#489BF6] superadmin-metaverse-details">
                                            <span className="text-black">
                                                Mac Assets:{" "}
                                            </span>
                                            {props.metaverse.links?.MAC.assets}
                                        </p>
                                        <p className="text-[#489BF6] superadmin-metaverse-details">
                                            <span className="text-black">
                                                Windows Build:{" "}
                                            </span>
                                            {
                                                props.metaverse.links?.WINDOWS
                                                    .build
                                            }
                                        </p>
                                        <p className="text-[#489BF6] superadmin-metaverse-details">
                                            <span className="text-black">
                                                Windows Assets:{" "}
                                            </span>
                                            {
                                                props.metaverse.links?.WINDOWS
                                                    .assets
                                            }
                                        </p>
                                        <p className="text-[#489BF6] superadmin-metaverse-details">
                                            <span className="text-black">
                                                VR Build:{" "}
                                            </span>
                                            {props.metaverse.links?.VR.build}
                                        </p>
                                        <p className="text-[#489BF6] superadmin-metaverse-details">
                                            <span className="text-black">
                                                VR Assests:{" "}
                                            </span>
                                            {props.metaverse.links?.VR.assets}
                                        </p>
                                        <p className="text-[#489BF6] superadmin-metaverse-details">
                                            <span className="text-black">
                                                iOS Build:{" "}
                                            </span>
                                            {props.metaverse.links?.IOS.assets}
                                        </p>
                                        <p className="text-[#489BF6] superadmin-metaverse-details">
                                            <span className="text-black">
                                                iOS Assests:{" "}
                                            </span>
                                            {props.metaverse.links?.IOS.assets}
                                        </p>
                                        <p className="text-[#489BF6] superadmin-metaverse-details">
                                            <span className="text-black">
                                                Android Build:{" "}
                                            </span>
                                            {
                                                props.metaverse.links?.ANDROID
                                                    .assets
                                            }
                                        </p>
                                        <p className="text-[#489BF6] superadmin-metaverse-details">
                                            <span className="text-black">
                                                Android Assests:{" "}
                                            </span>
                                            {
                                                props.metaverse.links?.ANDROID
                                                    .assets
                                            }
                                        </p>
                                        <button
                                            onClick={
                                                props.handleUpdateMetaverse
                                            }
                                            className="my-3 bg-blue-100 font-[600] px-10 max-md:px-[78px] py-3 text-white transition duration-300 ease-in-out hover:scale-[1.05] hover:from-red-500 hover:to-[#FFA500] rounded-md mr-4 bg-gradient-to-r from-blue-500 to-purple-600"
                                        >
                                            Update Metaverse
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {props.createMeta && (
                        <div className="p-4">
                            <span
                                onClick={props.handleCreateMetaverse}
                                className="font-['roboto'] text-sm font-semibold text-[#489BF6] cursor-pointer"
                            >
                                {"<< Back"}
                            </span>
                            <h3 className="pt-4">Create Metaverse</h3>
                            <form className="loginform2">
                                <div>
                                    <label>Metaverse Name</label>
                                    <input
                                        type="text"
                                        placeholder="Metaverse Name"
                                        name="name"
                                        onChange={props.metaverseDataController}
                                    />
                                </div>
                                <div>
                                    <label>Metaverse Description</label>
                                    <input
                                        type="text"
                                        placeholder="Metaverse Description"
                                        name="description"
                                        onChange={props.metaverseDataController}
                                    />
                                </div>
                                <div className="grid grid-cols-1">
                                    <label>Metaverse Admin</label>
                                    <div className="block">
                                        <div className="relative flex items-center bg-[#E7E7E7DB] text-sm font-normal border border-grey-500 rounded-lg">
                                            <div
                                                onClick={props.toggleDropdown}
                                                onChange={
                                                    props.metaverseDataController
                                                }
                                                className="flex items-center justify-between p-4 cursor-pointer w-full hover:bg-[#489BF6] group hover:rounded-lg"
                                            >
                                                <p className="font-[600] group-hover:text-white">
                                                    {props.metaverseData.admin}
                                                </p>
                                                <i className="fa fa-angle-down pl-1 group-hover:text-white"></i>
                                            </div>
                                            {props.isOpen && (
                                                <div className="absolute z-[9999] mt-56 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 h-[150px] w-full overflow-y-auto">
                                                    <input
                                                        id="search-input"
                                                        className="block w-full px-4 py-2 text-gray-800 border rounded-md border-gray-300 focus:outline-none"
                                                        type="text"
                                                        placeholder="Search items"
                                                        onChange={
                                                            props.handleSearchInput
                                                        }
                                                    ></input>
                                                    {props.dataDropdown
                                                        ? props.dataDropdown
                                                              .filter(
                                                                  (item: any) =>
                                                                      item.name
                                                                          .toLowerCase()
                                                                          .includes(
                                                                              props.searchterm.toLowerCase()
                                                                          ) ||
                                                                      item.email
                                                                          .toLowerCase()
                                                                          .includes(
                                                                              props.searchterm.toLowerCase()
                                                                          )
                                                              )
                                                              .map(
                                                                  (
                                                                      item: any,
                                                                      i: any
                                                                  ) => (
                                                                      <li
                                                                          key={
                                                                              i
                                                                          }
                                                                          className="block px-6 py-2 text-[14px] text-gray-700 text-center hover:bg-[#489BF6] hover:text-white font-[600] cursor-pointer rounded-md"
                                                                          value={
                                                                              item._id
                                                                          }
                                                                          onClick={(
                                                                              e
                                                                          ) => {
                                                                              props.onAdminDropdownEvent(
                                                                                  item._id
                                                                              );
                                                                          }}
                                                                      >
                                                                          {
                                                                              item.name
                                                                          }
                                                                          <br />
                                                                          {
                                                                              item.email
                                                                          }
                                                                      </li>
                                                                  )
                                                              )
                                                        : "not available"}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 max-md:block">
                                    <div className="mr-2">
                                        <label> WebGL Build</label>
                                        <input
                                            type="url"
                                            placeholder="Build Link"
                                            name="links.WEBGL.build"
                                            onChange={
                                                props.metaverseDataController
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label>WebGL Assets</label>
                                        <input
                                            type="url"
                                            placeholder="Asset Link"
                                            name="links.WEBGL.assets"
                                            onChange={
                                                props.metaverseDataController
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 max-md:block">
                                    <div className="mr-2">
                                        <label>Android Build</label>
                                        <input
                                            type="url"
                                            placeholder="Build Link"
                                            name="links.ANDROID.build"
                                            onChange={
                                                props.metaverseDataController
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label>Android Assets</label>
                                        <input
                                            type="url"
                                            placeholder="Asset Link"
                                            name="links.ANDROID.assets"
                                            onChange={
                                                props.metaverseDataController
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 max-md:block">
                                    <div className="mr-2">
                                        <label>IOS Build</label>
                                        <input
                                            type="url"
                                            placeholder="Build Link"
                                            name="links.IOS.build"
                                            onChange={
                                                props.metaverseDataController
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label>IOS Assets</label>
                                        <input
                                            type="url"
                                            placeholder="Asset Link"
                                            name="links.IOS.assets"
                                            onChange={
                                                props.metaverseDataController
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 max-md:block">
                                    <div className="mr-2">
                                        <label>Mac Build</label>
                                        <input
                                            type="url"
                                            placeholder="Build Link"
                                            name="links.MAC.build"
                                            onChange={
                                                props.metaverseDataController
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label>Mac Assets</label>
                                        <input
                                            type="url"
                                            placeholder="Asset Link"
                                            name="links.MAC.assets"
                                            onChange={
                                                props.metaverseDataController
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 max-md:block">
                                    <div className="mr-2">
                                        <label>Windows Build</label>
                                        <input
                                            type="url"
                                            placeholder="Build Link"
                                            name="links.WINDOWS.build"
                                            onChange={
                                                props.metaverseDataController
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label>Windows Assets</label>
                                        <input
                                            type="url"
                                            placeholder="Asset Link"
                                            name="links.WINDOWS.assets"
                                            onChange={
                                                props.metaverseDataController
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 max-md:block">
                                    <div className="mr-2">
                                        <label>VR Build</label>
                                        <input
                                            type="url"
                                            placeholder="Build Link"
                                            name="links.VR.build"
                                            onChange={
                                                props.metaverseDataController
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label>VR Assets</label>
                                        <input
                                            type="url"
                                            placeholder="Asset Link"
                                            name="links.VR.assets"
                                            onChange={
                                                props.metaverseDataController
                                            }
                                        />
                                    </div>
                                </div>
                                <button
                                    onClick={props.handleSuperAdminFormSubmit}
                                >
                                    Create Metaverse
                                </button>
                            </form>
                        </div>
                    )}
                    {props.updateMeta && (
                        <div className="p-4">
                            <span
                                onClick={props.handleUpdateMetaverse}
                                className="font-['roboto'] text-sm font-semibold text-[#489BF6] cursor-pointer"
                            >
                                {"<< Back"}
                            </span>
                            <h3 className="pt-4">Update Metaverse</h3>
                            <form className="loginform2">
                                <div>
                                    <label>Metaverse Name</label>
                                    <input
                                        type="text"
                                        placeholder="Metaverse Name"
                                        name="name"
                                        value={props.metaverseData.name}
                                        onChange={props.metaverseDataController}
                                    />
                                </div>
                                <div>
                                    <label>Metaverse Description</label>
                                    <input
                                        type="text"
                                        placeholder="Metaverse Description"
                                        name="description"
                                        value={props.metaverseData.description}
                                        onChange={props.metaverseDataController}
                                    />
                                </div>
                                <div className="grid grid-cols-1">
                                    <label>Metaverse Admin</label>
                                    <div className="block">
                                        <div className="relative flex items-center bg-[#E7E7E7DB] text-sm font-normal border border-grey-500 rounded-lg">
                                            <div
                                                onClick={props.toggleDropdown}
                                                onChange={
                                                    props.metaverseDataController
                                                }
                                                className="flex items-center justify-between p-4 cursor-pointer w-full hover:bg-[#489BF6] group hover:rounded-lg"
                                            >
                                                <p className="font-[600] group-hover:text-white">
                                                    {props.metaverseData.admin}
                                                </p>
                                                <i className="fa fa-angle-down pl-1 group-hover:text-white"></i>
                                            </div>
                                            {props.isOpen && (
                                                <div className="absolute z-[9999] mt-56 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 h-[150px] w-full overflow-y-auto">
                                                    <input
                                                        id="search-input"
                                                        className="block w-full px-4 py-2 text-gray-800 border rounded-md border-gray-300 focus:outline-none"
                                                        type="text"
                                                        placeholder="Search items"
                                                        onChange={
                                                            props.handleSearchInput
                                                        }
                                                    ></input>
                                                    {props.dataDropdown
                                                        ? props.dataDropdown
                                                              .filter(
                                                                  (item: any) =>
                                                                      item.name
                                                                          .toLowerCase()
                                                                          .includes(
                                                                              props.searchterm.toLowerCase()
                                                                          ) ||
                                                                      item.email
                                                                          .toLowerCase()
                                                                          .includes(
                                                                              props.searchterm.toLowerCase()
                                                                          )
                                                              )
                                                              .map(
                                                                  (
                                                                      item: any,
                                                                      i: any
                                                                  ) => (
                                                                      <li
                                                                          key={
                                                                              i
                                                                          }
                                                                          className="block px-6 py-2 text-[14px] text-gray-700 text-center hover:bg-[#489BF6] hover:text-white font-[600] cursor-pointer rounded-md"
                                                                          value={
                                                                              item._id
                                                                          }
                                                                          onClick={(
                                                                              e
                                                                          ) => {
                                                                              props.onAdminDropdownEvent(
                                                                                  item._id
                                                                              );
                                                                          }}
                                                                      >
                                                                          {
                                                                              item.name
                                                                          }
                                                                          <br />
                                                                          {
                                                                              item.email
                                                                          }
                                                                      </li>
                                                                  )
                                                              )
                                                        : "not available"}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 max-md:block">
                                    <div className="mr-2">
                                        <label> WebGL Build</label>
                                        <input
                                            type="url"
                                            placeholder="Build Link"
                                            name="links.WEBGL.build"
                                            value={
                                                props.metaverseData.links?.WEBGL
                                                    .build
                                            }
                                            onChange={
                                                props.metaverseDataController
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label>WebGL Assets</label>
                                        <input
                                            type="url"
                                            placeholder="Asset Link"
                                            name="links.WEBGL.assets"
                                            value={
                                                props.metaverseData.links?.WEBGL
                                                    .assets
                                            }
                                            onChange={
                                                props.metaverseDataController
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 max-md:block">
                                    <div className="mr-2">
                                        <label>Android Build</label>
                                        <input
                                            type="url"
                                            placeholder="Build Link"
                                            name="links.ANDROID.build"
                                            value={
                                                props.metaverseData.links
                                                    ?.ANDROID.build
                                            }
                                            onChange={
                                                props.metaverseDataController
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label>Android Assets</label>
                                        <input
                                            type="url"
                                            placeholder="Asset Link"
                                            name="links.ANDROID.assets"
                                            value={
                                                props.metaverseData.links
                                                    ?.ANDROID.assets
                                            }
                                            onChange={
                                                props.metaverseDataController
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 max-md:block">
                                    <div className="mr-2">
                                        <label>IOS Build</label>
                                        <input
                                            type="url"
                                            placeholder="Build Link"
                                            name="links.IOS.build"
                                            value={
                                                props.metaverseData.links?.IOS
                                                    .build
                                            }
                                            onChange={
                                                props.metaverseDataController
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label>IOS Assets</label>
                                        <input
                                            type="url"
                                            placeholder="Asset Link"
                                            name="links.IOS.assets"
                                            value={
                                                props.metaverseData.links?.IOS
                                                    .assets
                                            }
                                            onChange={
                                                props.metaverseDataController
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 max-md:block">
                                    <div className="mr-2">
                                        <label>Mac Build</label>
                                        <input
                                            type="url"
                                            placeholder="Build Link"
                                            name="links.MAC.build"
                                            value={
                                                props.metaverseData.links?.MAC
                                                    .build
                                            }
                                            onChange={
                                                props.metaverseDataController
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label>Mac Assets</label>
                                        <input
                                            type="url"
                                            placeholder="Asset Link"
                                            name="links.MAC.assets"
                                            value={
                                                props.metaverseData.links?.MAC
                                                    .assets
                                            }
                                            onChange={
                                                props.metaverseDataController
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 max-md:block">
                                    <div className="mr-2">
                                        <label>Windows Build</label>
                                        <input
                                            type="url"
                                            placeholder="Build Link"
                                            name="links.WINDOWS.build"
                                            value={
                                                props.metaverseData.links
                                                    ?.WINDOWS.build
                                            }
                                            onChange={
                                                props.metaverseDataController
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label>Windows Assets</label>
                                        <input
                                            type="url"
                                            placeholder="Asset Link"
                                            name="links.WINDOWS.assets"
                                            value={
                                                props.metaverseData.links
                                                    ?.WINDOWS.assets
                                            }
                                            onChange={
                                                props.metaverseDataController
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 max-md:block">
                                    <div className="mr-2">
                                        <label>VR Build</label>
                                        <input
                                            type="url"
                                            placeholder="Build Link"
                                            name="links.VR.build"
                                            value={
                                                props.metaverseData.links?.VR
                                                    .build
                                            }
                                            onChange={
                                                props.metaverseDataController
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label>VR Assets</label>
                                        <input
                                            type="url"
                                            placeholder="Asset Link"
                                            name="links.VR.assets"
                                            value={
                                                props.metaverseData.links?.VR
                                                    .assets
                                            }
                                            onChange={
                                                props.metaverseDataController
                                            }
                                        />
                                    </div>
                                </div>
                                <button
                                    onClick={
                                        props.handleSuperAdminUpdateFormSubmit
                                    }
                                >
                                    Update Metaverse
                                </button>
                            </form>
                        </div>
                    )}
                </div>
                {!props.createMeta && !props.updateMeta && (
                    <>
                        <div className="grid bg-white m-4 rounded p-4">
                            <div className="border-2 p-4 m-4">
                                <h3 className="mb-4">Dashboard Users</h3>
                                {props.metaverse.dashboardUsers &&
                                    props.metaverse.dashboardUsers.map(
                                        (dashboardUser: any, index: number) => (
                                            <ol key={index}>
                                                <li className="py-1 text-[#489BF6] font-[600] hover:text-green-500 duration-300">
                                                    <Link
                                                        to={`/super-admin/dashboard-user/${dashboardUser._id}`}
                                                    >
                                                        {index + 1 + ". "}
                                                        {dashboardUser.name}
                                                    </Link>
                                                </li>
                                            </ol>
                                        )
                                    )}
                            </div>
                        </div>
                        <div className="grid bg-white m-4 rounded p-4">
                            <div className="border-2 p-4 m-4">
                                <h3 className="mb-4">Metaverse Users</h3>
                                {props.metaverse.users &&
                                    props.metaverse.users.map(
                                        (user: any, index: number) => (
                                            <ol key={index}>
                                                <li className="py-1 text-[#489BF6] font-[600] hover:text-green-500 duration-300">
                                                    <Link
                                                        to={`/super-admin/metaverse-user/${user._id}`}
                                                    >
                                                        {index + 1 + ". "}
                                                        {user.name ??
                                                            "Name not set"}
                                                    </Link>
                                                </li>
                                            </ol>
                                        )
                                    )}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
