import { IAllUserDetailsViewProps } from "./AllUserDetails.interfaces";
import { Link } from "react-router-dom";
export default function AllUserDetailsView(props: IAllUserDetailsViewProps) {
    return (
        <>
            <div className="px-10 py-10 max-md:px-2 py-2 text-left">
                <Link to="/dashboard/branch">
                    <span className="font-['roboto'] text-sm font-semibold text-[#489BF6]">
                        {"<< Back"}
                    </span>
                </Link>
                <div className="border-2 border-[#929191]-300 rounded-lg bg-white font-['roboto'] text-[#929191] text-base font-semibold">
                    <div className="flex ml-5 mr-10 mt-10 justify-between max-md:mt-2">
                        <div className="flex max-md:block">
                            <p
                                className={`${
                                    props.btnName === "all-users-details"
                                        ? "text-[#489BF6] underline underline-offset-8 decoration-2"
                                        : "hover:text-[#489BF6] duration-300"
                                } mr-10 max-md:my-2 cursor-pointer`}
                                onClick={() => {
                                    props.handleClick("all-users-details");
                                }}
                            >
                                All users details
                            </p>
                            <p
                                className={`${
                                    props.btnName === "loan-department"
                                        ? "text-[#489BF6] underline underline-offset-8 decoration-2"
                                        : "hover:text-[#489BF6] duration-300"
                                } mr-10 max-md:my-2 cursor-pointer`}
                                onClick={() => {
                                    props.handleClick("loan-department");
                                }}
                            >
                                Loan department
                            </p>
                            <p
                                className={`${
                                    props.btnName === "account-department"
                                        ? "text-[#489BF6] underline underline-offset-8 decoration-2"
                                        : "hover:text-[#489BF6] duration-300"
                                } mr-10 max-md:my-2 cursor-pointer`}
                                onClick={() => {
                                    props.handleClick("account-department");
                                }}
                            >
                                Account department
                            </p>
                            <p
                                className={`${
                                    props.btnName === "credit-department"
                                        ? "text-[#489BF6] underline underline-offset-8 decoration-2"
                                        : "hover:text-[#489BF6] duration-300"
                                } mr-10 max-md:my-2 cursor-pointer`}
                                onClick={() => {
                                    props.handleClick("credit-department");
                                }}
                            >
                                Credit card department
                            </p>
                        </div>

                        <div className="flex max-md:items-center">
                            <img
                                src="../images/userdetails/filter.svg"
                                alt=""
                            />
                            <span className="ml-3 underline text-[#489BF6] cursor-pointer">
                                Filters
                            </span>
                        </div>
                    </div>

                    {props.btnName === "all-users-details" && (
                        <div>
                            <div className="grid grid-cols-[30px_90px_180px_2fr_2fr_1fr_130px_120px] text-[#929191] text-xs font-semibold mt-12 ml-5 mr-10 items-center max-md:hidden">
                                <span className="mx-2">s.no</span>
                                <span></span>
                                <span className="mx-2">Date</span>
                                <span className="mx-2">Name</span>
                                <span className="mx-2">Email</span>
                                <span className="mx-2">Phone number</span>
                                <span className="mx-2">Access departments</span>
                                <span className="mx-2">View</span>
                            </div>

                            <div className="mt-5 ml-5 mr-10 max-md:mt-2 ml-2 mr-3">
                                {props.record.map((item: any, index: any) => (
                                    <div
                                        className="grid grid-cols-userdetail border shadow-xl rounded-xl items-center mb-5 max-md:block font-medium"
                                        key={index}
                                    >
                                        <p className="bg-[#929191]-200 py-8 px-2.5 text-xs text-black max-md:py-2">
                                            #{index + 1}
                                        </p>
                                        <img
                                            className="ml-2 max-md:my-2"
                                            src="../images/userdetails/profile.svg"
                                            alt=""
                                        />
                                        <p className="text-sm text-black px-2 max-md:grid grid-cols-[150px,1fr] justify-between pl-5 py-1">
                                            <span className="hidden max-md:block">
                                                Date
                                            </span>
                                            <span className="font-normal">
                                                {item.createdAt}
                                            </span>{" "}
                                        </p>
                                        <p className="text-sm text-black px-2 max-md:grid grid-cols-[150px,1fr] justify-between pl-5 py-1">
                                            <span className="hidden max-md:block">
                                                Name
                                            </span>
                                            <span className="font-normal">
                                                {item.name}{" "}
                                            </span>{" "}
                                        </p>
                                        <p className="text-sm text-black px-2 max-md:grid grid-cols-[150px,1fr] justify-between pl-5 py-1">
                                            <span className="hidden max-md:block">
                                                Email
                                            </span>
                                            <span className="font-normal">
                                                {item.email}
                                            </span>{" "}
                                        </p>
                                        <p className="text-sm text-black px-2 max-md:grid grid-cols-[150px,1fr] justify-between pl-5 py-1">
                                            <span className="hidden max-md:block">
                                                Phone number
                                            </span>
                                            <span className="font-normal">
                                                {item.mobile}
                                            </span>{" "}
                                        </p>
                                        <p className="text-sm text-black px-2 max-md:grid grid-cols-[150px,1fr] justify-between pl-5 py-1">
                                            <span className="hidden max-md:block">
                                                Access departments
                                            </span>
                                            <span className="font-normal">
                                                1
                                            </span>
                                        </p>
                                        <Link to={"/dashboard/user-details"}>
                                            <p
                                                className="text-base text-[#489BF6] underline px-2 max-md:pl-5 py-1 cursor-pointer"
                                                onClick={() =>
                                                    props.saveDetails(item)
                                                }
                                            >
                                                View details
                                            </p>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {props.btnName === "loan-department" && (
                        <div>
                            <div className="text-[#929191] text-xs font-semibold items-center p-2">
                                <p className="py-10 text-center">
                                    No Data Found
                                </p>
                            </div>
                        </div>
                    )}
                    {props.btnName === "account-department" && (
                        <div>
                            <div className="text-[#929191] text-xs font-semibold items-center p-2">
                                <p className="py-10 text-center">
                                    No Data Found
                                </p>
                            </div>
                        </div>
                    )}
                    {props.btnName === "credit-department" && (
                        <div>
                            <div className="text-[#929191] text-xs font-semibold items-center p-2">
                                <p className="py-10 text-center">
                                    No Data Found
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
