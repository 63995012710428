import { IFooterViewProps } from "./Footer.interfaces";
import BookDemo from "../BookDemo";
import { Link } from "react-router-dom";
export default function FooterView(props: IFooterViewProps) {
    return (
        <>
            {props.showDemo === true && (
                <BookDemo demoController={props.demoController} />
            )}
            <div
                className="bg-cover font-['roboto']"
                style={{
                    backgroundImage: `url('./images/footerbackground.webp')`,
                }}
            >
                <div className="max-w-screen-2xl px-2 mx-auto max-md:mx-2 pt-16 pb-5 text-left">
                    <div className="grid text-white grid-cols-[600px__auto_auto_auto_auto] max-xl:grid-cols-[auto__auto_auto_auto_auto] max-md:block">
                        <div className="grid grid-rows-[50px_1fr] max-md:py-2 justify-items-start">
                            <Link to="/" onClick={props.scrollToTop}>
                                <img
                                    src="../images/whitelogo.webp"
                                    alt="Invincible Ocean Logo"
                                    className="max-w-[250px]"
                                />
                            </Link>
                            <div className="flex w-4/12 py-2">
                                <a
                                    href="https://in.linkedin.com/company/invincible-ocean"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <div className="rounded-full bg-black border-[1px] border-white h-[38px] w-[38px] px-2 py-1 mr-3">
                                        <i className="fa fa-linkedin text-[24px]"></i>
                                    </div>
                                </a>
                                <a
                                    href="https://www.instagram.com/officialinvincible/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <div className="rounded-full bg-black border-[1px] border-white h-[38px] w-[38px] px-2 py-1.5 mr-3">
                                        <i className="fa fa-instagram text-[24px]"></i>
                                    </div>
                                </a>
                                <a
                                    href="https://twitter.com/Invincible_meta"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <div className="rounded-full bg-black border-[1px] border-white h-[38px] w-[38px] px-2 py-1.5">
                                        <i className="fa fa-twitter text-[23px]"></i>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="max-md:py-2">
                            <h5 className="pb-5 text-[26px] font-[600]">
                                {" "}
                                Product{" "}
                            </h5>
                            <ol>
                                <Link to="/vrworld" onClick={props.scrollToTop}>
                                    <li className="text-[16px] py-1 font-[400] cursor-pointer">
                                        VR World
                                    </li>
                                </Link>
                                <Link to="/vrbank" onClick={props.scrollToTop}>
                                    <li className="text-[16px] py-1 font-[400] cursor-pointer">
                                        VR Bank
                                    </li>
                                </Link>
                                <Link to="/vrauto" onClick={props.scrollToTop}>
                                    <li className="text-[16px] py-1 font-[400] cursor-pointer">
                                        VR Auto
                                    </li>
                                </Link>
                                <Link
                                    to="/vrretail"
                                    onClick={props.scrollToTop}
                                >
                                    <li className="text-[16px] py-1 font-[400] cursor-pointer">
                                        VR Retail
                                    </li>
                                </Link>
                            </ol>
                        </div>
                        <div className="max-md:py-2">
                            <h5 className="pb-5 text-[26px] font-[600]">
                                Others
                            </h5>
                            <ol>
                                <Link
                                    to="/contactus"
                                    onClick={props.scrollToTop}
                                >
                                    <li className="text-[16px] py-1 font-[400] cursor-pointer">
                                        Contact us
                                    </li>
                                </Link>
                                <Link to="/aboutus" onClick={props.scrollToTop}>
                                    <li className="text-[16px] py-1 font-[400] cursor-pointer">
                                        About us
                                    </li>
                                </Link>
                                <Link to="/faq" onClick={props.scrollToTop}>
                                    <li className="text-[16px] py-1 font-[400] cursor-pointer">
                                        FAQs
                                    </li>
                                </Link>
                                <li className="text-[16px] py-1 font-[400] cursor-pointer">
                                    <a
                                        href="https://invincibleocean.com/dpa/"
                                        rel="noreferrer"
                                        target="_blank"
                                        onClick={props.scrollToTop}
                                    >
                                        DPA policy
                                    </a>
                                </li>
                                <li className="text-[16px] py-1 font-[400] cursor-pointer">
                                    <a
                                        href="https://invincibleocean.com/privacy-policy/"
                                        rel="noreferrer"
                                        target="_blank"
                                        onClick={props.scrollToTop}
                                    >
                                        Privacy policy
                                    </a>
                                </li>
                            </ol>
                        </div>
                        <div className="max-md:py-2">
                            <h5 className="pb-5 text-[26px] font-[600]">
                                {" "}
                                Industries{" "}
                            </h5>
                            <ol>
                                <a
                                    href="/industries#Finance"
                                    onClick={props.scrollToTop}
                                >
                                    <li className="text-[16px] py-1 font-[400] cursor-pointer">
                                        Finance
                                    </li>
                                </a>
                                <a
                                    href="/industries#Education"
                                    onClick={props.scrollToTop}
                                >
                                    <li className="text-[16px] py-1 font-[400] cursor-pointer">
                                        Education
                                    </li>
                                </a>
                                <a
                                    href="/industries#Retail"
                                    onClick={props.scrollToTop}
                                >
                                    <li className="text-[16px] py-1 font-[400] cursor-pointer">
                                        Retail
                                    </li>
                                </a>
                                <a
                                    href="/industries#Automobile"
                                    onClick={props.scrollToTop}
                                >
                                    <li className="text-[16px] py-1 font-[400] cursor-pointer">
                                        Automobile
                                    </li>
                                </a>
                            </ol>
                        </div>
                        <div className="max-lg:pl-0 max-md:py-2">
                            <h5 className="pb-5 text-[26px] font-[600]">
                                Contact us
                            </h5>
                            <ol>
                                <li className="text-[16px] py-1 font-[400] cursor-pointer text-[#83C1FFF7]">
                                    <a
                                        rel="noreferrer"
                                        target="_blank"
                                        href="mailto:contact@invincibleocean.com"
                                        onClick={props.scrollToTop}
                                    >
                                        contact@invincibleocean.com
                                    </a>
                                </li>
                                <li className="text-[16px] py-1 font-[400] cursor-pointer">
                                    <a
                                        rel="noreferrer"
                                        target="_blank"
                                        onClick={props.scrollToTop}
                                        href="https://www.google.com/maps/place/Invincible+Ocean+%7C+Emptra:+API+Solution+%7C+AI+Driven+Fraud+Detection+%26+Risk+Profiling/@28.4408871,77.0371702,17z/data=!4m14!1m7!3m6!1s0x390d19699f6e49bd:0x3c01b6ad67606134!2sInvincible+Ocean+%7C+Emptra:+API+Solution+%7C+AI+Driven+Fraud+Detection+%26+Risk+Profiling!8m2!3d28.4408871!4d77.0397451!16s%2Fg%2F11ls10wkpz!3m5!1s0x390d19699f6e49bd:0x3c01b6ad67606134!8m2!3d28.4408871!4d77.0397451!16s%2Fg%2F11ls10wkpz?entry=ttu"
                                    >
                                        India address: 201A SAS Tower Sector 38
                                        Gurgaon-122003
                                    </a>
                                </li>
                                <li className="text-[16px] py-1 font-[400]">
                                    <a
                                        rel="noreferrer"
                                        target="_blank"
                                        href="tel:+91-9599066061"
                                        onClick={props.scrollToTop}
                                    >
                                        Contact: +91-9599066061, 0124-4034247
                                    </a>
                                </li>
                                <li className="text-[16px] py-1 font-[400] cursor-pointer">
                                    UK address: 30 Churchill Pl, London E14 5RE
                                </li>

                                <li className="text-[16px] py-1 font-[400]">
                                    <a
                                        rel="noreferrer"
                                        target="_blank"
                                        href="tel:+44-7951055951"
                                    >
                                        Contact: +44 7951 055951
                                    </a>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div className="border-t-[1px] border-t-[#6B6B6B] mt-5 py-5">
                    <h3 className="text-center text-white max-w-screen-2xl px-2 mx-auto max-md:mx-2 text-[14px] font-[400]">
                        © 2023, Invincibleocean Pvt. Ltd. All Rights Reserved
                    </h3>
                </div>
            </div>
        </>
    );
}
