import SuperAdminMetaverseUserView from "./SuperAdminMetaverseUser.view";
import { SuperAdminMetaverseUserContainerProps } from "./SuperAdminMetaverseUser.interface";
import { useEffect, useState } from "react";
import { MetaverseUserService } from "../../Services";
import { useParams } from "react-router-dom";

export default function SuperAdminMetaverseUserContainer(
    props: SuperAdminMetaverseUserContainerProps
) {
    const [user, setUser] = useState({});
    const userID = useParams().id;

    useEffect(() => {
        async function fetchMetaverseUser() {
            const _user = await MetaverseUserService.fetchById(
                userID as string
            );
            setUser(_user?.data?.user);
        }
        fetchMetaverseUser();
        async function fetchUsersMetaverseAssociations() {
            const usersMetaverseAssociations =
                await MetaverseUserService.fetchMetaversesByUserID(
                    userID as string
                );
            setUser((_: any) => {
                return {
                    ..._,
                    metaverses: usersMetaverseAssociations.data.metaverses,
                };
            });
        }
        fetchUsersMetaverseAssociations();
    }, [userID]);

    return (
        <div>
            <SuperAdminMetaverseUserView user={user} />
        </div>
    );
}
