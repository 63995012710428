import { IFaqContainerProps } from "./Faq.interfaces";
import FaqView from "./Faq.view";
import { useState } from "react";

export default function FaqContainer(props: IFaqContainerProps) {
    const [selected, setSelected] = useState<string | number | null>(null);
    const toggle = (i: number | string) => {
        if (selected === i) {
            return setSelected(null);
        }

        setSelected(i);
    };
    return (
        <>
            <FaqView
                toggle={toggle}
                selected={selected}
                category={props.category}
            />
        </>
    );
}
