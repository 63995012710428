import { IPaginationViewProps } from "./Pagination.interface";

export default function SuperAdminDashboardUserView(
    props: IPaginationViewProps
) {
    return (
        <>
            <div className="text-left">
                <div className="">
                    <select
                        name="filtertype"
                        className="mx-1 cursor-pointer border-[1px] rounded-lg px-3 max-md:px-0 py-2 max-md:my-1 font-[600] outline-none bg-gradient-to-r from-blue-500 to-purple-600 text-white"
                        onChange={props.onFilterChange}
                    >
                        <option value="" className="font-[600] text-[#CCCCCC]">
                            Select
                        </option>
                        <option value="name" className="font-[600] text-black">
                            Name
                        </option>
                        <option value="email" className="font-[600] text-black">
                            Email
                        </option>
                        <option
                            value="description"
                            className="font-[600] text-black"
                        >
                            Description
                        </option>
                        <option
                            value="phoneNumber"
                            className="font-[600] text-black"
                        >
                            Phone Number
                        </option>
                    </select>
                    <select
                        name="regex"
                        className="mx-1 cursor-pointer border-[1px] rounded-lg pl-3 pr-9 max-md:pr-0 max-md:pl-0 max-md:my-1  py-2 font-[600] outline-none bg-gradient-to-r from-blue-500 to-purple-600 text-white"
                        onChange={props.onFilterChange}
                    >
                        <option value="" className="font-[600] text-[#CCCCCC]">
                            Select
                        </option>
                        <option value="=%5E" className="font-[600] text-black">
                            Starts with
                        </option>
                        <option value="=%24" className="font-[600] text-black">
                            Ends with
                        </option>
                        <option value="=~" className="font-[600] text-black">
                            Contains
                        </option>
                        <option value="=" className="font-[600] text-black">
                            Equals
                        </option>
                    </select>
                    <input
                        placeholder="Enter the value"
                        name="value"
                        className="border-[1px] rounded-md px-5 mx-1 py-2 outline-blue-500"
                        onChange={props.onFilterChange}
                    ></input>
                    <button
                        onClick={props.handleFilterChange}
                        className="my-1 bg-blue-100 font-[600] px-10 max-md:px-[10px] py-3 text-white transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-gradient-to-l rounded-md mx-2 mr-4 bg-gradient-to-r from-blue-500 to-purple-600"
                    >
                        Apply Filter
                    </button>
                </div>
            </div>
        </>
    );
}
