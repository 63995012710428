import { useParams } from "react-router-dom";
import { DashboardUserService, MetaverseService } from "../../Services";
import { ISuperAdminMetaverseDetailsContainerProps } from "./SuperAdminMetaverseDetails.interfaces";
import SuperAdminMetaverseDetailsView from "./SuperAdminMetaverseDetails.view";
import { useEffect, useState } from "react";

export default function SuperAdminMetaverseDetailsContainer(
    props: ISuperAdminMetaverseDetailsContainerProps
) {
    const metaverseID = useParams<{ id: string }>().id;

    const [createMetaverse, setCreateMetaverse] = useState(false);
    const [updateMetaverse, setUpdateMetaverse] = useState(false);
    const [selection, setSelection] = useState("user-visits");
    const [hoverCards, setHoverCards] = useState("c1");
    const [isOpen, setIsOpen] = useState(false);
    const [dataDropdown, setDataDropdown] = useState({});
    const toggleDropdown = (): void => {
        setIsOpen(!isOpen);
    };
    const [metaverseData, setMetaverseData] = useState<any>({
        name: "",
        description: "",
        admin: "",
        links: {
            WEBGL: {
                build: "",
                assets: "",
            },
            ANDROID: {
                build: "",
                assets: "",
            },
            IOS: {
                build: "",
                assets: "",
            },
            MAC: {
                build: "",
                assets: "",
            },
            VR: {
                build: "",
                assets: "",
            },
            WINDOWS: {
                build: "",
                assets: "",
            },
        },
    });

    const updateNestedValue = (obj: any, keys: string[], value: any): any => {
        if (keys.length === 1) {
            return {
                ...obj,
                [keys[0]]: value,
            };
        }
        const [key, ...remainingKeys] = keys;
        return {
            ...obj,
            [key]: updateNestedValue(obj[key], remainingKeys, value),
        };
    };

    const onDataChange = (e: any) => {
        setMetaverseData((prevData: any) => {
            const keys = e.target.name.split(".");
            return updateNestedValue(prevData, keys, e.target.value);
        });
    };

    const onAdminDropdownEvent = (value: any) => {
        setMetaverseData((prevState: any) => ({
            ...prevState,
            admin: value,
        }));
        setIsOpen(false);
    };

    const handleSelection = (i: string) => {
        setSelection(i);
    };
    const handleCreateMetaverse = async () => {
        setCreateMetaverse(!createMetaverse);
        setMetaverseData((prevState: any) => ({
            ...prevState,
            admin: "",
        }));
        const _dashboardUsers = await DashboardUserService.fetch("");
        setDataDropdown(_dashboardUsers.data.dashboardUsers);
    };
    const handleUpdateMetaverse = async () => {
        setUpdateMetaverse(!updateMetaverse);
        const _dashboardUsers = await DashboardUserService.fetch("");
        setDataDropdown(_dashboardUsers.data.dashboardUsers);
    };
    const handleHoverCards = (i: string) => {
        setHoverCards(i);
    };

    const [metaverse, setMetaverse] = useState({});

    const _createMetaverse = async () => {
        try {
            const data = await MetaverseService.create({
                admin: metaverseData.admin,
                links: metaverseData.links,
                description: metaverseData.description,
                name: metaverseData.name,
            });
            if (data && data.data) {
                setShowMessage(true);
                setMessageClass("success");
                setMessageText("Request Sent successfully!!");
                setTimeout(function () {
                    setShowMessage(false);
                    window.location.reload();
                }, 1000);
            } else {
                setShowMessage(true);
                setMessageClass("error");
                setMessageText(
                    data.message === "Unauthorized"
                        ? "Unauthorized"
                        : data.errors.details[0].message
                );
                setTimeout(function () {
                    setShowMessage(false);
                }, 2000);
            }
            alert("Metaverse created successfully");
            if (metaverseID) {
                const data = await MetaverseService.fetchByID(metaverseID); // Add pagination => TP | 2024-01-30 17:12:40
                if (data && data.data) {
                    setMetaverse(data.data.metaverse);
                    setMetaverseData(data.data.metaverse);
                } else throw new Error("Metaverse ID not found");
            } else throw new Error("Metaverse ID not found");
        } catch (error) {
            console.log(error);
        }
    };
    const _updateMetaverse = async () => {
        try {
            const data = await MetaverseService.update(
                metaverseID ? metaverseID : "",
                {
                    admin: metaverseData.admin,
                    links: metaverseData.links,
                    description: metaverseData.description,
                    name: metaverseData.name,
                }
            );
            if (data && data.data) {
                setShowMessage(true);
                setMessageClass("success");
                setMessageText("Request Sent successfully!!");
                setTimeout(function () {
                    setShowMessage(false);
                    window.location.reload();
                }, 1000);
            } else {
                setShowMessage(true);
                setMessageClass("error");
                setMessageText(
                    data.message === "Unauthorized"
                        ? "Unauthorized"
                        : data.errors.details[0].message
                );
                setTimeout(function () {
                    setShowMessage(false);
                }, 2000);
            }
            alert("Metaverse created successfully");
            if (metaverseID) {
                const data = await MetaverseService.fetchByID(metaverseID); // Add pagination => TP | 2024-01-30 17:12:40
                if (data && data.data) {
                    setMetaverse(data.data.metaverse);
                    setMetaverseData(data.data.metaverse);
                } else throw new Error("Metaverse ID not found");
            } else throw new Error("Metaverse ID not found");
        } catch (error) {
            console.log(error);
        }
    };
    const deleteMetaverse = async () => {
        try {
            const data = await MetaverseService.delete(metaverseID as string);
            if (data.message === "Metaverse deleted") {
                setShowMessage(true);
                setMessageClass("success");
                setMessageText("Request Sent successfully!!");
                setTimeout(function () {
                    setShowMessage(false);
                    window.location.href = "/super-admin/home";
                }, 1000);
            } else {
                setShowMessage(true);
                setMessageClass("error");
                setMessageText(
                    data.message === "Unauthorized"
                        ? "Unauthorized"
                        : data.errors.details[0].message
                );
                setTimeout(function () {
                    setShowMessage(false);
                }, 2000);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const fetchMetaverseUsers = async () => {
            try {
                if (metaverseID) {
                    const data =
                        await MetaverseService.fetchUsersOfMetaverseByID(
                            metaverseID
                        );
                    if (data.data && data.data.users && data.data.users.length)
                        setMetaverse((_: any) => {
                            function mapAssociationsOfMetaverseUsers(
                                users: any
                            ) {
                                return users.map((user: any) => {
                                    return {
                                        managingID: user._id,
                                        ...user.userID,
                                    };
                                });
                            }
                            if (_.users)
                                return {
                                    ..._,
                                    users: [
                                        ..._.users,
                                        ...mapAssociationsOfMetaverseUsers(
                                            data.data.users
                                        ),
                                    ],
                                };
                            else
                                return {
                                    ..._,
                                    users: [
                                        ...mapAssociationsOfMetaverseUsers(
                                            data.data.users
                                        ),
                                    ],
                                };
                        });
                }
            } catch (error) {
                console.error(error);
            }
        };
        const fetchDashboardUsersOfMetaverse = async () => {
            try {
                if (metaverseID) {
                    const data =
                        await MetaverseService.fetchDashboardUsersOfMetaverseByID(
                            metaverseID
                        );
                    if (
                        data.data &&
                        data.data.dashboardUsers &&
                        data.data.dashboardUsers.length
                    )
                        setMetaverse((_: any) => {
                            function mapManagingDashboardUsers(
                                dashboardUsers: any
                            ) {
                                return dashboardUsers.map(
                                    (dashboardUser: any) => {
                                        return {
                                            managingID: dashboardUser._id,
                                            ...dashboardUser.dashboardUserID,
                                        };
                                    }
                                );
                            }
                            if (_.dashboardUsers)
                                return {
                                    ..._,
                                    dashboardUsers: [
                                        ..._.dashboardUsers,
                                        ...mapManagingDashboardUsers(
                                            data.data.dashboardUsers
                                        ),
                                    ],
                                };
                            else
                                return {
                                    ..._,
                                    dashboardUsers: [
                                        ...mapManagingDashboardUsers(
                                            data.data.dashboardUsers
                                        ),
                                    ],
                                };
                        });
                }
            } catch (error) {
                console.error(error);
            }
        };
        const fetchMetaverse = async () => {
            // Add this to redux, useSelector, if array is empty fetch and store to redux => TP | 2024-01-30 16:53:21
            try {
                if (metaverseID) {
                    const data = await MetaverseService.fetchByID(metaverseID); // Add pagination => TP | 2024-01-30 17:12:40
                    if (data && data.data) {
                        fetchDashboardUsersOfMetaverse();
                        fetchMetaverseUsers();
                        setMetaverse(data.data.metaverse);
                        setMetaverseData(data.data.metaverse);
                    } else throw new Error("Metaverse ID not found");
                } else throw new Error("Metaverse ID not found");
            } catch (error) {
                console.error(error);
                window.location.href = "/super-admin/home";
            }
        };
        fetchMetaverse();
    }, [metaverseID]);

    const handleSuperAdminFormSubmit = (
        event: React.FormEvent<HTMLFormElement>
    ) => {
        event.preventDefault();
        _createMetaverse();
    };
    const handleSuperAdminUpdateFormSubmit = (
        event: React.FormEvent<HTMLFormElement>
    ) => {
        event.preventDefault();
        _updateMetaverse();
    };
    const [messageText, setMessageText] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const messageController = () => setShowMessage(!showMessage);
    const [dashboardUsers, setDashboardUsers] = useState<any>([]);
    const [searchterm, setSearchterm] = useState("");
    const handleSearchInput = (e: React.FormEvent<HTMLInputElement>): void => {
        setSearchterm(e.currentTarget.value);
    };
    return (
        <>
            <SuperAdminMetaverseDetailsView
                metaverse={metaverse}
                selection={selection}
                handleSelection={handleSelection}
                handleHoverCards={handleHoverCards}
                hoverCards={hoverCards}
                createMeta={createMetaverse}
                handleCreateMetaverse={handleCreateMetaverse}
                updateMeta={updateMetaverse}
                handleUpdateMetaverse={handleUpdateMetaverse}
                handleSuperAdminFormSubmit={handleSuperAdminFormSubmit}
                messageController={messageController}
                messageText={messageText}
                messageClass={messageClass}
                showMessage={showMessage}
                metaverseDataController={onDataChange}
                deleteMetaverse={deleteMetaverse}
                metaverseData={metaverseData}
                handleSuperAdminUpdateFormSubmit={
                    handleSuperAdminUpdateFormSubmit
                }
                dashboardUsers={dashboardUsers}
                toggleDropdown={toggleDropdown}
                isOpen={isOpen}
                handleSearchInput={handleSearchInput}
                dataDropdown={dataDropdown}
                searchterm={searchterm}
                onAdminDropdownEvent={onAdminDropdownEvent}
            />
        </>
    );
}
