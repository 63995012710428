import ApiService from "./API.service";
import SessionService from "./Session.service";

const baseUrl = process.env.REACT_APP_DASHBOARD_USER_MICROSERVICE_BASE_URL;

const DashboardUserService = {
    /**
     * PUT /dashboard-user
     * @access super-admin
     */
    create: async (
        email: string,
        name: string,
        password: string,
        phoneNumber: string
    ) => {
        try {
            const url = `${baseUrl}dashboard-user`;
            const data = await ApiService.put(url, {
                email: email,
                name: name,
                password: password,
                phoneNumber: phoneNumber,
            });
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * POST /dashboard-user
     * @access public
     */
    login: async (email: string, password: string) => {
        try {
            const url = `${baseUrl}dashboard-user`;
            const data = await ApiService.post(url, {
                email: email,
                password: password,
            });
            console.log(data);
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * GET /dashboard-user
     * @access dashboard-user
     */
    fetch: async (regex: string) => {
        try {
            const url = `${baseUrl}dashboard-users${regex}`;
            const data = await ApiService.get(url);
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * PATCH /dashboard-user
     * @access dashboard-user
     */
    update: async () => {
        try {
            const url = `${baseUrl}dashboard-user`;
            const data = await ApiService.patch(url, {});
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * DELETE /dashboard-user
     * @access dashboard-user
     */
    delete: async () => {
        try {
            const url = `${baseUrl}dashboard-user`;
            const data = await ApiService.delete(url);
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * PATCH /dashboard-user/refresh-token
     * @access public // Will be changed to dashboard-user later => TP
     */
    refreshToken: async () => {
        try {
            const url = `${baseUrl}dashboard-user/refresh-token`;
            const data = await ApiService.patch(url, {
                refreshToken: SessionService.get("refreshToken"),
            });
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * GET /dashboard-user/
     * @access dashboard-user
     */
    fetchDashboardUser: async () => {
        try {
            const url = `${baseUrl}dashboard-user/`;
            const data = await ApiService.get(url);
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * GET /dashboard-user/metaverse/{id}/metaverse-users
     * @access super-admin
     */
    fetchMetaverseUsers: async (metaverseId: string) => {
        try {
            const url = `${baseUrl}dashboard-user/metaverse/${metaverseId}/metaverse-users`;
            const data = await ApiService.get(url);
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * GET /dashboard-user/{id}
     * @access super-admin
     */
    fetchById: async (id: string) => {
        try {
            const url = `${baseUrl}dashboard-user/${id}`;
            const data = await ApiService.get(url);
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * PATCH /dashboard-user/{id}
     * @access super-admin
     */
    updateById: async (
        id: string,
        email: string,
        name: string,
        password: string,
        phoneNumber: string
    ) => {
        try {
            const url = `${baseUrl}dashboard-user/${id}`;
            const data = await ApiService.patch(url, {
                email: email,
                name: name,
                password: password,
                phoneNumber: phoneNumber,
            });
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * DELETE /dashboard-user/{id}
     * @access super-admin
     */
    deleteById: async (id: string) => {
        try {
            const url = `${baseUrl}dashboard-user/${id}`;
            const data = await ApiService.delete(url);
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * GET /dashboard-user/metaverses
     * @access dashboard-user
     */
    fetchMetaverses: async () => {
        try {
            const url = `${baseUrl}dashboard-user/metaverses`;
            const data = await ApiService.get(url);
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * GET /dashboard-user/{id}/metaverses
     * @access super-admin
     */
    fetchMetaversesByDashboardUserId: async (id: string) => {
        try {
            const url = `${baseUrl}dashboard-user/${id}/metaverses`;
            const data = await ApiService.get(url);
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
};

export default DashboardUserService;
