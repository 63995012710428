import { IMessageContainerProps } from "./Message.interfaces";
import MessageView from "./Message.view";

export default function MessageContainer(props: IMessageContainerProps) {
    return (
        <>
            <MessageView
                demoController={props.demoController}
                messageText={props.messageText}
                messageClass={props.messageClass}
            />
        </>
    );
}
