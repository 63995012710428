import { DashboardUserService, SessionService } from "../../Services";
import { IMyAccountContainerProps } from "./MyAccount.interfaces";
import MyAccountView from "./MyAccount.view";
import { useEffect, useState } from "react";

export default function MyAccountContainer(props: IMyAccountContainerProps) {
    const [selection, setSelection] = useState("basic-details");
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [dashboardUser, setDashboardUser] = useState({});
    const userData = SessionService.get("user");
    const handleSelection = (i: string) => {
        setSelection(i);
    };
    const handleSetShowPassword = (i: boolean) => {
        setShowPassword(i);
    };
    const handleSetShowNewPassword = (i: boolean) => {
        setShowNewPassword(i);
    };
    const handleSetShowConfirmPassword = (i: boolean) => {
        setShowConfirmPassword(i);
    };
    const handleSetCurrentPassword = (i: string) => {
        setCurrentPassword(i);
    };
    const handleSetNewPassword = (i: string) => {
        setNewPassword(i);
    };
    const handleSetConfirmPassword = (i: string) => {
        setConfirmPassword(i);
    };
    const fetchDashboardUser = async () => {
        try {
            const data = await DashboardUserService.fetch(""); // =>SA || 2024-02-13 12:25:25 to be implemented as the fetchbyid for getting the dashboard user data has access as the Super Admin only, and the fetch returns the dashboard userss
            if (data && data.data) {
                setDashboardUser(data.data.dashboardUser);
                return;
            } else throw new Error("User not found");
        } catch (error) {
            console.error(error);
            // window.location.href = "/super-admin/home";
        }
    };
    useEffect(() => {
        fetchDashboardUser();
    }, []);
    return (
        <>
            <MyAccountView
                selection={selection}
                showPassword={showPassword}
                showNewPassword={showNewPassword}
                showConfirmPassword={showConfirmPassword}
                currentPassword={currentPassword}
                newPassword={newPassword}
                confirmPassword={confirmPassword}
                handleSelection={handleSelection}
                handleSetShowPassword={handleSetShowPassword}
                handleSetConfirmPassword={handleSetConfirmPassword}
                handleSetNewPassword={handleSetNewPassword}
                handleSetCurrentPassword={handleSetCurrentPassword}
                handleSetShowNewPassword={handleSetShowNewPassword}
                handleSetShowConfirmPassword={handleSetShowConfirmPassword}
                dashboardUser={dashboardUser}
            />
        </>
    );
}
