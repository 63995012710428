import { IUserDetailsContainerProps } from "./UserDetails.interfaces";
import UserDetailsView from "./UserDetails.view";
import { useState } from "react";

export default function UserDetailsContainer(
    props: IUserDetailsContainerProps
) {
    const [btnName, SetBtnName] = useState("logs");
    const [record] = useState([]);
    const user = localStorage.getItem("user");
    const handleClick = (e: string) => {
        SetBtnName(e);
    };

    return (
        <>
            <UserDetailsView
                btnName={btnName}
                handleClick={handleClick}
                record={record}
                user={user}
            />
        </>
    );
}
