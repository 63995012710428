import { useState } from "react";

import { ILoginWithCodeContainerProps } from "./LoginWithCode.interfaces";
import LoginWithCodeView from "./LoginWithCode.view";
import { MetaverseUserService } from "../../Services";

export default function LoginContainer(props: ILoginWithCodeContainerProps) {
    const handleLogin = async () => {
        const _code = code.trimEnd();
        if (_code.length !== 6) {
            setMessageText("The length of OTP should be 6");
            setMessageClass("error");
            setShowMessage(true);
            return;
        }
        try {
            const data = await MetaverseUserService.loginWithCode(_code);
            // console.log(data);
            setMessageText("Logged in successfully");
            setMessageClass("success");
            setShowMessage(true);
        } catch (error: any) {
            console.error(error);
            setMessageText("An error occurred");
            setMessageClass("error");
            setShowMessage(true);
        }
    };

    const [messageText, setMessageText] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const [code, setCode] = useState("      ");
    const messageController = () => setShowMessage(!showMessage);

    const handleInputChange = (e: any) => {
        const index = e.target.id;
        const value = e.key;
        if (value === "Backspace") {
            setCode((prevCode) => {
                const newCode = prevCode.split("");
                newCode[index] = " ";
                return newCode.join("");
            });
            if (index > 0)
                e.target.parentElement.children[Number(index) - 1].focus();
        }
        if (Number.isNaN(Number(value))) return;
        else {
            setCode((prevCode) => {
                const newCode = prevCode.split("");
                newCode[index] = value;
                return newCode.join("");
            });
            if (index < 5)
                e.target.parentElement.children[Number(index) + 1].focus();
        }
    };

    return (
        <>
            <LoginWithCodeView
                handleInputChange={handleInputChange}
                handleLogin={handleLogin}
                messageController={messageController}
                messageText={messageText}
                messageClass={messageClass}
                showMessage={showMessage}
                code={code}
            />
        </>
    );
}
