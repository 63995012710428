import { useState } from "react";

import { ILoaderContainerProps } from "./Loader.interfaces";
import LoaderView from "./Loader.view";

export default function LoginContainer(props: ILoaderContainerProps) {
    return (
        <>
            <LoaderView />
        </>
    );
}
