import { ISuperAdminDashboardUsersContainerProps } from "./SuperAdminDashboardUsers.interfaces";
import SuperAdminDashboardUsersView from "./SuperAdminDashboardUsers.view";
import { useEffect, useState } from "react";
import { DashboardUserService } from "../../Services";

export default function SuperAdminDsashboardUsersContainer(
    props: ISuperAdminDashboardUsersContainerProps
) {
    const [messageText, setMessageText] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const [createDashboardUser, setCreateDashboardUser] = useState(false);
    const [updateDashboardUser, setUpdateDashboardUser] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [dashboardUserId, setDashboardUserId] = useState("");
    const [parentQueryString, setParentQueryString] = useState("");
    const [dashboardUserData, setDashboardUserData] = useState<any>({
        email: "",
        password: "",
        name: "",
        phoneNumber: "",
    });
    const handleCreateDashboardUser = () => {
        setCreateDashboardUser(!createDashboardUser);
    };
    const handleUpdateDashboardUser = (id: string) => {
        setDashboardUserId(id);
        setUpdateDashboardUser(!updateDashboardUser);
        async function fetchDashboardUser() {
            const _dashboardUser = await DashboardUserService.fetchById(
                id as string
            );
            setDashboardUserData(_dashboardUser.data.dashboardUser);
        }
        if (id) {
            fetchDashboardUser();
        }
    };
    const messageController = () => setShowMessage(!showMessage);
    const handleSetShowPassword = (i: boolean) => {
        setShowPassword(i);
    };
    const [dashboardUsers, setDashboardUsers] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const fetchDashboardUsers = async (x: any) => {
        // Add this to redux, useSelector, if array is empty fetch and store to redux => TP | 2024-01-30 16:53:21
        try {
            setDashboardUsers([]);
            const data = await DashboardUserService.fetch(x);
            setLoaded(true);
            setDashboardUsers(data.data.dashboardUsers);
        } catch (error) {
            console.log(error);
        }
    };
    const updateNestedValue = (obj: any, keys: string[], value: any): any => {
        if (keys.length === 1) {
            return {
                ...obj,
                [keys[0]]: value,
            };
        }
        const [key, ...remainingKeys] = keys;
        return {
            ...obj,
            [key]: updateNestedValue(obj[key], remainingKeys, value),
        };
    };

    const onDataChange = (e: any) => {
        setDashboardUserData((prevData: any) => {
            const keys = e.target.name.split(".");
            return updateNestedValue(prevData, keys, e.target.value);
        });
    };
    const handleFilterChange = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        fetchDashboardUsers(parentQueryString);
    };

    const _createDashboardUser = async () => {
        try {
            const data = await DashboardUserService.create(
                dashboardUserData.email,
                dashboardUserData.name,
                dashboardUserData.password,
                dashboardUserData.phoneNumber
            );
            if (data && data.data) {
                setShowMessage(true);
                setMessageClass("success");
                setMessageText("Request Sent successfully!!");
                setTimeout(function () {
                    setShowMessage(false);
                    window.location.reload();
                }, 1000);
            } else {
                setShowMessage(true);
                setMessageClass("error");
                setMessageText(
                    data.message === "Unauthorized"
                        ? "Unauthorized"
                        : data.errors.details[0].message
                );
                setTimeout(function () {
                    setShowMessage(false);
                }, 2000);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const deleteDashboardUser = async (id: string) => {
        try {
            const data = await DashboardUserService.deleteById(id);
            if (data.message === `DashboardUser ${id} deleted`) {
                setShowMessage(true);
                setMessageClass("success");
                setMessageText("Request Sent successfully!!");
                setTimeout(function () {
                    setShowMessage(false);
                    window.location.reload();
                }, 1000);
            } else {
                setShowMessage(true);
                setMessageClass("error");
                setMessageText(
                    data.message === "Unauthorized"
                        ? "Unauthorized"
                        : data.errors.details[0].message
                );
                setTimeout(function () {
                    setShowMessage(false);
                }, 1000);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const _updateDashboardUser = async () => {
        try {
            const data = await DashboardUserService.updateById(
                dashboardUserId,
                dashboardUserData.email,
                dashboardUserData.name,
                dashboardUserData.password,
                dashboardUserData.phoneNumber
            );
            if (data && data.data) {
                setShowMessage(true);
                setMessageClass("success");
                setMessageText("Request Sent successfully!!");
                setTimeout(function () {
                    setShowMessage(false);
                    window.location.reload();
                }, 1000);
            } else {
                setShowMessage(true);
                setMessageClass("error");
                setMessageText(
                    data.message === "Unauthorized"
                        ? "Unauthorized"
                        : data.errors.details[0].message
                );
                setTimeout(function () {
                    setShowMessage(false);
                }, 2000);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchDashboardUsers("");
    }, []);

    const handleQueryStringChange = (newQueryString: string) => {
        setParentQueryString(newQueryString);
    };
    const handleCreateDashboardUserFormSubmit = (
        event: React.FormEvent<HTMLFormElement>
    ) => {
        event.preventDefault();
        _createDashboardUser();
    };
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const handleNextPage = () => {
        const querystring = `?page=${currentPage + 1}`;
        fetchDashboardUsers(parentQueryString + "&" + querystring);
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        const querystring = `?page=${currentPage - 1}`;
        fetchDashboardUsers(parentQueryString + "&" + querystring);
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    return (
        <>
            <SuperAdminDashboardUsersView
                loaded={loaded}
                messageController={messageController}
                messageText={messageText}
                messageClass={messageClass}
                showMessage={showMessage}
                dashboardUsers={dashboardUsers}
                handleCreateDashboardUser={handleCreateDashboardUser}
                createDashboardUser={createDashboardUser}
                dashboardUserDataController={onDataChange}
                handleCreateDashboardUserFormSubmit={
                    handleCreateDashboardUserFormSubmit
                }
                handleSetShowPassword={handleSetShowPassword}
                showPassword={showPassword}
                deleteDashboardUser={deleteDashboardUser}
                _updateDashboardUser={_updateDashboardUser}
                startIndex={startIndex}
                endIndex={endIndex}
                handleNextPage={handleNextPage}
                handlePrevPage={handlePrevPage}
                currentPage={currentPage}
                handleUpdateDashboardUser={handleUpdateDashboardUser}
                updateDashboardUser={updateDashboardUser}
                dashboardUserData={dashboardUserData}
                handleFilterChange={handleFilterChange}
                handleQueryStringChange={handleQueryStringChange}
            />
        </>
    );
}
