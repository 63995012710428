import Navigation from "../Navigation";
import UserLoginBox from "../UserLoginBox";
import { ILandingPageViewProps } from "./LandingPage.interfaces";
import BookDemo from "../BookDemo";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import data from "../ResponisveCarousel.json";
export default function LandingPageView(props: ILandingPageViewProps) {
    return (
        <>
            {props.showlogin === true && !props.user && (
                <UserLoginBox
                    redirectionURL="/metaverse/65b741def8309762720ad53f"
                    loginController={props.loginController}
                /> // Since the line 122, says Login to Eternity City, but can be changed later => TP | 2024-01-30 14:39:44
            )}
            {props.showDemo === true && (
                <BookDemo demoController={props.demoController} />
            )}
            <div className="bg-cover font-['roboto'] relative h-auto">
                <video
                    autoPlay
                    muted
                    loop
                    playsInline
                    className="absolute top-0 left-0 w-full h-full object-cover"
                >
                    <source
                        src="./images/dashboardheader/backdrop2.webm"
                        type="video/webm"
                    />
                    Your browser does not support the video tag.
                </video>
                <div className="absolute inset-0 bg-black opacity-60"></div>
                <Navigation />
                <div className="max-w-screen-2xl text-center relative z-10 px-2 mx-auto max-md:mx-2 py-72 max-md:py-24 font-roboto font-semibold text-white">
                    <div className="text-center">
                        <h2 className="text-6xl max-md:text-[45px] max-md:leading-[50px] max-md:py-5">
                            Crafting Artificial Intelligence for the Metaverse
                        </h2>
                    </div>
                    <div className="text-center  my-10 max-md:mt-2 text-[18px] font-[600]">
                        <p>
                            Enabling Artificial Intelligence Solutions for
                            enterprises.
                        </p>
                    </div>
                    <div className="text-center">
                        <button
                            className="bg-blue-100 px-10 max-md:px-[78px] py-3 text-white transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-gradient-to-l rounded-md mr-4 bg-gradient-to-r from-blue-500 to-purple-600"
                            onClick={props.demoController}
                        >
                            Book a Demo
                        </button>
                    </div>
                </div>
            </div>
            {
                //section one begins
                <>
                    <div className="bg-black py-4 relative font-['roboto']">
                        <div
                            className="py-10 bg-cover border-[2px] border-[#489BF6] -mt-36 max-md:-mt-20 text-white rounded-lg max-w-screen-2xl mx-auto max-2xl:mx-2 relative"
                            style={{
                                backgroundImage: `url('./images/dashboardsectionone/displaycard.webp')`,
                            }}
                        >
                            <p className="absolute top-0 left-0 bg-[#489BF6] py-2 px-4 rounded-r-md text-[14px] font-[600]">
                                INTRODUCING
                            </p>
                            <div className="p-6 my-4 text-left w-4/6 max-md:w-full">
                                <div className="text-left text-[46px] font-[700] bg-gradient-to-b from-white to-opacity-100 inline-block  shadow-2xl text-transparent bg-clip-text">
                                    "Empower Memories: Volunteer with Us"
                                </div>
                                <p className="py-5">
                                    Introducing a groundbreaking platform where
                                    you can once again meet and converse with
                                    departed loved ones, bridging the gap
                                    between past and present in a deeply
                                    personal and meaningful way.
                                </p>
                                <div className="mt-10 flex items-end max-md:block">
                                    <button
                                        onClick={props.demoController}
                                        className="max-md:my-4 hover:border-[#489BF6] hover:text-[#489BF6] border-[3px] mr-10 max-md:mr-0 border-gradient-to-r hover:scale-110 duration-300 font-[700] from-[#7B49A9] to-[#489BF6] text-white rounded-r-full rounded-l-full px-10"
                                    >
                                        Enroll to Eternity
                                    </button>
                                    <div className="flex max-md:py-4 ">
                                        <div className="grid-cols-1 px-2 border-r-[1px] border-r-[#3D3C46]">
                                            <h5 className="text-[32px]">
                                                {props.days}
                                            </h5>
                                            <p className="text-[12px]">Days</p>
                                        </div>
                                        <div className="grid-cols-1 px-2 border-r-[1px] border-r-[#3D3C46]">
                                            <h5 className="text-[32px]">
                                                {props.hours}
                                            </h5>
                                            <p className="text-[12px]">Hours</p>
                                        </div>
                                        <div className="grid-cols-1 px-2 border-r-[1px] border-r-[#3D3C46]">
                                            <h5 className="text-[32px]">
                                                {props.minutes}
                                            </h5>
                                            <p className="text-[12px]">
                                                Minutes
                                            </p>
                                        </div>
                                        <div className="grid-cols-1 px-2">
                                            <h5 className="text-[32px]">
                                                {props.seconds}
                                            </h5>
                                            <p className="text-[12px]">
                                                seconds
                                            </p>
                                        </div>
                                    </div>
                                    <p className="max-md:py-4 text-[#FF8A05] px-2 max-md:px-0 font-[400] text-[12px]">
                                        Time left till 3rd march
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-[#000000] text-center text-white font-semibold font-['roboto'] text-[40px] py-16">
                        <div className="max-w-screen-2xl truncate px-2 mx-auto max-md:mx-2">
                            <h5 className="pb-20 max-md:pb-4 text-[40px] max-md:text-[36px] leading-[48px] font-[600]">
                                Brands we worked with
                            </h5>

                            <div className="">
                                <Carousel
                                    containerClass="carousel-container"
                                    responsive={data.responsive}
                                    swipeable={false}
                                    draggable={false}
                                    showDots={false}
                                    ssr={true}
                                    infinite={true}
                                    autoPlay={true}
                                    autoPlaySpeed={1500}
                                    keyBoardControl={true}
                                    removeArrowOnDeviceType={[
                                        "tablet",
                                        "mobile",
                                        "desktop",
                                        "superLargeDesktop",
                                    ]}
                                    centerMode={true}
                                >
                                    <div className="flex items-center justify-center h-full">
                                        <img
                                            className="object-contain mr-8 w-[80px] pt-5 max-lg:mr-2 max-md:py-4"
                                            src="./images/dashboardsectionone/tata.webp"
                                            alt=""
                                        />
                                    </div>
                                    <div className="flex items-center justify-center h-full">
                                        <img
                                            className="object-contain mx-8 w-[80px] max-lg:mx-2 max-md:py-4"
                                            src="./images/dashboardsectionone/audi.webp"
                                            alt=""
                                        />
                                    </div>
                                    <div className="flex items-center justify-center h-full">
                                        <img
                                            className="object-contain mx-8 w-[80px] pt-5 max-lg:mx-2 max-md:py-4"
                                            src="./images/dashboardsectionone/bajaj.webp"
                                            alt=""
                                        />
                                    </div>
                                    <div className="flex items-center justify-center h-full">
                                        <img
                                            className="object-contain mx-8 w-[80px] pt-5 max-lg:mx-2 max-md:py-4"
                                            src="./images/dashboardsectionone/droom.webp"
                                            alt=""
                                        />
                                    </div>

                                    <div className="flex items-center justify-center h-full">
                                        <img
                                            className="object-contain mx-8 w-[80px] pt-5 max-lg:mx-2 max-md:py-4"
                                            src="./images/dashboardsectionone/gromo.webp"
                                            alt=""
                                        />
                                    </div>
                                    <div className="flex items-center justify-center h-full">
                                        <img
                                            className="object-contain mx-8 w-[80px] pt-2 max-lg:mx-2 max-md:py-4"
                                            src="./images/dashboardsectionone/maruti.webp"
                                            alt=""
                                        />
                                    </div>
                                    <div className="flex items-center justify-center h-full">
                                        <img
                                            className="object-contain ml-8 w-[80px] px-2.5 max-lg:ml-2 max-md:py-4"
                                            src="./images/dashboardsectionone/olx.webp"
                                            alt=""
                                        />
                                    </div>
                                    <div className="flex items-center justify-center h-full">
                                        <img
                                            className="object-contain ml-8 w-[80px] pt-5 max-lg:ml-2 max-md:py-4"
                                            src="./images/dashboardsectionone/royal.webp"
                                            alt=""
                                        />
                                    </div>
                                    <div className="flex items-center justify-center h-full">
                                        <img
                                            className="object-contain ml-8 w-[80px] max-lg:ml-2 max-md:py-4"
                                            src="./images/dashboardsectionone/skoda.webp"
                                            alt=""
                                        />
                                    </div>
                                    <div className="flex items-center justify-center h-full">
                                        <img
                                            className="object-contain ml-8 w-[80px] pt-5 max-lg:ml-2 max-md:py-4"
                                            src="./images/dashboardsectionone/volks.webp"
                                            alt=""
                                        />
                                    </div>
                                </Carousel>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white font-['roboto']">
                        <div className="grid grid-cols-2 items-center max-w-screen-2xl px-2 mx-auto max-md:mx-2 py-20 max-md:py-4 max-md:block">
                            <div className="p-10">
                                <img
                                    src="./images/dashboardsectionone/headgear.webp"
                                    alt=""
                                />
                            </div>
                            <div className="">
                                <div className="text-left font-roboto">
                                    <p className="bg-gradient-to-r from-blue-500 to-purple-800 text-[20px] font-medium text-transparent bg-clip-text py-5">
                                        Embrace the Artificial Intelligence
                                        Metaverse Wave
                                    </p>
                                    <h5 className="font-semibold text-[48px] text-[#1C2347] pb-5 leading-[56.25px] max-md:text-[30px] max-md:leading-[40px]">
                                        Explore the Transformation of
                                        Conventional Businesses: The Fusion of
                                        AI and Virtual Reality
                                    </h5>
                                    <p className="leading-[30px] text-[18px] font-[400] text-[#6B6B6B]">
                                        Get expert advice from invincibleMeta.ai
                                        on AI and Virtual Reality: Embrace the
                                        metaverse evolution. With
                                        invincibleMeta.ai, navigate your way to
                                        achievement in this dynamic virtual
                                        realm. The metaverse isn't a far-off
                                        dream; it's a present reality, growing
                                        swiftly. Begin your exploration of the
                                        metaverse with us and see how this
                                        virtual dimension can reshape your
                                        enterprise.
                                    </p>
                                </div>
                                <div className="flex py-5 max-md:grid max-md:grid-cols-2 max-md:items-center">
                                    <p className="border-l-[3px] border-l-[#015B8D] px-3 max-md:px-1 max-md:my-2">
                                        Artificial Intelligence
                                    </p>
                                    <p className="border-l-[3px] border-l-[#F9BA21] px-3 max-md:px-1 max-md:my-2">
                                        AR/VR/MR Solutions
                                    </p>
                                    <p className="border-l-[3px] border-l-[#AF23F3] px-3 max-md:px-1 max-md:my-2">
                                        Metaverse Training
                                    </p>
                                    <p className="border-l-[3px] border-l-[#038918] px-3 max-md:px-1 max-md:my-2">
                                        Virtual World
                                    </p>
                                </div>
                                <div className="text-left py-5">
                                    <button
                                        className="bg-blue-100 px-10 py-3 hover:scale-[1.1] hover:bg-gradient-to-l transition duration-300 ease-in-out rounded-md mr-4 bg-gradient-to-r from-blue-500 to-purple-600 text-white font-semibold"
                                        onClick={props.demoController}
                                    >
                                        Book a Demo
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            {
                //section two begins
                <>
                    <div
                        className="bg-cover font-['roboto']"
                        style={{
                            backgroundImage: `url('./images/randomtoberemoved/section2back.png')`,
                        }}
                    >
                        <div className="max-w-screen-2xl px-2 mx-auto max-md:mx-2">
                            <h5 className="py-24 max-md:py-10 font-roboto font-semibold text-[48px] max-md:text-[36px] leading-[56.25px] text-[#FFFFFF]">
                                AI Driven AR/VR software development process
                            </h5>

                            <div className="text-white text-left pb-24 max-md:pb-10">
                                <Carousel
                                    containerClass="carousel-container"
                                    responsive={data.responsiveSection2}
                                    partialVisible={true}
                                    swipeable={true}
                                    draggable={true}
                                    showDots={true}
                                    ssr={true}
                                    infinite={true}
                                    keyBoardControl={true}
                                >
                                    <div className="max-h-[350px] my-10 max-md:my-8 hover:scale-[1.05] mx-10 max-[400px]:mx-0 max-[350px]:ml-10 max-[600px]:ml-28 max-[440px]:ml-16 max-[430px]:ml-12 max-[400px]:ml-0 max-xl:my-4 group transition duration-300 ease-in-out border-t-[5px] border-t-[#0929EC] h-[350px] bg-[#000000B0] w-[250px] rounded-md max-md:w-[300px]">
                                        <div className="p-5">
                                            <div>
                                                <img
                                                    src="./images/dashboardsectiontwo/icononeblue.webp"
                                                    className="py-5 w-[50px] group-hover:hidden object-cover transition duration-300 ease-in-out"
                                                    alt=""
                                                />
                                                <img
                                                    src="./images/dashboardsectiontwo/icononewhite.webp"
                                                    className="py-5 w-[50px] hidden group-hover:block object-cover transition duration-300 ease-in-out"
                                                    alt=""
                                                />
                                            </div>
                                            <div>
                                                <h5 className="font-semibold text-[22px] leading-[25.7px] pb-3">
                                                    1.Discovery
                                                </h5>
                                                <ol className="list-disc list-outside font-[400] text-[16px] leading-[25px] px-2">
                                                    <li>
                                                        Analyze requirements
                                                    </li>
                                                    <li>
                                                        Make high-level
                                                        estimation
                                                    </li>
                                                    <li>
                                                        Provide technology
                                                        consulting
                                                    </li>
                                                    <li>
                                                        Exploratory data
                                                        analysis
                                                    </li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="max-h-[350px] my-10 max-md:my-8 hover:scale-[1.05] mx-10 max-[400px]:mx-0 max-[350px]:ml-10 max-[600px]:ml-28 max-[440px]:ml-16 max-[430px]:ml-12 max-[400px]:ml-0 max-xl:my-4 group transition duration-300 ease-in-out border-t-[5px] border-t-[#FF3535] h-[350px] bg-[#000000B0] w-[250px] rounded-md max-md:w-[300px]">
                                        <div className="p-5">
                                            <div>
                                                <img
                                                    src="./images/dashboardsectiontwo/icontwoblue.webp"
                                                    className="py-5 w-[50px] group-hover:hidden object-cover transition duration-300 ease-in-out"
                                                    alt=""
                                                />
                                                <img
                                                    src="./images/dashboardsectiontwo/icontwowhite.webp"
                                                    className="py-5 w-[50px] hidden group-hover:block object-cover transition duration-300 ease-in-out"
                                                    alt=""
                                                />
                                            </div>
                                            <div>
                                                <h5 className="font-semibold text-[22px] leading-[25.7px] pb-3">
                                                    2.Project setup
                                                </h5>
                                                <ol className="list-disc list-outside font-[400] text-[16px] leading-[25px] px-2">
                                                    <li>
                                                        Select the engagement
                                                        model
                                                    </li>
                                                    <li>Build the core team</li>
                                                    <li>
                                                        Prepare a roadmap for
                                                        the project
                                                    </li>
                                                    <li>
                                                        Prepare tech documents
                                                    </li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="max-h-[350px] my-10 max-md:my-8 hover:scale-[1.05] mx-10 max-[400px]:mx-0 max-[350px]:ml-10 max-[600px]:ml-28 max-[440px]:ml-16 max-[430px]:ml-12 max-[400px]:ml-0 max-xl:my-4 group transition duration-300 ease-in-out border-t-[5px] border-t-[#09EC48] h-[350px] bg-[#000000B0] w-[250px] rounded-md max-md:w-[300px]">
                                        <div className="p-5">
                                            <div>
                                                <img
                                                    src="./images/dashboardsectiontwo/iconthreeblue.webp"
                                                    className="py-5 w-[50px] group-hover:hidden object-cover transition duration-300 ease-in-out"
                                                    alt=""
                                                />
                                                <img
                                                    src="./images/dashboardsectiontwo/iconthreewhite.webp"
                                                    className="py-5 w-[50px] hidden group-hover:block object-cover transition duration-300 ease-in-out"
                                                    alt=""
                                                />
                                            </div>
                                            <div>
                                                <h5 className="font-semibold text-[22px] leading-[25.7px] pb-3">
                                                    3.Development
                                                </h5>
                                                <ol className="list-disc list-outside font-[400] text-[16px] leading-[25px] px-2">
                                                    <li>
                                                        Use Agile development
                                                        methodology
                                                    </li>
                                                    <li>
                                                        Progress reporting on
                                                        each iteration
                                                    </li>
                                                    <li>
                                                        Perform internal and
                                                        release testing
                                                    </li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="max-h-[350px] my-10 max-md:my-8 hover:scale-[1.05] mx-10 max-[400px]:mx-0 max-[350px]:ml-10 max-[600px]:ml-28 max-[440px]:ml-16 max-[430px]:ml-12 max-[400px]:ml-0 max-xl:my-4 group transition duration-300 ease-in-out border-t-[5px] border-t-[#8235FF] h-[350px] bg-[#000000B0] w-[250px] rounded-md max-md:w-[300px]">
                                        <div className="p-5">
                                            <div>
                                                <img
                                                    src="./images/dashboardsectiontwo/iconfourblue.webp"
                                                    className="py-5 w-[50px] group-hover:hidden object-cover transition duration-300 ease-in-out"
                                                    alt=""
                                                />
                                                <img
                                                    src="./images/dashboardsectiontwo/iconfourwhite.webp"
                                                    className="py-5 w-[50px] hidden group-hover:block object-cover transition duration-300 ease-in-out"
                                                    alt=""
                                                />
                                            </div>
                                            <div>
                                                <h5 className="font-semibold text-[22px] leading-[25.7px] pb-3">
                                                    4.Live release
                                                </h5>
                                                <ol className="list-disc list-outside font-[400] text-[16px] leading-[25px] px-2">
                                                    <li>
                                                        Final testing of the
                                                        complete system
                                                    </li>
                                                    <li>
                                                        Solve possible issues
                                                    </li>
                                                    <li>
                                                        Live release after
                                                        approval
                                                    </li>
                                                    <li>Collect feedback</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="max-h-[350px] my-10 max-md:my-8 hover:scale-[1.05] mx-10 max-[400px]:mx-0 max-[350px]:ml-10 max-[600px]:ml-28 max-[440px]:ml-16 max-[430px]:ml-12 max-[400px]:ml-0 max-xl:my-4 group transition duration-300 ease-in-out border-t-[5px] border-t-[#CB35FF] h-[350px] bg-[#000000B0] w-[250px] rounded-md max-md:w-[300px]">
                                        <div className="p-5">
                                            <div>
                                                <img
                                                    src="./images/dashboardsectiontwo/iconfiveblue.webp"
                                                    className="py-5 w-[55px] group-hover:hidden object-cover transition duration-300 ease-in-out"
                                                    alt=""
                                                />
                                                <img
                                                    src="./images/dashboardsectiontwo/iconfivewhite.webp"
                                                    className="py-5 w-[50px] hidden group-hover:block object-cover transition duration-300 ease-in-out"
                                                    alt=""
                                                />
                                            </div>
                                            <div>
                                                <h5 className="font-semibold text-[22px] leading-[25.7px] pb-3">
                                                    5.Support
                                                </h5>
                                                <ol className="list-disc list-outside font-[400] text-[16px] leading-[25px] px-2">
                                                    <li>Knowledge transfer</li>
                                                    <li>
                                                        Perform continuous
                                                        server monitoring
                                                    </li>
                                                    <li>
                                                        Allocate team members to
                                                        fix bugs and make
                                                        improvements
                                                    </li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel>
                            </div>
                        </div>
                        <div className="text-center">
                            <div className="text-white">
                                <div className="max-w-screen-2xl px-2 mx-auto max-md:mx-2 py-40 max-md:py-10">
                                    <h3 className="font-[700] font-roboto text-[80px] leading-[93.75px] pt-36 max-md:pt-10 max-md:text-[40px] max-md:leading-[50px] px-24 max-md:px-2">
                                        Journey to the Edge of Imagination. Your
                                        Portal to Infinite Possibilities Awaits.
                                    </h3>
                                    <p className="font-[400] text-[18px] leading-[30px] py-10 px-48 max-md:px-2">
                                        Dive into a realm where your wildest
                                        dreams take digital form. Experience a
                                        world where every corner offers a new
                                        adventure, every interaction sparks
                                        creativity.
                                    </p>

                                    <div className="pb-16">
                                        <button
                                            onClick={props.demoController}
                                            className="bg-blue-100 text-white px-10 py-3 font-[600] hover:scale-[1.05] transition duration-300 ease-in-out hover:bg-gradient-to-l rounded-md mr-4 bg-gradient-to-r from-blue-500 to-purple-600"
                                        >
                                            Start your journey
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            {
                //section three begins
                <>
                    <div className="font-['roboto']">
                        <div
                            className="bg-cover py-10"
                            style={{
                                backgroundImage: `url('./images/dashboardsectionthree/backdrop.webp')`,
                            }}
                        >
                            <div className="max-w-screen-2xl px-2 mx-auto max-md:mx-2 font-roboto">
                                <div className="grid grid-cols-2 max-md:block items-center">
                                    <div className="">
                                        <img
                                            src="./images/dashboardsectionthree/vrsearching.webp"
                                            className=""
                                            alt=""
                                        />
                                    </div>
                                    <div className="text-left">
                                        <h5 className="font-[700] text-[48px] max-lg:text-[38px] leading-[56px] text-[#1C2347] py-5 max-md:text-[30px]">
                                            Why Choose InvincibleMeta.AI?
                                        </h5>
                                        <p className="font-[500] text-[#6B6B6B] text-[18px] leading-[21.09px] py-5">
                                            InvincibleMeta.AI is your trusted
                                            partner in the realm of cutting-edge
                                            technology. Here's why you should
                                            partner with us.
                                        </p>
                                        <div>
                                            <div className="border-b-2 border-b-[#D9D9D9] pb-10">
                                                <div className="flex items-center">
                                                    <img
                                                        src="./images/dashboardsectionthree/webicon.webp"
                                                        alt=""
                                                    />
                                                    <h5 className="text-[#136CC5] text-[30px] leading-[36px] max-md:text-[24px] py-3 px-2">
                                                        Leading AI Solutions
                                                    </h5>
                                                </div>
                                                <p className="font-[500] text-[#6B6B6B] leading-[30px]">
                                                    Our advanced AI algorithms
                                                    and technologies are
                                                    tailor-made to meet your
                                                    specific needs. From machine
                                                    learning to natural language
                                                    processing, we harness the
                                                    full power of AI to drive
                                                    your success.
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="border-b-2 border-b-[#D9D9D9] pb-10">
                                                <div className="flex items-center">
                                                    <img
                                                        src="./images/dashboardsectionthree/diceicon.webp"
                                                        alt=""
                                                    />
                                                    <h5 className="text-[#136CC5] text-[30px] leading-[36px] max-md:text-[24px] py-3 px-2">
                                                        Pioneering Metaverse AR
                                                        VR
                                                    </h5>
                                                </div>
                                                <p className="font-[500] text-[#6B6B6B] leading-[30px]">
                                                    Immerse your audience in
                                                    unforgettable experiences
                                                    with our Metaverse AR VR
                                                    solutions. Explore new
                                                    dimensions of engagement and
                                                    interaction that redefine
                                                    the future.
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="pb-10">
                                                <div className="flex items-center">
                                                    <img
                                                        src="./images/dashboardsectionthree/songicon.webp"
                                                        alt=""
                                                    />
                                                    <h5 className="text-[#136CC5] text-[30px] leading-[36px] max-md:text-[24px] py-3 px-2">
                                                        Data-Driven AI
                                                        Consultancy
                                                    </h5>
                                                </div>
                                                <p className="font-[500] text-[#6B6B6B] leading-[30px]">
                                                    Our AI consultancy services
                                                    are designed to empower your
                                                    traditional business with
                                                    data-driven strategies.
                                                    Transform your operations,
                                                    increase efficiency, and
                                                    make informed decisions with
                                                    our expert guidance.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-white max-w-screen-2xl px-2 mx-auto max-md:mx-2 text-left">
                            <div className="grid grid-cols-[auto_200px] max-md:block items-center py-10">
                                <div>
                                    <h5 className="text-[20px] bg-gradient-to-r from-blue-500 to-purple-600 inline-block text-transparent bg-clip-text">
                                        Develop
                                    </h5>
                                    <p className="text-[48px] max-md:text-[32px] font-[700] text-[#1C2347]">
                                        Need Help With Your Idea? Our Team Can
                                        Refine Your Concept Into A Project.
                                    </p>
                                </div>
                                <div>
                                    <Link to="/contactus">
                                        <div className="border-b-2 border-b-[#1C2347] hover:scale-[1.05] transition duration-300 ease-in-out flex justify-between items-center text-[#1C2347] cursor-pointer">
                                            Contact Us
                                            <i className="fa fa-long-arrow-right"></i>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="">
                                <div className="relative h-[600px] w-fill max-lg:h-auto max-lg:w-auto">
                                    <video
                                        autoPlay
                                        muted
                                        loop
                                        playsInline
                                        className="top-0 left-0 w-full object-fill h-full rounded-t-xl"
                                    >
                                        <source
                                            src="./images/dashboardsectionthree/concept.webm"
                                            type="video/webm"
                                        />
                                        Your browser does not support the video
                                        tag.
                                    </video>
                                </div>
                                <div className="py-20 max-md:py-10 bg-[#136CC50F] flex max-md:block px-10 max-md:px-2 rounded-b-xl">
                                    <div className="border-r-[1px] border-r-[#6B6B6B] max-md:border-r-0 max-md:border-b-[1px] max-md:border-b-[#6B6B6B] max-md:py-2 mx-2 px-2">
                                        <h5 className="text-[22px] leading-[36px] text-[#1C2347] pb-2 pr-20">
                                            Tailored to Your Imagination
                                        </h5>{" "}
                                        <p className="text-[#6B6B6B] leadin-[30px] font-[500]">
                                            Whatever your vision, our team is
                                            ready to bring it to life with
                                            custom AR and VR solutions.
                                        </p>
                                    </div>
                                    <div className="border-r-[1px] border-r-[#6B6B6B] max-md:border-r-0 max-md:border-b-[1px] max-md:border-b-[#6B6B6B] max-md:py-2 mx-2 px-2">
                                        <h5 className="text-[22px] leading-[36px] text-[#1C2347] pb-2">
                                            Innovative Solutions for Diverse
                                            Industries
                                        </h5>{" "}
                                        <p className="text-[#6B6B6B] leadin-[30px] font-[500]">
                                            We build interactive software that
                                            captures your idea and turns it into
                                            a virtual masterpiece.
                                        </p>
                                    </div>
                                    <div className="px-2">
                                        <h5 className="text-[22px] leading-[36px] text-[#1C2347] pb-2 max-md:py-2">
                                            Interactive VR Software Development
                                        </h5>{" "}
                                        <p className="text-[#6B6B6B] leadin-[30px] font-[500]">
                                            We build interactive software that
                                            captures your idea and turns it into
                                            a virtual masterpiece.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            {
                //section four begins
                <>
                    <div className="bg-white py-24 max-md:py-10 font-['roboto']">
                        <div className="max-w-screen-2xl px-2 mx-auto">
                            <h5 className="font-[600] text-[48px] leading-[57px] text-[#1C2347] text-center">
                                Experience Innovation Across Industries
                            </h5>

                            <div className="text-white text-left pb-24 max-md:pb-10">
                                <Carousel
                                    containerClass="carousel-container"
                                    responsive={data.responsiveSection3}
                                    partialVisible={true}
                                    swipeable={true}
                                    draggable={true}
                                    showDots={true}
                                    ssr={true}
                                    infinite={true}
                                    autoPlay={true}
                                    autoPlaySpeed={2000}
                                    keyBoardControl={true}
                                    removeArrowOnDeviceType={[
                                        "tablet",
                                        "mobile",
                                    ]}
                                >
                                    <div className="max-h-[350px] my-10 max-md:my-8 hover:scale-[1.05] mx-2 px-4 max-[680px]:mx-36 max-[570px]:mx-24 max-[500px]:mx-16 max-[400px]:mx-0 max-[400px]:ml-10 max-[370px]:ml-5 max-[350px]:ml-2 group hover:border-2 hover:border-[#02F3FE] hover:bg-gradient-to-r from-blue-500 to-purple-600 transition duration-300 ease-in-out shadow-2xl h-[350px] bg-[#ffffff] w-[300px] rounded-xl">
                                        <img
                                            src="./images/dashboardsectionfour/one.webp"
                                            alt=""
                                            className="w-[100px] py-5"
                                        />{" "}
                                        <h5 className="font-[600] text-[22px] leading-[30px] text-[#1C2347] group-hover:text-white">
                                            Finance
                                        </h5>
                                        <p className="py-3 text-[#6B6B6B] group-hover:text-white font-[400] text-[16px] leading-[25px]">
                                            Invincible Meta VR technology is
                                            redefining how customers interact
                                            with banking services.
                                        </p>
                                        <a href="/industries#Finance">
                                            <button className="border-2 border-[#1C2347] text-[#1C2347] group-hover:text-white group-hover:border-2 group-hover:border-white px-10 py-3 font-[600] hover:scale-[1.05] transition duration-300 ease-in-out hover:bg-gradient-to-l rounded-md mr-4 hover:Experience Innovation from-blue-500 to-purple-600">
                                                Explore
                                            </button>
                                        </a>
                                    </div>
                                    <div className="max-h-[350px] my-10 max-md:my-8 hover:scale-[1.05] mx-2 px-4 max-[680px]:mx-36 max-[570px]:mx-24 max-[500px]:mx-16 max-[400px]:mx-0 max-[400px]:ml-10 max-[370px]:ml-5 max-[350px]:ml-2 group hover:border-2 hover:border-[#02F3FE] hover:bg-gradient-to-r from-blue-500 to-purple-600 transition duration-300 ease-in-out shadow-2xl h-[350px] bg-[#ffffff] w-[300px] rounded-xl">
                                        <img
                                            src="./images/dashboardsectionfour/two.webp"
                                            alt=""
                                            className="w-[100px] py-5"
                                        />{" "}
                                        <h5 className="font-[600] text-[22px] leading-[30px] text-[#1C2347] group-hover:text-white">
                                            Automotive
                                        </h5>
                                        <p className="py-3 text-[#6B6B6B] font-[400] text-[16px] leading-[25px] group-hover:text-white">
                                            A game-changing experience.
                                            Customers can explore, customize
                                            cars in a virtual showroom.
                                        </p>
                                        <a href="/industries#Automobile">
                                            <button className="border-2 border-[#1C2347] text-[#1C2347] group-hover:text-white px-10 py-3 font-[600] group-hover:border-2 group-hover:border-white hover:scale-[1.05] transition duration-300 ease-in-out hover:bg-gradient-to-l rounded-md mr-4 hover:Experience Innovation from-blue-500 to-purple-600">
                                                Explore
                                            </button>
                                        </a>
                                    </div>
                                    <div className="max-h-[350px] my-10 max-md:my-8 hover:scale-[1.05] mx-2 px-4 max-[680px]:mx-36 max-[570px]:mx-24 max-[500px]:mx-16 max-[400px]:mx-0 max-[400px]:ml-10 max-[370px]:ml-5 max-[350px]:ml-2 group hover:border-2 hover:border-[#02F3FE] hover:bg-gradient-to-r from-blue-500 to-purple-600 transition duration-300 ease-in-out shadow-2xl h-[350px] bg-[#ffffff] w-[300px] rounded-xl">
                                        <img
                                            src="./images/dashboardsectionfour/three.webp"
                                            alt=""
                                            className="w-[100px] py-5"
                                        />{" "}
                                        <h5 className="font-[600] text-[22px] leading-[30px] text-[#1C2347] group-hover:text-white">
                                            Retail
                                        </h5>
                                        <p className="py-3 text-[#6B6B6B] font-[400] text-[16px] leading-[25px] group-hover:text-white">
                                            Customers can virtually try on
                                            clothing, explore stores, or even
                                            design their own products in 3D.
                                        </p>
                                        <a href="/industries#Retail">
                                            <button className="border-2 border-[#1C2347] text-[#1C2347] group-hover:text-white px-10 py-3 font-[600] group-hover:border-2 group-hover:border-white hover:scale-[1.05] transition duration-300 ease-in-out hover:bg-gradient-to-l rounded-md mr-4 hover:Experience Innovation from-blue-500 to-purple-600">
                                                Explore
                                            </button>
                                        </a>
                                    </div>
                                    <div className="max-h-[350px] my-10 max-md:my-8 hover:scale-[1.05] mx-2 px-4 max-[680px]:mx-36 max-[570px]:mx-24 max-[500px]:mx-16 max-[400px]:mx-0 max-[400px]:ml-10 max-[370px]:ml-5 max-[350px]:ml-2 group hover:border-2 hover:border-[#02F3FE] hover:bg-gradient-to-r from-blue-500 to-purple-600 transition duration-300 ease-in-out shadow-2xl h-[350px] bg-[#ffffff] w-[300px] rounded-xl">
                                        <img
                                            src="./images/dashboardsectionfour/four.webp"
                                            alt=""
                                            className="w-[100px] py-5"
                                        />{" "}
                                        <h5 className="font-[600] text-[22px] leading-[30px] text-[#1C2347] group-hover:text-white">
                                            Education
                                        </h5>
                                        <p className="py-3 text-[#6B6B6B] font-[400] text-[16px] leading-[25px] group-hover:text-white">
                                            Our immersive virtual classrooms
                                            bring subjects to life, offering
                                            interactive and engaging experiences
                                        </p>
                                        <a href="/industries#Education">
                                            <button className="border-2 border-[#1C2347] text-[#1C2347] group-hover:text-white px-10 py-3 font-[600] group-hover:border-2 group-hover:border-white hover:scale-[1.05] transition duration-300 ease-in-out hover:bg-gradient-to-l rounded-md mr-4 hover:Experience Innovation from-blue-500 to-purple-600">
                                                Explore
                                            </button>
                                        </a>
                                    </div>
                                </Carousel>
                            </div>
                        </div>
                        <div className="bg-gradient-to-r truncate from-[#489BF6] via-[#5421B3] via-40% to-[#D36500] my-16 text-center">
                            <div className="max-w-screen-2xl px-2 mx-auto max-md:mx-2 flex items-center justify-center animate-line relative z-10 whitespace-nowrap reduced-motion:animate-none reverse">
                                <h5 className="text-white font-[600] inline-block flex items-center justify-center px-10 text-[48px] py-10 max-md:py-2 leading-[35px] max-md:text-[30px] max-md:px-2">
                                    <i className="fa fa-circle text-white text-[15px] px-5"></i>{" "}
                                    Explore, Engage & Transform with Invincible
                                    meta{" "}
                                    <i className="fa fa-long-arrow-right text-white text-[30px] px-5"></i>
                                </h5>
                                <h5 className="text-white font-[600] inline-block flex items-center justify-center px-10 text-[48px] py-10 max-md:py-2 leading-[35px] max-md:text-[30px] max-md:px-2">
                                    <i className="fa fa-circle text-white text-[15px] px-5"></i>{" "}
                                    Explore, Engage & Transform with Invincible
                                    meta{" "}
                                    <i className="fa fa-long-arrow-right text-white text-[30px] px-5"></i>
                                </h5>
                                <h5 className="text-white font-[600] inline-block flex items-center justify-center px-10 text-[48px] py-10 max-md:py-2 leading-[35px] max-md:text-[30px] max-md:px-2">
                                    <i className="fa fa-circle text-white text-[15px] px-5"></i>{" "}
                                    Explore, Engage & Transform with Invincible
                                    meta{" "}
                                    <i className="fa fa-long-arrow-right text-white text-[30px] px-5"></i>
                                </h5>
                                <h5 className="text-white font-[600] inline-block flex items-center justify-center px-10 text-[48px] py-10 max-md:py-2 leading-[35px] max-md:text-[30px] max-md:px-2">
                                    <i className="fa fa-circle text-white text-[15px] px-5"></i>{" "}
                                    Explore, Engage & Transform with Invincible
                                    meta{" "}
                                    <i className="fa fa-long-arrow-right text-white text-[30px] px-5"></i>
                                </h5>
                                <h5 className="text-white font-[600] inline-block flex items-center justify-center px-10 text-[48px] py-10 max-md:py-2 leading-[35px] max-md:text-[30px] max-md:px-2">
                                    <i className="fa fa-circle text-white text-[15px] px-5"></i>{" "}
                                    Explore, Engage & Transform with Invincible
                                    meta{" "}
                                    <i className="fa fa-long-arrow-right text-white text-[30px] px-5"></i>
                                </h5>
                                <h5 className="text-white font-[600] inline-block flex items-center justify-center px-10 text-[48px] py-10 max-md:py-2 leading-[35px] max-md:text-[30px] max-md:px-2">
                                    <i className="fa fa-circle text-white text-[15px] px-5"></i>{" "}
                                    Explore, Engage & Transform with Invincible
                                    meta{" "}
                                    <i className="fa fa-long-arrow-right text-white text-[30px] px-5"></i>
                                </h5>
                                <h5 className="text-white font-[600] inline-block flex items-center justify-center px-10 text-[48px] py-10 max-md:py-2 leading-[35px] max-md:text-[30px] max-md:px-2">
                                    <i className="fa fa-circle text-white text-[15px] px-5"></i>{" "}
                                    Explore, Engage & Transform with Invincible
                                    meta{" "}
                                    <i className="fa fa-long-arrow-right text-white text-[30px] px-5"></i>
                                </h5>
                                <h5 className="text-white font-[600] inline-block flex items-center justify-center px-10 text-[48px] py-10 max-md:py-2 leading-[35px] max-md:text-[30px] max-md:px-2">
                                    <i className="fa fa-circle text-white text-[15px] px-5"></i>{" "}
                                    Explore, Engage & Transform with Invincible
                                    meta{" "}
                                    <i className="fa fa-long-arrow-right text-white text-[30px] px-5"></i>
                                </h5>
                                <h5 className="text-white font-[600] inline-block flex items-center justify-center px-10 text-[48px] py-10 max-md:py-2 leading-[35px] max-md:text-[30px] max-md:px-2">
                                    <i className="fa fa-circle text-white text-[15px] px-5"></i>{" "}
                                    Explore, Engage & Transform with Invincible
                                    meta{" "}
                                    <i className="fa fa-long-arrow-right text-white text-[30px] px-5"></i>
                                </h5>
                                <h5 className="text-white font-[600] inline-block flex items-center justify-center px-10 text-[48px] py-10 max-md:py-2 leading-[35px] max-md:text-[30px] max-md:px-2">
                                    <i className="fa fa-circle text-white text-[15px] px-5"></i>{" "}
                                    Explore, Engage & Transform with Invincible
                                    meta{" "}
                                    <i className="fa fa-long-arrow-right text-white text-[30px] px-5"></i>
                                </h5>
                            </div>
                        </div>
                        <div className="max-w-screen-2xl px-2 mx-auto max-md:mx-2 py-10">
                            <h5 className="text-[70px] max-md:text-[35px] max-md:leading-[50px] font-[900] leading-[94px] text-center py-10 max-md:py-0">
                                Dive into a World Beyond Imagination with{" "}
                                <span className="text-transparent inline-block bg-clip-text bg-gradient-to-r from-blue-500 to-purple-600">
                                    InvincibleMeta.AI
                                </span>
                            </h5>
                            <div className="flex justify-between py-10 max-md:grid max-md:grid-cols-1">
                                <div className="max-h-[350px] min-w-[300px] px-4 pt-56 mr-3 h-[450px] max-xl:max-w-[600px] w-[750px] rounded-xl bg-cover max-lg:max-w-[400px] max-md:max-w-full max-md:my-4 relative h-auto">
                                    <video
                                        autoPlay
                                        muted
                                        loop
                                        playsInline
                                        className="absolute top-0 left-0 w-full rounded-xl h-full object-cover"
                                    >
                                        <source
                                            src="./images/dashboardsectionfour/vrzonebackdrop.webm"
                                            type="video/webm"
                                        />
                                        Your browser does not support the video
                                        tag.
                                    </video>
                                    <div className="absolute inset-0 rounded-xl bg-black opacity-10"></div>
                                    <h5 className="font-[900] relative z-10 text-white text-left h-full flex items-end py-3 text-[48px] max-md:text-[36px] leading-[64px]">
                                        VR Zone
                                    </h5>
                                </div>
                                <div className="max-h-[350px] min-w-[300px] pt-56 relative h-auto px-4 h-[450px] max-xl:max-w-[600px] bg-cover w-[750px] rounded-xl max-lg:max-w-[400px] max-md:max-w-full max-md:my-4">
                                    <video
                                        autoPlay
                                        muted
                                        loop
                                        playsInline
                                        className="absolute top-0 left-0 w-full rounded-xl h-full object-cover"
                                    >
                                        <source
                                            src="./images/dashboardsectionfour/vrmeetingbackdrop.webm"
                                            type="video/webm"
                                        />
                                        Your browser does not support the video
                                        tag.
                                    </video>
                                    <div className="absolute inset-0 rounded-xl bg-black opacity-10"></div>
                                    <h5 className="relative z-10 font-[900] text-white text-left h-full flex items-end py-3 text-[48px] max-md:text-[36px] leading-[64px]">
                                        VR Meeting Rooms
                                    </h5>
                                </div>
                            </div>
                            <div className="text-center">
                                <div
                                    className="bg-cover relative h-auto text-white rounded-xl"
                                    style={{
                                        backgroundImage: `url('./images/randomtoberemoved/section4card3.png')`,
                                    }}
                                >
                                    <video
                                        autoPlay
                                        muted
                                        loop
                                        playsInline
                                        className="absolute top-0 left-0 w-full rounded-xl h-full object-cover"
                                    >
                                        <source
                                            src="./images/dashboardsectionfour/explorebackdrop.webm"
                                            type="video/webm"
                                        />
                                        Your browser does not support the video
                                        tag.
                                    </video>
                                    <div className="absolute inset-0 rounded-xl bg-black opacity-40"></div>
                                    <div className="max-w-screen-2xl rounded-xl relative z-10 max-md:px-2 mx-auto max-md:mx-2 px-24">
                                        <h3 className="font-[900] font-roboto text-[54px] max-md:text-[48px] leading-[64px] pt-20 max-md:pt-10">
                                            Explore our “Eternity City”
                                        </h3>
                                        <p className="font-[400] text-[18px] leading-[30px] py-10 px-10 max-md:px-2 max-md:py-3">
                                            Don't miss out on the Metaverse
                                            revolution. Let InvincibleMeta.ai be
                                            your guide to success in this
                                            exciting virtual world. The
                                            Metaverse is not a distant future;
                                            it's here, and it's evolving
                                            rapidly. Start your Metaverse
                                            journey with us today and discover
                                            how the virtual world can transform
                                            your business.
                                        </p>

                                        <div className="pb-16">
                                            <button
                                                onClick={props.demoController}
                                                className="border-2 bg-[#FFF8F840] text-white font-[700] transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-[#FFF8F860] px-10 py-3 rounded-md"
                                            >
                                                Explore
                                                <i className="fa fa-long-arrow-right ml-24 text-[20px]"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            {
                //section five begins
                <>
                    <div className="bg-[#000000] font-['roboto']">
                        <div className="max-md:mx-2 max-w-screen-2xl px-2 mx-auto">
                            <div className="grid grid-cols-2 max-md:grid-cols-1 py-24 max-md:py-10">
                                <div className="px-28 max-md:px-10">
                                    <img
                                        src="./images/dashboardsectionfive/technologylogos.webp"
                                        alt=""
                                    />
                                </div>
                                <div className="px-10 max-md:2 max-lg:px-4 max-md:px-0">
                                    <h5 className="text-[#FFFFFF] text-left text-[48px] max-md:text-[40px] leading-[53px] font-[600] py-8">
                                        Tools Used in VR Development
                                    </h5>
                                    <p className="font-[400] text-[18px] leading-[30px] text-[#FFFFFF] pb-8 text-left">
                                        In the Realm of VR development, Several
                                        Key Tools and Technologies Play A
                                        Crucial Role In Creating Immersive and
                                        Interactive Virtual Environments:
                                    </p>
                                    <div className="flex py-8 max-md:py-2 items-center">
                                        <p className="border-l-[1px] max-md:border-l-0 max-md:leading-[20px] max-md:border-r-[1px] max-md:pr-2 mr-5 max-lg:mr-2 border-l-[#FFFFFF] pl-6 max-lg:pl-2 text-[#FFFFFF] font-[400] text-[18px] max-md:text-[14px] leading-[30px]">
                                            React
                                        </p>
                                        <p className="border-l-[1px] max-md:border-l-0 max-md:leading-[20px] max-md:border-r-[1px] max-md:pr-2 mr-5 max-lg:mr-2 border-l-[#FFFFFF] pl-6 max-lg:pl-2 text-[#FFFFFF] font-[400] text-[18px] max-md:text-[14px] leading-[30px]">
                                            3ds Max
                                        </p>
                                        <p className="border-l-[1px] max-md:border-l-0 max-md:leading-[20px] max-md:border-r-[1px] max-md:pr-2 mr-5 max-lg:mr-2 border-l-[#FFFFFF] pl-6 max-lg:pl-2 text-[#FFFFFF] font-[400] text-[18px] max-md:text-[14px] leading-[30px]">
                                            Blender
                                        </p>
                                        <p className="border-l-[1px] max-md:border-l-0 max-md:leading-[20px] max-md:border-r-[1px] max-md:pr-2 mr-5 max-lg:mr-2 border-l-[#FFFFFF] pl-6 max-lg:pl-2 text-[#FFFFFF] font-[400] text-[18px] max-md:text-[14px] leading-[30px]">
                                            Unity
                                        </p>
                                        <p className="border-l-[1px] max-md:border-l-0 max-md:leading-[20px] mr-5 max-lg:mr-2 border-l-[#FFFFFF] pl-6 max-lg:pl-2 text-[#FFFFFF] font-[400] text-[18px] max-md:text-[14px] leading-[30px]">
                                            Unreal engine
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="bg-cover font-['roboto'] h-screen max-md:h-auto"
                        style={{
                            backgroundImage: `url('./images/dashboardsectionfive/backdrop.webp')`,
                        }}
                    >
                        <div className="max-md:mx-2 max-w-screen-2xl px-2 mx-auto py-20 max-md:py-5">
                            <div className="grid grid-cols-2 max-md:block items-center text-left">
                                <div className="py-20 max-md:py-5">
                                    <h5 className="text-white font-[600] text-[48px] max-md:text-[35px] max-md:leading-[45px] leading-[57px] pb-10 pr-24 max-md:pr-2 max-md:pb-2">
                                        Have a Project or Ready to Experience
                                        the Future?
                                    </h5>
                                    <p className="text-white font-[400] text-[18px] leading-[30px] pr-24 max-md:pr-3">
                                        Embark on a journey into the
                                        extraordinary with Invincible Meta.
                                        Discover how our virtual world can
                                        transform your reality. Book a demo call
                                        today and take the first step into a new
                                        realm of possibilities.
                                    </p>
                                </div>
                                <div className="w-full">
                                    <div className="py-20 flex justify-center max-md:block max-md:px-0 max-md:py-5 max-xl:px-5 max-md:px-1 max-xl:py-5">
                                        <div className="border-[1px] p-4 max-md:p-2">
                                            <div className="border shadow-2xl bg-white">
                                                <div className="pt-10">
                                                    <h5 className="text-[#1C2347] text-[30px] leading-[30px] text-left px-5 max-md:px-2 pb-5">
                                                        Send us your query
                                                    </h5>
                                                </div>
                                                <form className="">
                                                    <div className="flex max-md:block">
                                                        <div className="w-[500px] max-[1080px]:w-full px-5 max-lg:px-2">
                                                            <label className="text-xs font-medium text-[#1C2347] text-[14px] leading-[16px] font-[500]">
                                                                Name
                                                                <span className="text-xs text-pink">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                className="w-full text-grey bg-[#E7E7E7DB] text-sm font-normal border border-grey-500 rounded-md py-3 px-4 max-md:px-2 focus:outline-none"
                                                                type="text"
                                                                placeholder="Enter name"
                                                                onChange={
                                                                    props.handleDemoFormChange
                                                                }
                                                                name="name"
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        <div className="w-full px-5 max-lg:px-2">
                                                            <label
                                                                className="text-xs font-medium text-[#1C2347] text-[14px] leading-[16px] font-[500]"
                                                                htmlFor="grid-email"
                                                            >
                                                                Phone No.
                                                                <span className="text-xs text-pink">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                className="w-full bg-[#E7E7E7DB] text-grey border text-sm font-normal border-grey-500 rounded-md py-3 px-4 max-md:px-2 focus:outline-none"
                                                                id="grid-phone"
                                                                type="text"
                                                                placeholder="Enter Phone No."
                                                                onChange={
                                                                    props.handleDemoFormChange
                                                                }
                                                                name="phone-number"
                                                                required
                                                            />
                                                        </div>
                                                        <div className="w-full px-5 max-lg:px-2">
                                                            <label className="text-xs font-medium text-[#1C2347] text-[14px] leading-[16px] font-[500]">
                                                                Company Name
                                                                <span className="text-xs text-pink">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                className="w-full text-grey bg-[#E7E7E7DB] text-sm font-normal border border-grey-500 rounded-md py-3 px-4 max-md:px-2 focus:outline-none"
                                                                type="text"
                                                                placeholder="Enter Comapany Name"
                                                                onChange={
                                                                    props.handleDemoFormChange
                                                                }
                                                                name="company-name"
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        <div className="px-5 max-md:px-2">
                                                            <label className="text-xs font-medium text-[#1C2347] text-[14px] leading-[16px] font-[500]">
                                                                Business Email
                                                                <span className="text-xs text-pink">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                className="w-full text-grey bg-[#E7E7E7DB] text-sm font-normal border border-grey-500 rounded-md py-3 px-4 max-md:px-2 focus:outline-none"
                                                                type="text"
                                                                placeholder="Enter Buisness Email"
                                                                onChange={
                                                                    props.handleDemoFormChange
                                                                }
                                                                name="business-email"
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="px-5 max-md:px-2">
                                                        <label className="text-xs font-medium text-[#1C2347] text-[14px] leading-[16px] font-[500]">
                                                            Message
                                                            <span className="text-xs text-pink">
                                                                *
                                                            </span>
                                                        </label>
                                                        <textarea
                                                            className="w-full py-3 px-4 max-md:px-2 border bg-[#E7E7E7DB] border-grey-500 rounded-md h-[100px] max-md:h-[50px] text-sm focus:outline-none"
                                                            placeholder="Enter message here"
                                                            required
                                                            onChange={
                                                                props.handleDemoFormChange
                                                            }
                                                            name="message"
                                                        />
                                                    </div>
                                                    <div className="px-4 w-full">
                                                        <button
                                                            onClick={
                                                                props.handleDemoFormSubmit
                                                            }
                                                            className="bg-blue-100 w-full font-[700] py-3 my-5 text-white transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-gradient-to-l rounded-md mr-4 bg-gradient-to-r from-blue-500 to-purple-600"
                                                        >
                                                            Submit request
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gradient-to-b from-blue-500 to-purple-600 font-['roboto']">
                        <div className="max-md:mx-2 max-w-screen-2xl px-2 mx-auto">
                            <div className="text-center">
                                <div className="text-white">
                                    <div className="max-w-screen-2xl px-2 mx-auto max-md:mx-2">
                                        <h3 className="font-[600] text-[48px] leading-[56px] pt-36 max-md:pt-16">
                                            Join Us on This Incredible Journey
                                        </h3>
                                        <p className="font-[400] text-[18px] leading-[30px] py-10 px-20 max-md:px-2">
                                            Looking to explore the virtual realm
                                            or bring your own project to life?
                                            We're here to make it happen. Reach
                                            out to us for more information, a
                                            demo, or to discuss your project.
                                            Let's create something amazing
                                            together.
                                        </p>

                                        <div className="py-16 max-md:pb-10 max-md:py-0">
                                            <button
                                                onClick={props.demoController}
                                                className="text-white px-10 py-3 font-[600] hover:scale-[1.10] transition duration-300 ease-in-out rounded-md mr-4 bg-[#FFFFFF54]"
                                            >
                                                Get Started
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}
