import { useEffect, useState } from "react";
import { IVrBankContainerProps } from "./VrBank.interfaces";
import VrBankView from "./VrBank.view";
import BroswerService from "../../Services/Browser.service";

export default function VrBankContainer(props: IVrBankContainerProps) {
    useEffect(() => {
        BroswerService.setCustomTitle("VrBankContainer");
    }, []);
    const [showDemo, setShowDemo] = useState(false);
    const demoController = () => setShowDemo(!showDemo);
    const [showlogin, setShowlogin] = useState(false);
    const loginController = () => setShowlogin(!showlogin);
    return (
        <>
            <VrBankView
                demoController={demoController}
                showDemo={showDemo}
                loginController={loginController}
                showlogin={showlogin}
            />
        </>
    );
}
