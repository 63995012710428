import Faq from "../FaqComponent";
import Navigation from "../Navigation";
import { IContactusViewProps } from "./Contactus.interfaces";
import BookDemo from "../BookDemo";
export default function ContactusView(props: IContactusViewProps) {
    return (
        <>
            {props.showDemo === true && (
                <BookDemo demoController={props.demoController} />
            )}
            <div className="font-['roboto'] text-left">
                <div>
                    <div
                        className="text-white bg-cover"
                        style={{
                            backgroundImage: `url('./images/contactus/backdrop.webp')`,
                        }}
                    >
                        <div className="bg-black/[0.50]">
                            <Navigation />
                            <div className="px-10 py-72 max-md:py-20">
                                <div className="max-w-screen-2xl mx-auto max-md:px-2">
                                    <div></div>
                                    <div className="text-center">
                                        <div>
                                            <h3 className="text-white font-['roboto'] text-[58px] leading-[68px] max-sm:text-4xl font-bold my-5">
                                                Contact us & Unleash your idea
                                            </h3>
                                        </div>
                                        <p className="pb-10 font-['roboto'] text-lg font-medium">
                                            Let us know how we can assist you.
                                        </p>
                                        <button
                                            onClick={props.demoController}
                                            className="mt-10 bg-blue-100 font-[700] px-10 py-3 text-white transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-gradient-to-l rounded-md mr-4 bg-gradient-to-r from-blue-500 to-purple-600"
                                        >
                                            Contact Us
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="font-['roboto'] items-center max-w-screen-2xl mx-auto px-2 max-md:px-2">
                        <div className="flex py-10">
                            <div className="px-6 max-md:px-0">
                                <h3 className="mt-10 text-darkblue font-bold text-3xl">
                                    What will be your next step?
                                </h3>
                                <p className="my-2.5 text-lg text-grey">
                                    You are one step closer to buy or explore
                                    Virtual space in Eternity city
                                </p>
                            </div>
                            <div className="flex my-10 px-6 max-md:px-0">
                                <div className="border-r border-grey-500">
                                    <p className="font-semibold mb-3 text-darkblue text-xl">
                                        Get own space in city ?
                                    </p>
                                    <p className="text-grey">
                                        Get your virtual bank , showroom,
                                        retails store or any other things, just
                                        contact us
                                    </p>
                                </div>
                                <div className="ml-5">
                                    <p className="font-semibold mb-3 text-darkblue text-xl">
                                        Explore Eternity
                                    </p>
                                    <p className="text-grey">
                                        Explore city virtually with VR glasses,
                                        see high rated brands products while
                                        sitting at home
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-100">
                        <div className="max-w-screen-2xl mx-auto max-md:px-2 flex max-sm:block max-md:px-2 justify-around py-14">
                            <div className="mr-16">
                                <h3 className="text-[58px] leading-[68px] text-darkblue font-bold">
                                    Quick
                                </h3>
                                <h3 className="text-[58px] leading-[68px] text-darkblue font-bold my-4">
                                    support
                                </h3>
                                <p className="font-['roboto'] twxt-lg font-medium text-gray-600">
                                    You can get all the contact information
                                </p>
                            </div>
                            <div className="max-md:my-2">
                                <i className="fa fa-map-marker text-[#136CC5] text-[40px]"></i>
                                <p className="font-['roboto'] text-xl font-bold text-darkblue my-4">
                                    Visit us
                                </p>
                                <p className="font-['roboto'] text-base font-medium text-grey">
                                    201A SAS Tower Sector
                                </p>
                                <p className="font-['roboto'] text-base font-medium text-grey">
                                    38 Gurgaon-122003
                                </p>
                            </div>
                            <div className="max-md:my-2">
                                <i className="fa fa-phone text-[#136CC5] text-[40px]"></i>
                                <p className="font-['roboto'] text-xl font-bold text-darkblue my-4">
                                    Call us
                                </p>
                                <p className="font-['roboto'] text-base font-medium text-grey">
                                    +91-9599066061,
                                </p>
                                <p className="font-['roboto'] text-base font-medium text-grey">
                                    01244034247
                                </p>
                            </div>
                            <div className="max-md:my-2">
                                <i className="fa fa-envelope text-[#136CC5] text-[40px]"></i>
                                <p className="font-['roboto'] text-xl font-bold text-darkblue my-4">
                                    Email us
                                </p>
                                <p className="font-['roboto'] text-base font-medium text-grey">
                                    Contact@invincibleocean.com
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="max-w-screen-2xl mx-auto max-md:px-2">
                        <Faq category="Frequently Asked Questions" />
                    </div>
                    <div className="max-w-screen-2xl mx-auto max-md:px-2 py-10">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.277203725602!2d77.03694517619266!3d28.44105899274859!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19699f6e49bd%3A0x3c01b6ad67606134!2sInvincible%20Ocean%20%7C%20Emptra%3A%20API%20Solution%20%7C%20AI%20Driven%20Fraud%20Detection%20%26%20Risk%20Profiling!5e0!3m2!1sen!2sin!4v1694521935338!5m2!1sen!2sin"
                            width="100%"
                            height="650"
                            title="invincible"
                            style={{ border: "0" }}
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                </div>
            </div>
        </>
    );
}
