import { useEffect, useState, useContext } from "react";
import {
    IBookDemoForm,
    ILandingPageContainerProps,
} from "./LandingPage.interfaces";
import LandingPageView from "./LandingPage.view";
import BroswerService from "../../Services/Browser.service";
import { Auth } from "../../providers";
import { MetaverseUserService } from "../../Services";
export default function LandingPageContainer(
    props: ILandingPageContainerProps
) {
    const [showlogin, setShowlogin] = useState(false);
    const loginController = () => setShowlogin(!showlogin);
    const [showDemo, setShowDemo] = useState(false);
    const demoController = () => setShowDemo(!showDemo);
    const user = useContext(Auth.Context) ?? null;
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const countdownDate = new Date("2024-03-03T00:00:00").getTime();
    const countdown = setInterval(function () {
        const now = new Date().getTime();
        const distance = countdownDate - now;
        setDays(Math.floor(distance / (1000 * 60 * 60 * 24)));
        setHours(
            Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        );
        setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
        setSeconds(Math.floor((distance % (1000 * 60)) / 1000));
        if (distance < 0) {
            clearInterval(countdown);
        }
    }, 1000);
    const [demoForm, setDemoForm] = useState<IBookDemoForm>({
        businessEmail: "",
        companyName: "",
        message: "",
        name: "",
        phoneNumber: "",
    });
    const handleDemoFormChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setDemoForm({
            ...demoForm,
            [event.target.name.replace(/-([a-z])/g, (_, letter) =>
                letter.toUpperCase()
            )]: event.target.value,
        });
    };
    const handleDemoFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        bookDemo(demoForm);
    };
    const bookDemo = async (demoForm: any) => {
        const data = await MetaverseUserService.bookDemo(
            demoForm.businessEmail,
            demoForm.companyName,
            demoForm.message,
            demoForm.name,
            demoForm.phoneNumber
        );
        if (data && data.message === "Demo booked") {
            setShowMessage(true);
            setMessageClass("success");
            setMessageText("Demo booked successfully");
            setTimeout(function () {
                window.location.reload();
            }, 5000);
        } else {
            setShowMessage(true);
            setMessageClass("error");
            setMessageText(data.response.data.errors.details[0].message);
        }
    };
    const [messageText, setMessageText] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const messageController = () => setShowMessage(!showMessage);
    useEffect(() => {
        BroswerService.setCustomTitle("LandingPageContainer");
    }, []);
    return (
        <>
            <LandingPageView
                demoController={demoController}
                showDemo={showDemo}
                days={days}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
                loginController={loginController}
                showlogin={showlogin}
                user={user}
                handleDemoFormChange={handleDemoFormChange}
                handleDemoFormSubmit={handleDemoFormSubmit}
                messageController={messageController}
                messageText={messageText}
                messageClass={messageClass}
                showMessage={showMessage}
            />
        </>
    );
}
