import { IDashboardContainerProps } from "./Dashboard.interfaces";
import DashboardView from "./Dashboard.view";

export default function DashboardContainer(props: IDashboardContainerProps) {
    return (
        <>
            <DashboardView />
        </>
    );
}
