import { useState } from "react";

import { ISuperAdminLoginContainerProps } from "./SuperAdminLogin.interfaces";
import SuperAdminLoginBoxView from "./SuperAdminLogin.view";
import { SuperAdminService, SessionService } from "../../Services";

export default function SuperAdminLoginContainer(
    props: ISuperAdminLoginContainerProps
) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleLogin = async (e: any) => {
        if (e.key && e.key !== "Enter") return;
        const data = await SuperAdminService.login(email, password);
        if (data && data.data) {
            SessionService.set("user-type", "super-admin");
            SessionService.set("user", JSON.stringify(data.data.superAdmin));
            SessionService.set("accessToken", data.data.accessToken);
            SessionService.set("refreshToken", data.data.refreshToken);
            setShowMessage(true);
            setMessageClass("Success");
            setMessageText("SuperAdmin logged in successfully");
            setTimeout(function () {
                window.location.href = "/super-admin/home";
            }, 1000);
        } else {
            setShowMessage(true);
            setMessageClass("error");
            setMessageText(
                data.response.data.message === "SuperAdmin not found"
                    ? "User not found!"
                    : data.response.data.message === "Unauthorized"
                      ? "Wrong Password!"
                      : data.response.data.errors.details[0].message
            );
            setTimeout(function () {
                setShowMessage(false);
            }, 2000);
        }
    };

    const emailController = (event: React.ChangeEvent<HTMLInputElement>) =>
        setEmail(event.target.value);
    const passwordController = (event: React.ChangeEvent<HTMLInputElement>) =>
        setPassword(event.target.value);
    const [messageText, setMessageText] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const messageController = () => setShowMessage(!showMessage);

    return (
        <>
            <SuperAdminLoginBoxView
                handleLogin={handleLogin}
                emailController={emailController}
                passwordController={passwordController}
                messageController={messageController}
                messageText={messageText}
                messageClass={messageClass}
                showMessage={showMessage}
            />
        </>
    );
}
