import { useState, useEffect } from "react";
import { IFaqPageContainerProps } from "./FaqPage.interfaces";
import FaqPageView from "./FaqPage.view";
import BroswerService from "../../Services/Browser.service";
export default function FaqPageContainer(props: IFaqPageContainerProps) {
    const [selected, setSelected] = useState<number>(1);
    const handleSelected = (i: number) => {
        setSelected(i);
    };
    useEffect(() => {
        BroswerService.setCustomTitle("FaqPageContainer");
    }, []);
    const [showlogin, setShowlogin] = useState(false);
    const loginController = () => setShowlogin(!showlogin);
    return (
        <>
            <FaqPageView
                selected={selected}
                handleSelected={handleSelected}
                loginController={loginController}
                showlogin={showlogin}
            />
        </>
    );
}
