import { IUserLoginContainerProps } from "./UserLoginBox.interfaces";
import UserLoginBoxView from "./UserLoginBox.view";
import { useState } from "react";
import {
    SessionService,
    FirebaseAuthService,
    MetaverseUserService,
} from "../../Services";

export default function UserLoginContainer(props: IUserLoginContainerProps) {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [otp, setOtp] = useState("");
    const [otpStatus, setOtpStatus] = useState(false);
    const [confirmationResult, setConfimationResult] = useState<any>(null);

    const handleEntry = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        setPhoneNumber(e.currentTarget.value);
    };

    const handleOTPEntry = (i: string) => {
        setOtp(i);
    };

    const signinWithGoogle = async () => {
        const user = await FirebaseAuthService.signinWithGoogle();
        if (user && user.user) {
            const data = await MetaverseUserService.login(
                "google",
                await user.user.getIdToken()
            );
            if (data) {
                SessionService.set("user-type", "metaverse-user");
                SessionService.set("user", JSON.stringify(data.user));
                SessionService.set("accessToken", data.accessToken);
                SessionService.set("refreshToken", data.refreshToken);
                setShowMessage(true);
                setMessageClass("success");
                setMessageText("Login successfully");
                setTimeout(function () {
                    window.location.href = props.redirectionURL;
                }, 1000);
            } else {
                setShowMessage(true);
                setMessageClass("error");
                setMessageText("Invalid credentials");
                setTimeout(function () {
                    setShowMessage(false);
                }, 2000);
            }
        } else window.location.reload();
    };
    const signinWithPhoneNumber = async (event: any) => {
        if (event.key && event.key !== "Enter") return;
        event.preventDefault();
        if (phoneNumber.length < 10) {
            setShowMessage(true);
            setMessageClass("error");
            setMessageText(
                "Phone Number Lenth must be at least 10 characters!"
            );
            setTimeout(function () {
                setShowMessage(false);
            }, 2000);
            return;
        } else if (phoneNumber.length > 10) {
            setShowMessage(true);
            setMessageClass("error");
            setMessageText("Phone Number Lenth must be maximum 10 characters!");
            setTimeout(function () {
                setShowMessage(false);
            }, 2000);
            return;
        }
        const _confirmationResult =
            await FirebaseAuthService.signinWithPhoneNumber(
                phoneCode + phoneNumber
            );
        setOtpStatus(
            typeof _confirmationResult === "object" &&
                _confirmationResult !== null
        );
        setConfimationResult(_confirmationResult);
    };
    const otpConfirmation = async (event: React.FormEvent) => {
        event.preventDefault();
        const user =
            await FirebaseAuthService.confirmOTPToSigninWithPhoneNumber(
                confirmationResult,
                otp
            );
        if (user && user.user) {
            const data = await MetaverseUserService.login(
                "phone",
                await user.user.getIdToken()
            );
            if (data) {
                SessionService.set("user-type", "metaverse-user");
                SessionService.set("user", JSON.stringify(data.user));
                SessionService.set("accessToken", data.accessToken);
                SessionService.set("refreshToken", data.refreshToken);
                setShowMessage(true);
                setMessageClass("success");
                setMessageText("Login successfully");
                setTimeout(function () {
                    window.location.href = props.redirectionURL;
                }, 1000);
            } else {
                setShowMessage(true);
                setMessageClass("error");
                setMessageText("Invalid credentials");
                setTimeout(function () {
                    setShowMessage(false);
                }, 2000);
            }
        } else window.location.reload();
    };

    const [messageText, setMessageText] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const messageController = () => setShowMessage(!showMessage);
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = (): void => {
        setIsOpen(!isOpen);
    };
    const [phoneCode, setPhoneCode] = useState("+91");
    const [country, setCountry] = useState("IN");
    const [searchterm, setSearchterm] = useState("");
    const togglePhone = (i: string | undefined): void => {
        if (i !== undefined) {
            setPhoneCode(i);
        }
    };
    const toggleCountry = (i: string | undefined): void => {
        if (i !== undefined) {
            setCountry(i);
        }
    };
    const handleSearchInput = (e: React.FormEvent<HTMLInputElement>): void => {
        setSearchterm(e.currentTarget.value);
    };

    return (
        <>
            <UserLoginBoxView
                otpStatus={otpStatus}
                otp={otp}
                signinWithGoogle={signinWithGoogle}
                signinWithPhoneNumber={signinWithPhoneNumber}
                loginController={props.loginController}
                phoneNumber={phoneNumber}
                handleEntry={handleEntry}
                handleOTPEntry={handleOTPEntry}
                OtpConfirmation={otpConfirmation}
                messageController={messageController}
                messageText={messageText}
                messageClass={messageClass}
                showMessage={showMessage}
                isOpen={isOpen}
                toggleDropdown={toggleDropdown}
                phoneCode={phoneCode}
                togglePhone={togglePhone}
                country={country}
                toggleCountry={toggleCountry}
                searchterm={searchterm}
                handleSearchInput={handleSearchInput}
            />
        </>
    );
}
