import { IFooterContainerProps } from "./Footer.interfaces";
import FooterView from "./Footer.view";
import { useState } from "react";
export default function FooterContainer(props: IFooterContainerProps) {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };
    const [showDemo, setShowDemo] = useState(false);
    const demoController = () => setShowDemo(!showDemo);
    return (
        <>
            <FooterView
                demoController={demoController}
                showDemo={showDemo}
                scrollToTop={scrollToTop}
            />
        </>
    );
}
