// import axios from "axios";
import ApiService from "./API.service";
// import SessionService from "./Session.service";

// const baseUrl = `https://${process.env.REACT_APP_METAVERSE_CDN_URL}`;

// const setHeaders = () => {
//     axios.interceptors.request.use((config: any) => {
//         const innerConfig = config;
//         const token = SessionService.get("accessToken");
//         if (token) {
//             innerConfig.headers.Authorization = `Bearer ${token}`;
//         }
//         return innerConfig;
//     });
// };

const MetaverseCDNService = {
    /**
     * GET /:game/:stage/index.html
     * @access public
     */
    checkGameExists: async (url: string) => {
        try {
            const data = await ApiService.get(url);
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
};

export default MetaverseCDNService;
