import { useEffect, useState } from "react";
import { ISuperAdminSidebarContainerProps } from "./SuperAdminSidebar.interfaces";
import SuperAdminSidebarView from "./SuperAdminSidebar.view";
import { MetaverseService } from "../../Services";

export default function SuperAdminSidebarContainer(
    props: ISuperAdminSidebarContainerProps
) {
    const [btnColor, setBtnColor] = useState("Dashboard");
    const [metaverses, setMetaverses] = useState<any[]>([]);

    const ButtonColor = (i: string) => {
        setBtnColor(i);
    };

    const fetchMetaverses = async () => {
        // Add this to redux, useSelector, if array is empty fetch and store to redux => TP | 2024-01-30 16:53:21
        try {
            const data = await MetaverseService.fetch(); // Add pagination => TP | 2024-01-30 17:12:40
            setMetaverses(data.data.metaverses);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchMetaverses();
    }, []);

    const [activeMetaverse, setActiveMetaverse] = useState(null);

    const handleMetaverseClick = (metaverse: any) => {
        setActiveMetaverse(metaverse);
    };

    return (
        <>
            <SuperAdminSidebarView
                ButtonColor={ButtonColor}
                btnColor={btnColor}
                metaverses={metaverses}
                activeMetaverse={activeMetaverse}
                handleMetaverseClick={handleMetaverseClick}
            />
        </>
    );
}
