import Navigation from "../Navigation";
import { IAboutusViewProps } from "./Aboutus.interfaces";
import UserLoginBox from "../UserLoginBox";
export default function AboutusView(props: IAboutusViewProps) {
    return (
        <>
            {props.showlogin === true && (
                <UserLoginBox
                    redirectionURL="/metaverse/65b741def8309762720ad53f"
                    loginController={props.loginController}
                /> // Since the line 122, says Login to Eternity City, but can be changed later => TP | 2024-01-30 14:39:44
            )}
            <div className="font-['roboto']">
                <section className="">
                    <div
                        className="bg-cover"
                        style={{
                            backgroundImage: `url('./images/aboutus/background.webp')`,
                        }}
                        data-testid="element-with-background-image"
                    >
                        <div className="bg-black/[0.50]">
                            <Navigation />
                            <div className="max-w-screen-2xl mx-auto max-md:px-2 max-md:block text-center px-10 max-md:px-2 text-white">
                                <div></div>
                                <div className="py-56 max-md:py-24">
                                    <h3 className="my-5 text-5xl font-bold">
                                        About Us
                                    </h3>
                                    <p className="font-['roboto'] text-[18px] max-md:text-[14px] font-[500] max-md:pt-5 max-md:font-[400] max-w-screen-2xl mx-auto">
                                        At Invincible ocean, we're on a mission
                                        to redefine the boundaries of reality
                                        through cutting-edge Augmented Reality
                                        (AR) and Virtual Reality (VR)
                                        technologies. We're passionate about
                                        crafting immersive experiences that
                                        bridge the gap between the physical and
                                        digital worlds, allowing individuals and
                                        businesses to explore new dimensions of
                                        creativity and possibility.
                                    </p>
                                </div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                    <div className="max-w-screen-2xl mx-auto max-md:px-2 grid grid-cols-3 max-md:grid-cols-1 my-20 max-md:my-7">
                        <div className="px-7 text-left">
                            <h5 className="text-[#1C2347] text-xl">
                                Innovation
                            </h5>
                            <div className="border-b-2 border-b-[#136CC5] w-7 mb-2"></div>
                            <p className="max-md:mb-4 text-[#777777] text-[18px] font-[400] font-['roboto']">
                                We are driven by innovation, always at the
                                forefront of emerging AR/VR trends and
                                technologies. Our commitment to staying ahead of
                                the curve ensures that you'll experience the
                                latest and greatest in immersive technology.
                            </p>
                        </div>
                        <div className="px-7 text-left">
                            <h5 className="text-[#1C2347] text-xl">
                                User-Centric
                            </h5>
                            <div className="border-b-2 border-b-[#136CC5] w-7 mb-2"></div>
                            <p className="max-md:mb-4 text-[#777777] text-[18px] font-[400] font-['roboto']">
                                Our users are at the heart of everything we do.
                                We design our AR/VR experiences with your needs
                                and preferences in mind, ensuring intuitive
                                interactions and unforgettable journeys.
                            </p>
                        </div>
                        <div className="px-7 text-left">
                            <h5 className="text-[#1C2347] text-xl">Quality</h5>
                            <div className="border-b-2 border-b-[#136CC5] w-7 mb-2"></div>
                            <p className="max-md:mb-4 text-[#777777] text-[18px] font-[400] font-['roboto']">
                                We take pride in delivering high-quality,
                                lifelike experiences. Our team of experts
                                meticulously crafts each aspect of our virtual
                                environments to provide you with the most
                                realistic encounters possible.
                            </p>
                        </div>
                    </div>
                    <div className="py-20 px-10 text-center bg-[#F1F1F1]">
                        <div className="max-w-screen-2xl mx-auto max-md:px-2">
                            <h5 className="pb-8 text-[48px] text-[#1C2347] font-[700]">
                                Our Mission
                            </h5>
                            <p className="font-['roboto'] font-semibold text-[18px] text-[#777777]">
                                Our mission is simple yet profound: To empower
                                individuals and businesses with the tools to
                                transcend reality. We believe in the
                                transformative power of AR and VR to
                                revolutionize industries, enhance education,
                                elevate entertainment, and unlock new ways of
                                experiencing the world around us.
                            </p>
                        </div>
                    </div>

                    <div className="bg-gradient-to-b from-[#2059C5] to-black text-white">
                        <div className="text-center py-24 max-w-screen-2xl mx-auto max-md:px-2">
                            <h5 className="text-3xl mb-7 max-md:mb-5">
                                {" "}
                                Join Us on Our Journey{" "}
                            </h5>
                            <p className="mb-10 px-2 font-['roboto'] text-[18px]">
                                {" "}
                                Whether you're an individual seeking new
                                dimensions of entertainment or a business aiming
                                to revolutionize your industry, Invincible ocean
                                invites you to join us on this exciting journey.
                                Together, we'll explore the limitless
                                possibilities of augmented and virtual reality.
                            </p>
                            <button
                                onClick={props.loginController}
                                className="border-2 bg-[#FFF8F840] text-white max-md:mt-2 transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-[#FFF8F860] px-8 py-3 rounded-md"
                            >
                                Login to Eternity City
                                <i className="fa fa-long-arrow-right ml-4"></i>
                            </button>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}
