import { IAllUserDetailsContainerProps } from "./AllUserDetails.interfaces";
import AllUserDetailsView from "./AllUserDetails.view";
import { useState } from "react";

export default function AllUserDetailsContainer(
    props: IAllUserDetailsContainerProps
) {
    const [btnName, SetBtnName] = useState("all-users-details");
    const [record] = useState(["Testing Purpose"]); //record needs to be set by an api call which returns a list of users that are currently using under the admin => SA || 2024-01-11 15:29:56
    const handleClick = (e: string) => {
        SetBtnName(e);
    };
    const saveDetails = (i: any) => {
        localStorage.setItem("user", JSON.stringify(i));
    };
    return (
        <>
            <AllUserDetailsView
                btnName={btnName}
                handleClick={handleClick}
                saveDetails={saveDetails}
                record={record}
            />
        </>
    );
}
