import { IBranchViewProps } from "./Branch.interfaces";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 3,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1388 },
        items: 2,
    },
    tablet: {
        breakpoint: { max: 1388, min: 687 },
        items: 1,
    },
    mobile: {
        breakpoint: { max: 687, min: 0 },
        items: 1,
    },
};

export default function BranchView(props: IBranchViewProps) {
    return (
        <>
            <div className="text-left">
                <div className="grid bg-white m-4 rounded">
                    <div className="p-4 grid grid-cols-2 max-lg:block">
                        <div className="px-10 max-md:px-2 max-lg:hidden">
                            <h5 className="text-darkblue font-['roboto']">
                                Branch 1
                            </h5>
                            <div className="py-2 flex justify-center items-center">
                                {props.hoverCards === "c1" && (
                                    <div className="h-[350px] w-[400px] max-lg:w-[200px] max-lg:h-[300px] max-md:w-[200px] max-md:h-[200px] rounded-lg">
                                        <img
                                            src="../images/dashboard/c1.webp"
                                            alt=""
                                        />
                                    </div>
                                )}
                                {props.hoverCards === "c3" && (
                                    <div className="h-[350px] w-[400px] max-lg:w-[200px] max-lg:h-[300px] max-md:w-[200px] max-md:h-[200px] rounded-lg">
                                        <img
                                            src="../images/dashboard/c3.webp"
                                            alt=""
                                        />
                                    </div>
                                )}
                                {props.hoverCards === "c4" && (
                                    <div className="h-[350px] w-[400px] max-lg:w-[200px] max-lg:h-[300px] max-md:w-[200px] max-md:h-[200px] rounded-lg">
                                        <img
                                            src="../images/dashboard/c4.webp"
                                            alt=""
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="">
                                <Carousel
                                    containerClass="carousel-container"
                                    responsive={responsive}
                                    swipeable={true}
                                    draggable={true}
                                    showDots={true}
                                    ssr={true}
                                    infinite={true}
                                    keyBoardControl={true}
                                    removeArrowOnDeviceType={[
                                        "tablet",
                                        "mobile",
                                    ]}
                                >
                                    <div
                                        className="h-[200px] w-[200px] mx-2"
                                        onClick={() =>
                                            props.handleHoverCards("c1")
                                        }
                                    >
                                        <img
                                            src="../images/dashboard/c1.webp"
                                            alt=""
                                        />
                                    </div>
                                    <div
                                        className="h-[200px] w-[200px] mx-2"
                                        onClick={() =>
                                            props.handleHoverCards("c3")
                                        }
                                    >
                                        <img
                                            src="../images/dashboard/c3.webp"
                                            alt=""
                                        />
                                    </div>
                                    <div
                                        className="h-[200px] w-[200px] mx-2"
                                        onClick={() =>
                                            props.handleHoverCards("c4")
                                        }
                                    >
                                        <img
                                            src="../images/dashboard/c4.webp"
                                            alt=""
                                        />
                                    </div>
                                </Carousel>
                            </div>
                        </div>
                        <div className="p-1">
                            <div className="flex justify-between">
                                <div className="flex font-['roboto'] text-[#929191] font-semibold">
                                    <p
                                        className={`${
                                            props.selection === "user-visits"
                                                ? "underline text-[#489BF6] duration-500"
                                                : "text-[#929191] hover:text-[#489BF6]"
                                        } px-2 cursor-pointer`}
                                        onClick={() => {
                                            props.handleSelection(
                                                "user-visits"
                                            );
                                        }}
                                    >
                                        User Visits
                                    </p>
                                    <p
                                        className={`${
                                            props.selection === "dept-wise"
                                                ? "underline text-[#489BF6] duration-500"
                                                : "text-[#929191] hover:text-[#489BF6]"
                                        } px-2 cursor-pointer`}
                                        onClick={() =>
                                            props.handleSelection("dept-wise")
                                        }
                                    >
                                        Dept wise queries
                                    </p>
                                </div>{" "}
                                <div className="flex justify-center items-center">
                                    <p className="text-[12px] font-[500] text-[#929191]">
                                        Per day
                                    </p>
                                    <i className="fa fa-angle-down ml-1 text-[#929191]"></i>
                                </div>
                            </div>
                            <div>
                                <div className="flex justify-between bg-lightblue p-4 max-xl:p-2 max-lg:p-4 my-4 rounded-xl bg-[#B6DAF41F]">
                                    <div className="px-2 max-xl:px-[2px] max max-lg:px-1 border-r-2">
                                        <p className="font-['roboto'] text-[#929191] text-[14px] font-[600]">
                                            Loan Department
                                        </p>
                                        <h5 className="text-3xl text-darkblue font-semibold">
                                            870
                                        </h5>
                                    </div>
                                    <div className="px-2 max-xl:px-[2px] max-lg:px-1 border-r-2">
                                        <p className="font-['roboto'] text-[#929191] text-[14px] font-[600]">
                                            Credit card department{" "}
                                        </p>
                                        <h5 className="text-3xl text-darkblue font-semibold">
                                            870
                                        </h5>
                                    </div>
                                    <div className="px-2 max-xl:px-[2px] max-lg:px-1 border-r-2">
                                        <p className="font-['roboto'] text-[#929191] text-[14px] font-[600]">
                                            Account department
                                        </p>
                                        <h5 className="text-3xl text-darkblue font-semibold">
                                            870
                                        </h5>
                                    </div>
                                    <div className="px-2 max-xl:px-[2px] max-lg:px-1">
                                        <p className="font-['roboto'] text-[#929191] text-[14px] font-[600]">
                                            Customer Queries
                                        </p>
                                        <h5 className="text-3xl text-darkblue font-semibold">
                                            870
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="py-4">
                                <div className="flex justify-between py-2">
                                    <h5 className="font-[600] text-[16px] text-[#1C2347]">
                                        Users visits
                                    </h5>
                                    <div className="flex justify-center items-center">
                                        <p className="text-[12px] font-[500] text-[#929191]">
                                            Per week
                                        </p>
                                        <i className="fa fa-angle-down ml-1 text-[#929191]"></i>
                                    </div>
                                </div>
                                <img
                                    src="../images/dashboard/graph.webp"
                                    alt=""
                                />
                            </div>
                            <div className="">
                                <Link to="/dashboard/all-user-details">
                                    <div className="border-2 rounded-lg flex justify-between hover:bg-[#489BF6] p-4 hover:scale-[1.02] duration-500 group">
                                        <p className="font-['roboto'] text-sm text-[#929191] group-hover:text-[#ffffff] font-[600]">
                                            View all Users details
                                        </p>
                                        <i className="fa fa-angle-right"></i>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
