import ApiService from "./API.service";

const baseUrl = process.env.REACT_APP_METAVERSE_MICROSERVICE_BASE_URL;

const MetaverseService = {
    /**
     * PUT /metaverse
     * @access super-admin
     */
    create: async (metaverse: any) => {
        // Change type to Metaverse => TP | 2024-01-30 17:19:57
        try {
            const url = `${baseUrl}metaverse`;
            const data = await ApiService.put(url, metaverse);
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * GET /metaverse
     * @access public
     */
    fetch: async () => {
        try {
            const url = `${baseUrl}metaverses`;
            const data = await ApiService.get(url);
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * GET /metaverse/{id}
     * @access public
     */
    fetchByID: async (id: string) => {
        try {
            const url = `${baseUrl}metaverse/${id}`;
            const data = await ApiService.get(url);
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * PATCH /metaverse/{id}
     * @access super-admin
     */
    update: async (id: string, update: any) => {
        try {
            const url = `${baseUrl}metaverse/${id}`;
            const data = await ApiService.patch(url, {
                ...update,
            });
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * DELETE /metaverse/{id}
     * @access super-admin
     */
    delete: async (id: string) => {
        try {
            const url = `${baseUrl}metaverse/${id}`;
            const data = await ApiService.delete(url);
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * PUT /metaverse/{id}/dashboard-user/{dashboardUserID}
     * @access super-admin
     */
    addDashboardUserAsMetaverseManager: async (
        metaverseID: string,
        dashboardUserID: string
    ) => {
        try {
            const url = `${baseUrl}metaverse/${metaverseID}/dashboard-user/${dashboardUserID}`;
            const data = await ApiService.put(url, {});
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * DELETE /metaverse/{id}/dashboard-user/{dashboardUserID}
     * @access super-admin
     */
    removeDashboardUserAsMetaverseManager: async (
        metaverseID: string,
        dashboardUserID: string
    ) => {
        try {
            const url = `${baseUrl}metaverse/${metaverseID}/dashboard-user/${dashboardUserID}`;
            const data = await ApiService.delete(url);
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * GET /metaverse/{id}/dashboard-users
     * @access super-admin
     */
    fetchDashboardUsersOfMetaverseByID: async (metaverseID: string) => {
        try {
            const url = `${baseUrl}metaverse/${metaverseID}/dashboard-users`;
            const data = await ApiService.get(url);
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * PUT /metaverse/{id}/user
     * @access user
     */
    associateUserWithMetaverse: async (metaverseID: string | undefined) => {
        try {
            if (metaverseID === undefined) window.location.href = "/";
            const url = `${baseUrl}metaverse/${metaverseID}/metaverse-user`;
            const data = await ApiService.put(url, {});
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * DELETE /metaverse/{id}/user/{userID}
     * @access super-admin
     */
    deassociateUserFromMetaverse: async (
        metaverseID: string,
        userID: string
    ) => {
        try {
            const url = `${baseUrl}metaverse/${metaverseID}/user/${userID}`;
            const data = await ApiService.delete(url);
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * GET /metaverse/{id}/users
     */
    fetchUsersOfMetaverseByID: async (metaverseID: string) => {
        try {
            const url = `${baseUrl}metaverse/${metaverseID}/metaverse-users`;
            const data = await ApiService.get(url);
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
};

export default MetaverseService;
