import { IBranchContainerProps } from "./Branch.interfaces";
import BranchView from "./Branch.view";
import { useState } from "react";

export default function BranchContainer(props: IBranchContainerProps) {
    const [selection, setSelection] = useState("user-visits");
    const [hoverCards, setHoverCards] = useState("c1");
    const handleSelection = (i: string) => {
        setSelection(i);
    };
    const handleHoverCards = (i: string) => {
        setHoverCards(i);
    };
    return (
        <>
            <BranchView
                selection={selection}
                handleSelection={handleSelection}
                handleHoverCards={handleHoverCards}
                hoverCards={hoverCards}
            />
        </>
    );
}
