import { IUserDetailsViewProps } from "./UserDetails.interfaces";
import { Link } from "react-router-dom";
export default function UserDetailsView(props: IUserDetailsViewProps) {
    return (
        <>
            <div className="px-10 py-10 max-md:px-2 py-2 text-left">
                <Link to="/dashboard/all-user-details">
                    <span className="font-['roboto'] text-sm font-semibold text-[#489BF6]">
                        {"<< Back"}
                    </span>
                </Link>

                <div className="grid grid-cols-[180px_1fr_1fr_1fr_1fr] justify-evenly py-4 items-center border-[1px] border-[#ABABAB1C] rounded-lg bg-white font-['roboto'] text-[#6B6B6B] text-base font-semibold max-md:block max-md:py-2">
                    <img
                        className="py-4 px-7 border-r-2 border-[#ABABAB1C] max-md:border-none"
                        src="../images/dashboard/profile.svg"
                        alt=""
                    />
                    <div className="max-md:grid max-md:grid-cols-[100px,1fr] ml-8">
                        <span className="text-xs font-medium">Date</span>
                        <br className="max-md:hidden" />
                        <span className="text-base text-black font-medium pr-2"></span>
                    </div>
                    <div className="max-md:grid max-md:grid-cols-[100px,1fr] ml-8">
                        <span className="text-xs font-medium">Name</span>
                        <br className="max-md:hidden" />
                        <span className="text-base text-black font-medium pr-2"></span>
                    </div>
                    <div className="max-md:grid max-md:grid-cols-[100px,1fr] ml-8">
                        <span className="text-xs font-medium">Email</span>
                        <br className="max-md:hidden" />
                        <span className="text-base text-black font-medium pr-2"></span>
                    </div>
                    <div className="max-md:grid max-md:grid-cols-[100px,1fr] ml-8">
                        <span className="text-xs font-medium">
                            Phone number
                        </span>
                        <br className="max-md:hidden" />
                        <span className="text-base text-black font-medium pr-2"></span>
                    </div>
                </div>

                <div className="border-[1px] border-[#ABABAB1C] rounded-lg mt-5 max-md:mt-3">
                    <div className="flex items-center mx-6 my-4 p-2 border-[1px] border-[#F7F7F7] rounded-lg bg-[#F7F7F7] font-['roboto'] text-xs font-medium max-md:block">
                        <p
                            className={`${
                                props.btnName === "logs"
                                    ? "bg-[#96D4FE1F] border-2 border-[#489BF60A] rounded-lg text-[#489BF6]"
                                    : "hover:bg-[#96D4FE1F] duration-300"
                            } cursor-pointer py-2 px-14 mx-2 max-md:px-5`}
                            onClick={() => {
                                props.handleClick("logs");
                            }}
                        >
                            Logs
                        </p>
                        <p
                            className={`${
                                props.btnName === "overall_journey"
                                    ? "bg-[#96D4FE1F] border-2 border-[#489BF60A] rounded-lg text-[#489BF6]"
                                    : "hover:bg-[#96D4FE1F] duration-300"
                            } cursor-pointer py-2 px-14 mx-2 max-md:px-5 `}
                            onClick={() => {
                                props.handleClick("overall_journey");
                            }}
                        >
                            Overall journey
                        </p>
                        <p
                            className={`${
                                props.btnName === "accessed_api"
                                    ? "bg-[#96D4FE1F] border-2 border-[#489BF60A] rounded-lg text-[#489BF6]"
                                    : "hover:bg-[#96D4FE1F] duration-300"
                            } cursor-pointer py-2 px-14 mx-2 max-md:px-5`}
                            onClick={() => {
                                props.handleClick("accessed_api");
                            }}
                        >
                            Accessed APIs
                        </p>
                        <p
                            className={`${
                                props.btnName === "setting"
                                    ? "bg-[#96D4FE1F] border-2 border-[#489BF60A] rounded-lg text-[#489BF6]"
                                    : "hover:bg-[#96D4FE1F] duration-300"
                            } cursor-pointer py-2 px-14 mx-2 max-md:px-5`}
                            onClick={() => {
                                props.handleClick("setting");
                            }}
                        >
                            Settings
                        </p>
                    </div>
                    <div className="mx-6 mb-4 border-[1px] border-[#D9D9D9] rounded-lg font-['roboto'] text-xs font-medium">
                        {props.btnName === "logs" && (
                            <div>
                                <span className="flex my-4 mx-3 pl-4">
                                    Today logs{" "}
                                    <i className="fa fa-angle-down ml-2"></i>
                                </span>
                                <div className="grid grid-cols-[56px_3fr_2fr_5fr] bg-[#ABABAB1C] items-center max-md:hidden">
                                    <span> </span>
                                    <span className="py-4 pl-8">Activity</span>
                                    <span className="py-4 pl-6">Time</span>
                                    <span className="py-4 pl-5">Details</span>
                                </div>

                                <div className="my-4 border-l-2 border-dashed border-[#489BF60A] ml-4 max-md:border-none">
                                    {props.record.map(
                                        (item: any, index: any) => (
                                            <div
                                                className="grid grid-cols-[56px_3fr_2fr_5fr] mt-3 border-[#ABABAB1C] items-center max-md:block max-md:border-b-2 max-md:mr-4"
                                                key={index}
                                            >
                                                <span className="bg-[#489BF6] w-3 h-3 rounded-full my-2.5 ml-[-7px] max-md:hidden"></span>
                                                <p className="py-2 text-[#6B6B6B] border-b-4 max-md:border-none py-1 pl-1 grid grid-cols-[100px,1fr]">
                                                    <span className="hidden max-md:block text-darkblue">
                                                        Activity
                                                    </span>
                                                    <span>
                                                        {item.department}
                                                    </span>
                                                </p>
                                                <p className="py-2 text-[#6B6B6B] border-b-4 max-md:border-none py-1 pl-1 grid grid-cols-[100px,1fr]">
                                                    <span className="hidden max-md:block text-darkblue">
                                                        Time
                                                    </span>
                                                    {item.entryTime}
                                                </p>
                                                <p className="py-2 mr-4 text-[#6B6B6B] border-b-4 max-md:border-none py-1 pl-1 max-md:grid grid-cols-[100px,1fr]">
                                                    <span className="hidden max-md:block text-darkblue">
                                                        Details
                                                    </span>
                                                    {item.department}
                                                </p>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        )}

                        {props.btnName === "overall_journey" && (
                            <div>
                                <div className="grid grid-cols-[180px_1fr_1fr_1fr_1fr] items-center border-b-1 border-[#ABABAB1C]">
                                    <img
                                        className="px-8 pt-7 pb-3.5 max-md:px-5 pt-4 pb-4"
                                        src="../images/dashboard/time.svg"
                                        alt=""
                                    />
                                    <div className="pt-7 pb-3.5">
                                        <span>Entry time</span>
                                        <br />
                                        <p className="font-medium text-sm text-[#6B6B6B] mt-1.5">
                                            9:20 AM
                                        </p>
                                    </div>
                                    <div className="text-[#489BF6] text-lg pt-7 pb-3.5 -ml-16 max-md:hidden">
                                        --
                                    </div>
                                    <div className="pt-7 pb-3.5 -ml-32 max-md:ml-0">
                                        <span>Exit time</span>
                                        <br />
                                        <p className="font-medium text-sm text-[#6B6B6B] mt-1.5">
                                            10:20 AM
                                        </p>
                                    </div>
                                </div>
                                <p className="bg-[#7C56C3] text-white mx-4 mt-2 py-2 px-3.5 text-sm">
                                    Loan department
                                </p>
                                <div className="mx-4 bg-[#F7F7F7]">
                                    <div className="grid grid-cols-[200px_1fr] items-center border-b-1 border-[#ABABAB1C]">
                                        {" "}
                                        <span className="p-3.5 pr-0">
                                            Entry time
                                        </span>{" "}
                                        <span className="text-[#6B6B6B]">
                                            9:20 AM
                                        </span>
                                    </div>
                                    <div className="grid grid-cols-[200px_1fr] items-center border-b-1 border-[#ABABAB1C]">
                                        {" "}
                                        <span className="p-3.5 pr-0">
                                            Access APIs
                                        </span>{" "}
                                        <span className="text-[#6B6B6B]">
                                            PAN, Aadhaar
                                        </span>
                                    </div>

                                    <p className="bg-[#00000012] py-2 px-3.5 text-sm font-medium">
                                        PAN details
                                    </p>
                                    <div className="grid grid-cols-[200px_1fr] items-center border-b-1 border-[#ABABAB1C]">
                                        {" "}
                                        <span className="p-3.5 pr-0">
                                            Name on PAN
                                        </span>{" "}
                                        <span className="text-[#6B6B6B]">
                                            NISHANT KAUSHIK
                                        </span>
                                    </div>
                                    <div className="grid grid-cols-[200px_1fr] items-center border-b-1 border-[#ABABAB1C]">
                                        {" "}
                                        <span className="p-3.5 pr-0">
                                            PAN number
                                        </span>{" "}
                                        <span className="text-[#6B6B6B]">
                                            CYNPDXXX
                                        </span>
                                    </div>

                                    <p className="bg-[#00000012] py-2 px-3.5 text-sm font-medium">
                                        Aadhaar details
                                    </p>
                                    <div className="grid grid-cols-[200px_1fr] items-center border-b-1 border-[#ABABAB1C]">
                                        {" "}
                                        <span className="p-3.5 pr-0">
                                            Name on Aadhaar
                                        </span>{" "}
                                        <span className="text-[#6B6B6B]">
                                            NISHANT KAUSHIK
                                        </span>
                                    </div>
                                    <div className="grid grid-cols-[200px_1fr] items-center border-b-1 border-[#ABABAB1C]">
                                        {" "}
                                        <span className="p-3.5 pr-0">
                                            Date of birth
                                        </span>{" "}
                                        <span className="text-[#6B6B6B]">
                                            1-10-1990
                                        </span>
                                    </div>
                                    <div className="grid grid-cols-[200px_1fr] items-center border-b-1 border-[#ABABAB1C]">
                                        {" "}
                                        <span className="p-3.5 pr-0">
                                            Gender
                                        </span>{" "}
                                        <span className="text-[#6B6B6B]">
                                            M
                                        </span>
                                    </div>
                                    <div className="grid grid-cols-[200px_1fr] items-center border-b-1 border-[#ABABAB1C]">
                                        {" "}
                                        <span className="p-3.5 pr-0">
                                            Aadhaar address
                                        </span>{" "}
                                        <span className="text-[#6B6B6B]">
                                            S/0 Vijay setia, yamuna nagar,
                                            320/13
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}

                        {props.btnName === "accessed_api" && (
                            <div>
                                <div className="text-[#6B6B6B] text-xs font-semibold items-center p-2">
                                    <p className="py-10 text-center">
                                        No Data Found
                                    </p>
                                </div>
                            </div>
                        )}
                        {props.btnName === "setting" && (
                            <div>
                                <div className="text-[#6B6B6B] text-xs font-semibold items-center p-2">
                                    <p className="py-10 text-center">
                                        No Data Found
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
