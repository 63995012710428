import { useEffect, useState } from "react";
import { IVrAutoContainerProps } from "./VrAuto.interfaces";
import VrAutoView from "./VrAuto.view";
import BroswerService from "../../Services/Browser.service";
export default function VrAutoContainer(props: IVrAutoContainerProps) {
    useEffect(() => {
        BroswerService.setCustomTitle("VrAutoContainer");
    }, []);
    const [showDemo, setShowDemo] = useState(false);
    const demoController = () => setShowDemo(!showDemo);
    const [showlogin, setShowlogin] = useState(false);
    const loginController = () => setShowlogin(!showlogin);

    return (
        <>
            <VrAutoView
                demoController={demoController}
                showDemo={showDemo}
                loginController={loginController}
                showlogin={showlogin}
            />
        </>
    );
}
