import { ISuperAdminDashboardContainerProps } from "./SuperAdminDashboard.interfaces";
import SuperAdminDashboardBoxView from "./SuperAdminDashboard.view";

export default function SuperAdminDashboardContainer(
    props: ISuperAdminDashboardContainerProps
) {
    return (
        <>
            <SuperAdminDashboardBoxView />
        </>
    );
}
