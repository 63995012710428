import { lazy } from "react";

const Aboutus = lazy(() => import("./Aboutus"));
const AllUserDetails = lazy(() => import("./AllUserDetails"));
const Branch = lazy(() => import("./Branch"));
const Contact = lazy(() => import("./Contact"));
const Dashboard = lazy(() => import("./Dashboard"));
const DashboardLogin = lazy(() => import("./DashboardLogin"));
const Faq = lazy(() => import("./Faq"));
const Industries = lazy(() => import("./Industries"));
const LandingPage = lazy(() => import("./LandingPage"));
const LoginWithCode = lazy(() => import("./LoginWithCode"));
const Metaverse = lazy(() => import("./Metaverse"));
const MyAccount = lazy(() => import("./MyAccount"));
const NotFound = lazy(() => import("./NotFound"));
const SuperAdminDashboard = lazy(() => import("./SuperAdminDashboard"));
const SuperAdminDashboardUser = lazy(() => import("./SuperAdminDashboardUser"));
const SuperAdminDashboardUsers = lazy(
    () => import("./SuperAdminDashboardUsers")
);
const SuperAdminLogin = lazy(() => import("./SuperAdminLogin"));
const SuperAdminMetaverseDetails = lazy(
    () => import("./SuperAdminMetaverseDetails")
);
const SuperAdminMetaverseUser = lazy(() => import("./SuperAdminMetaverseUser"));
const SuperAdminMetaverseUsers = lazy(
    () => import("./SuperAdminMetaverseUsers")
);
const SuperAdminMyAccount = lazy(() => import("./SuperAdminMyAccount"));
const UserDetails = lazy(() => import("./UserDetails"));
const VrAuto = lazy(() => import("./VrAuto"));
const VrBank = lazy(() => import("./VrBank"));
const VrRetail = lazy(() => import("./VrRetail"));
const VrWorld = lazy(() => import("./VrWorld"));
const DashboardMetaverseDetails = lazy(
    () => import("./DashboardMetaverseDetails")
);
const DashboadMetaverseUser = lazy(() => import("./DashboardMetaverseUser"));
const DashboadMetaverseUsers = lazy(() => import("./DashboardMetaverseUsers"));
export {
    Aboutus,
    AllUserDetails,
    Branch,
    Contact,
    Dashboard,
    DashboardLogin,
    // DashboardUsers,
    DashboardMetaverseDetails,
    DashboadMetaverseUser,
    DashboadMetaverseUsers,
    Faq,
    Industries,
    LandingPage,
    LoginWithCode,
    Metaverse,
    MyAccount,
    NotFound,
    SuperAdminDashboard,
    SuperAdminDashboardUser,
    SuperAdminDashboardUsers,
    SuperAdminLogin,
    SuperAdminMetaverseDetails,
    SuperAdminMetaverseUser,
    SuperAdminMetaverseUsers,
    SuperAdminMyAccount,
    UserDetails,
    VrAuto,
    VrBank,
    VrRetail,
    VrWorld,
};
