import { ISuperAdminDashboardUsersViewProps } from "./SuperAdminDashboardUsers.interfaces";
// import { Link } from "react-router-dom";
import Message from "../Message";
import { Link } from "react-router-dom";
import Loader from "../Loader";
import PaginationComponent from "../Pagination";

export default function SuperAdminDashboardUserView(
    props: ISuperAdminDashboardUsersViewProps
) {
    return (
        <>
            {props.showMessage && (
                <Message
                    messageText={props.messageText}
                    messageClass={props.messageClass}
                    demoController={props.messageController}
                />
            )}
            <div className="text-left">
                <div className="grid bg-white m-4 rounded ">
                    {!props.createDashboardUser &&
                        !props.updateDashboardUser && (
                            <div className="p-4">
                                <div className="py-10 p-4 flex items-center justify-between max-md:block">
                                    <button
                                        onClick={
                                            props.handleCreateDashboardUser
                                        }
                                        className="my-1 bg-blue-100 font-[600] px-10 max-md:px-[20px] py-3 text-white transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-gradient-to-l rounded-md mr-4 bg-gradient-to-r from-blue-500 to-purple-600"
                                    >
                                        Create Dashboard User
                                    </button>
                                    {/* <div className="">
                                        <select
                                            name="filtertype"
                                            className="mx-1 cursor-pointer border-[1px] rounded-lg px-3 max-md:px-0 py-2 max-md:my-1 font-[600] outline-none bg-gradient-to-r from-blue-500 to-purple-600 text-white"
                                            onChange={props.onFilterChange}
                                        >
                                            <option
                                                value=""
                                                className="font-[600] text-[#CCCCCC]"
                                            >
                                                Select
                                            </option>
                                            <option
                                                value="name"
                                                className="font-[600] text-black"
                                            >
                                                Name
                                            </option>
                                            <option
                                                value="email"
                                                className="font-[600] text-black"
                                            >
                                                Email
                                            </option>
                                            <option
                                                value="description"
                                                className="font-[600] text-black"
                                            >
                                                Description
                                            </option>
                                            <option
                                                value="phoneNumber"
                                                className="font-[600] text-black"
                                            >
                                                Phone Number
                                            </option>
                                        </select>
                                        <select
                                            name="regex"
                                            className="mx-1 cursor-pointer border-[1px] rounded-lg pl-3 pr-9 max-md:pr-0 max-md:pl-0 max-md:my-1  py-2 font-[600] outline-none bg-gradient-to-r from-blue-500 to-purple-600 text-white"
                                            onChange={props.onFilterChange}
                                        >
                                            <option
                                                value=""
                                                className="font-[600] text-[#CCCCCC]"
                                            >
                                                Select
                                            </option>
                                            <option
                                                value="=%5E"
                                                className="font-[600] text-black"
                                            >
                                                Starts with
                                            </option>
                                            <option
                                                value="=%24"
                                                className="font-[600] text-black"
                                            >
                                                Ends with
                                            </option>
                                            <option
                                                value="=~"
                                                className="font-[600] text-black"
                                            >
                                                Contains
                                            </option>
                                            <option
                                                value="="
                                                className="font-[600] text-black"
                                            >
                                                Equals
                                            </option>
                                        </select>
                                        <input
                                            placeholder="Enter the value"
                                            name="value"
                                            className="border-[1px] rounded-md px-5 mx-1 py-2 outline-blue-500"
                                            onChange={props.onFilterChange}
                                        ></input>
                                        <button
                                            onClick={props.handleFilterChange}
                                            className="my-1 bg-blue-100 font-[600] px-10 max-md:px-[10px] py-3 text-white transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-gradient-to-l rounded-md mx-2 mr-4 bg-gradient-to-r from-blue-500 to-purple-600"
                                        >
                                            Apply Filter
                                        </button>
                                    </div> */}
                                    <PaginationComponent
                                        handleFilterChange={
                                            props.handleFilterChange
                                        }
                                        onQueryStringChange={
                                            props.handleQueryStringChange
                                        }
                                    />
                                </div>
                                <div className="superadmin-metaverse-user-box">
                                    <div className="superadmin-metaverse-user-head grid-cols-[50px_1fr_250px_1fr_1fr_1fr_1fr_1fr_1fr]">
                                        <p>#</p>
                                        <p>Name</p>
                                        <p>Email</p>
                                        <p>Phone Number</p>
                                        <p>Created At</p>
                                        <p>Updated At</p>
                                        <p>Update</p>
                                        <p>Delete</p>
                                        <p>View Details</p>
                                    </div>
                                    {props.dashboardUsers.length >= 1 ? (
                                        <>
                                            {props.dashboardUsers.map(
                                                (
                                                    dashboardUser: any,
                                                    index: number
                                                ) => (
                                                    <div
                                                        className="superadmin-metaverse-user-body grid-cols-[50px_1fr_250px_1fr_1fr_1fr_1fr_1fr_1fr]"
                                                        key={index}
                                                    >
                                                        <p>
                                                            <span>#</span>{" "}
                                                            {props.currentPage ===
                                                            1
                                                                ? index + 1
                                                                : (props.currentPage -
                                                                      1) *
                                                                      10 +
                                                                  index +
                                                                  1}
                                                        </p>
                                                        <p className="">
                                                            <span>Name: </span>
                                                            {dashboardUser.name}
                                                        </p>
                                                        <p className="">
                                                            <span>Email: </span>
                                                            {
                                                                dashboardUser.email
                                                            }
                                                        </p>
                                                        <p className="">
                                                            <span>
                                                                Phone Number:{" "}
                                                            </span>
                                                            {
                                                                dashboardUser.phoneNumber
                                                            }
                                                        </p>
                                                        <p className="text-[14px]">
                                                            <span>
                                                                Created At:
                                                            </span>
                                                            {new Date(
                                                                dashboardUser.createdAt
                                                            ).toLocaleString()}
                                                        </p>
                                                        <p className="text-[14px]">
                                                            <span>
                                                                Updated At:
                                                            </span>
                                                            {new Date(
                                                                dashboardUser.updatedAt
                                                            ).toLocaleString()}
                                                        </p>
                                                        <p
                                                            onClick={() =>
                                                                props.handleUpdateDashboardUser(
                                                                    dashboardUser._id
                                                                )
                                                            }
                                                            className="text-red-500 underline font-[600] hover:text-green-500 cursor-pointer hover:scale-110 duration-300"
                                                        >
                                                            Update User
                                                        </p>
                                                        <p
                                                            onClick={() =>
                                                                props.deleteDashboardUser(
                                                                    dashboardUser._id
                                                                )
                                                            }
                                                            className="text-red-500 underline font-[600] hover:text-green-500 cursor-pointer  hover:scale-110 duration-300"
                                                        >
                                                            Delete User
                                                        </p>
                                                        <p className="text-[#489BF6] underline font-[600] cursor-pointer hover:scale-110 duration-300">
                                                            <Link
                                                                to={`/super-admin/dashboard-user/${dashboardUser._id}`}
                                                                className=""
                                                            >
                                                                View Details
                                                            </Link>
                                                        </p>
                                                    </div>
                                                )
                                            )}
                                            {
                                                <div className="text-center py-5">
                                                    <button
                                                        onClick={
                                                            props.handlePrevPage
                                                        }
                                                        disabled={
                                                            props.currentPage ===
                                                            1
                                                        }
                                                        className={`${
                                                            props.currentPage ===
                                                            1
                                                                ? "bg-[#DCDCDC]"
                                                                : "bg-[#708090]"
                                                        } rounded-full text-black px-4 m-2`}
                                                    >
                                                        <i className="text-black font-[600] fa fa-angle-double-left"></i>
                                                    </button>
                                                    <span className="font-[600] text-[18px] px-3">
                                                        {props.currentPage}
                                                    </span>
                                                    <button
                                                        onClick={
                                                            props.handleNextPage
                                                        }
                                                        className={`${
                                                            props.endIndex >=
                                                            props.dashboardUsers
                                                                .length
                                                                ? "bg-[#DCDCDC]"
                                                                : "bg-[#708090]"
                                                        } rounded-full text-black px-4 m-2`}
                                                    >
                                                        <i className="text-black font-[600] fa fa-angle-double-right"></i>
                                                    </button>
                                                </div>
                                            }
                                        </>
                                    ) : (
                                        <>
                                            {props.loaded ? (
                                                <div className="text-center p-10">
                                                    <p>
                                                        <span className="font-[600] text-[18px]">
                                                            No Users available
                                                        </span>
                                                    </p>
                                                </div>
                                            ) : (
                                                <Loader />
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                    {props.createDashboardUser &&
                        !props.updateDashboardUser && (
                            <div className="p-4">
                                <span
                                    onClick={props.handleCreateDashboardUser}
                                    className="font-['roboto'] text-sm font-semibold text-[#489BF6] cursor-pointer"
                                >
                                    {"<< Back"}
                                </span>
                                <h3 className="pt-4">Create Dashboard User</h3>
                                <div className="loginform2">
                                    <div>
                                        <label>Name</label>
                                        <input
                                            type="text"
                                            autoComplete="new-text"
                                            placeholder="Name"
                                            name="name"
                                            onChange={
                                                props.dashboardUserDataController
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label>Email</label>
                                        <input
                                            autoComplete="new-email"
                                            type="email"
                                            placeholder="Email"
                                            name="email"
                                            onChange={
                                                props.dashboardUserDataController
                                            }
                                        />
                                    </div>

                                    <div>
                                        <label>Phone Number</label>
                                        <input
                                            type="text"
                                            placeholder="Phone Number"
                                            name="phoneNumber"
                                            onChange={
                                                props.dashboardUserDataController
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label>Password</label>
                                        <input
                                            autoComplete="new-password"
                                            placeholder="Password"
                                            name="password"
                                            type={
                                                props.showPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            onChange={
                                                props.dashboardUserDataController
                                            }
                                        />
                                    </div>
                                    <button
                                        onClick={
                                            props.handleCreateDashboardUserFormSubmit
                                        }
                                    >
                                        Create Dashboard User
                                    </button>
                                </div>
                            </div>
                        )}
                    {props.updateDashboardUser &&
                        !props.createDashboardUser && (
                            <div className="p-4">
                                <span
                                    onClick={() =>
                                        props.handleUpdateDashboardUser("")
                                    }
                                    className="font-['roboto'] text-sm font-semibold text-[#489BF6] cursor-pointer"
                                >
                                    {"<< Back"}
                                </span>{" "}
                                <h3 className="pt-4">Update Dashboard User</h3>
                                <div className="loginform2">
                                    <div>
                                        <label>Name</label>
                                        <input
                                            type="text"
                                            autoComplete="new-text"
                                            placeholder="Name"
                                            name="name"
                                            value={props.dashboardUserData.name}
                                            onChange={
                                                props.dashboardUserDataController
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label>Email</label>
                                        <input
                                            autoComplete="new-email"
                                            type="email"
                                            placeholder="Email"
                                            name="email"
                                            value={
                                                props.dashboardUserData.email
                                            }
                                            onChange={
                                                props.dashboardUserDataController
                                            }
                                        />
                                    </div>

                                    <div>
                                        <label>Phone Number</label>
                                        <input
                                            type="text"
                                            placeholder="Phone Number"
                                            name="phoneNumber"
                                            value={
                                                props.dashboardUserData
                                                    .phoneNumber
                                            }
                                            onChange={
                                                props.dashboardUserDataController
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label>Password</label>
                                        <input
                                            autoComplete="new-password"
                                            placeholder="Password"
                                            name="password"
                                            type={
                                                props.showPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            onChange={
                                                props.dashboardUserDataController
                                            }
                                        />
                                    </div>
                                    <button
                                        onClick={props._updateDashboardUser}
                                    >
                                        Update Dashboard User
                                    </button>
                                </div>
                            </div>
                        )}
                </div>
            </div>
        </>
    );
}
