import { IPaginationContainerProps } from "./Pagination.interface";
import PaginationView from "./Pagination.view";
import { useEffect, useState } from "react";

export default function PaginationContainer(props: IPaginationContainerProps) {
    const [filterData, setFilterData] = useState<any>({
        filtertype: "",
        regex: "",
        value: "",
    });
    const [queryString, setQueryString] = useState("");
    const constructQueryString = (filterData: any) => {
        const { filtertype, regex, value } = filterData;
        let queryString = "";
        if (filtertype && regex && value) {
            queryString = `?${filtertype}${regex}${value}`;
        }
        return queryString;
    };
    useEffect(() => {
        setQueryString(constructQueryString(filterData));
    }, [filterData]);
    const updateNestedValue = (obj: any, keys: string[], value: any): any => {
        if (keys.length === 1) {
            return {
                ...obj,
                [keys[0]]: value,
            };
        }
        const [key, ...remainingKeys] = keys;
        return {
            ...obj,
            [key]: updateNestedValue(obj[key], remainingKeys, value),
        };
    };
    const onFilterChange = (e: any) => {
        setFilterData((prevData: any) => {
            const keys = e.target.name.split(".");
            return updateNestedValue(prevData, keys, e.target.value);
        });
    };
    useEffect(() => {
        props.onQueryStringChange(queryString);
    }, [queryString, props.onQueryStringChange]);
    return (
        <>
            <PaginationView
                onFilterChange={onFilterChange}
                handleFilterChange={props.handleFilterChange}
            />
        </>
    );
}
