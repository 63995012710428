import { useState } from "react";
import { IBookDemoContainerProps, IBookDemoForm } from "./BookDemo.interfaces";
import BookDemoView from "./BookDemo.view";
import { MetaverseUserService } from "../../Services";

export default function BookDemoContainer(props: IBookDemoContainerProps) {
    const [demoForm, setDemoForm] = useState<IBookDemoForm>({
        businessEmail: "",
        companyName: "",
        message: "",
        name: "",
        phoneNumber: "",
    });

    const handleDemoFormChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setDemoForm({
            ...demoForm,
            [event.target.name.replace(/-([a-z])/g, (_, letter) =>
                letter.toUpperCase()
            )]: event.target.value,
        });
    };

    const bookDemo = async (demoForm: any) => {
        const data = await MetaverseUserService.bookDemo(
            demoForm.businessEmail,
            demoForm.companyName,
            demoForm.message,
            demoForm.name,
            demoForm.phoneNumber
        );
        if (data && data.message === "Demo booked") {
            setShowMessage(true);
            setMessageClass("success");
            setMessageText("Request Sent successfully!!");
            setTimeout(function () {
                setShowMessage(false);
                window.location.reload();
            }, 2000);
        } else {
            setShowMessage(true);
            setMessageClass("error");
            setMessageText(data.response.data.errors.details[0].message);
            setTimeout(function () {
                setShowMessage(false);
            }, 2000);
        }
    };

    const handleDemoFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        bookDemo(demoForm);
    };

    const [messageText, setMessageText] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const messageController = () => setShowMessage(!showMessage);

    return (
        <>
            <BookDemoView
                handleDemoFormSubmit={handleDemoFormSubmit}
                handleDemoFormChange={handleDemoFormChange}
                demoController={props.demoController}
                messageController={messageController}
                messageText={messageText}
                messageClass={messageClass}
                showMessage={showMessage}
            />
        </>
    );
}
