const titleMap: any = {
    FaqPageContainer: "FAQs | Invincible Meta",
    AboutusContainer: "About Invincible Metaverse Platform",
    ContactusContainer: "Contact us | Invincible Meta",
    IndustriesContainer: "Industries | Invincible Meta",
    VrAutoContainer: "Automotive Metaverse | Virtual Car Showroom",
    VrBankContainer: "Banking Metaverse | Virtual Bank Branch",
    VrRetailContainer: "Retail Metaverse | Virtual 3D products",
    VrWorldContainer: "VR World | Design your Own Metaverse",
    LandingPageContainer: "InvincibleMeta | Join the Metaverse Revolution",
};
const BroswerService = {
    setCustomTitle: (titleKey: string) => {
        document.title = titleMap[titleKey];
        return () => {
            document.title = "InvincibleMeta | Join the Metaverse Revolution"; // Set your default title here
        };
    },

    scrolltoTarget: (divelement: string) => {
        const targetDiv = document.getElementById(divelement);
        if (targetDiv) {
            setTimeout(() => {
                targetDiv.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "start",
                });
            }, 0);
        }
    },
};

export default BroswerService;
