import Navigation from "../Navigation";
import { IVrWorldViewProps } from "./VrWorld.interfaces";
import BookDemo from "../BookDemo";
import UserLoginBox from "../UserLoginBox";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 4,
        partialVisibilityGutter: 20,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1388 },
        items: 4,
        partialVisibilityGutter: 10,
    },
    tablet: {
        breakpoint: { max: 1388, min: 687 },
        items: 2,
        partialVisibilityGutter: 30,
    },
    mobile: {
        breakpoint: { max: 687, min: 0 },
        items: 1,
        partialVisibilityGutter: 10,
    },
};

export default function VrWorldView(props: IVrWorldViewProps) {
    return (
        <>
            {props.showlogin === true && (
                <UserLoginBox
                    redirectionURL="/metaverse/65b741def8309762720ad53f"
                    loginController={props.loginController}
                /> // Since the line 122, says Login to Eternity City, but can be changed later => TP | 2024-01-30 14:39:44
            )}
            {props.showDemo === true && (
                <BookDemo demoController={props.demoController} />
            )}
            <div className="font-['roboto'] text-left">
                <section className="relative h-auto">
                    <video
                        autoPlay
                        muted
                        loop
                        playsInline
                        className="absolute top-0 left-0 w-full h-full object-cover"
                    >
                        <source
                            src="./images/vrworld/background.webm"
                            type="video/webm"
                        />
                        Your browser does not support the video tag.
                    </video>
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                    <Navigation />
                    <div className="relative z-10 flex flex-col items-center justify-center h-full text-white">
                        <div className="text-left max-w-screen-2xl mx-auto max-md:px-2">
                            <div className="text-center py-72 max-md:py-48">
                                <div className="mx-20 max-md:m-2 max-md:text-center">
                                    <h3 className="my-10 max-md:mt-10 max-md:mb-5 font-[700] leading-[87px] max-md:leading-[48px] text-[58px] max-md:text-2xl text-white">
                                        Welcome to the Future of Virtual
                                        Creation: VR World by Invincible Meta.
                                    </h3>
                                    <p className="text-[18px] mb-20 max-md:mb-2 text-white mx-20 max-md:mx-2 max-md:text-xs text-[18px]">
                                        Discover a new realm of possibilities
                                        with our SAAS-based solutions. Whether
                                        you're a bank, retail store, or
                                        automotive company, we have tailored
                                        products to suit your needs
                                    </p>

                                    <button
                                        onClick={props.loginController}
                                        className="border-2 bg-[#FFF8F840] text-white max-md:mt-2 transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-[#FFF8F860] px-8 py-3 rounded-md"
                                    >
                                        Login to Eternity City
                                        <i className="fa fa-long-arrow-right ml-4"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="bg-[#F5FAFF]">
                        <div className="max-w-screen-2xl mx-auto max-md:px-2 text-[##1C2347]">
                            <h3 className="font-[600] text-[38px] leading-[45px] text-center pt-20 pb-10">
                                Design Your Dream Metaverse
                            </h3>
                            <p className="text-center text-[18px] leading-[26px] font-[400] pb-10">
                                Imagine a platform where your creativity knows
                                no bounds. VR World gives you the power to
                                design, create, and inhabit your very own
                                metaverse city. Whether you're envisioning a
                                bustling urban center, a tranquil retreat, or an
                                innovative educational space, the possibilities
                                are limitless.
                            </p>
                            <div className="pb-20 flex justify-center w-full">
                                <button
                                    onClick={props.demoController}
                                    className="bg-gradient-to-r from-blue-500 to-purple-600 inline-block text-transparent bg-clip-text cursor-pointer font-[700]"
                                >
                                    Book a demo{" "}
                                    <i className="fa fa-angle-right font-[700] text-[22px] pl-2"></i>
                                </button>
                            </div>

                            <div className="pb-20 max-w-screen-2xl mx-auto">
                                <Carousel
                                    containerClass="carousel-container"
                                    responsive={responsive}
                                    partialVisible={true}
                                    swipeable={true}
                                    draggable={true}
                                    showDots={true}
                                    ssr={true}
                                    infinite={true}
                                    autoPlay={true}
                                    autoPlaySpeed={2000}
                                    keyBoardControl={true}
                                    removeArrowOnDeviceType={[
                                        "tablet",
                                        "mobile",
                                    ]}
                                >
                                    <div className="shadow-2xl rounded-lg my-10 max-md:mx-10 hover:scale-110 max-md:hover:scale-100 duration-500 w-[314px] h-[300px] 300px bg-white mr-5 px-3 grid grid-rows-[120px_auto]">
                                        {" "}
                                        <h5 className="text-[#1C2347] text-[22px] pt-10">
                                            Conceptualize Your Dream City
                                        </h5>
                                        <p className="font-[400] text-[18px] leading-[30px] text-[#6B6B6B]">
                                            Discover the potential of your
                                            imagination as we help you
                                            conceptualize and outline your dream
                                            virtual city.
                                        </p>
                                    </div>
                                    <div className="shadow-2xl rounded-lg my-10 max-md:mx-10 hover:scale-110 max-md:hover:scale-100 duration-500 w-[314px] h-[300px] 300px bg-white mr-5 px-3 grid grid-rows-[120px_auto]">
                                        {" "}
                                        <h5 className="text-[#1C2347] text-[22px] pt-10">
                                            Designing the Virtual Landscape
                                        </h5>
                                        <p className="font-[400] text-[18px] leading-[30px] text-[#6B6B6B]">
                                            Experience the artistry as we design
                                            breathtaking landscapes, intricate
                                            buildings, and immersive
                                            environments.
                                        </p>
                                    </div>
                                    <div className="shadow-2xl rounded-lg my-10 max-md:mx-10 hover:scale-110 max-md:hover:scale-100 duration-500 w-[314px] h-[300px] 300px bg-white mr-5 px-3 grid grid-rows-[120px_auto]">
                                        {" "}
                                        <h5 className="text-[#1C2347] text-[22px] pt-10">
                                            Bringing the City to Life
                                        </h5>
                                        <p className="font-[400] text-[18px] leading-[30px] text-[#6B6B6B]">
                                            We ensure every street corner,
                                            building, and interactive element in
                                            your VR city is engaging and
                                            flawlessly integrated.
                                        </p>
                                    </div>
                                    <div className="shadow-2xl rounded-lg my-10 max-md:mx-10 hover:scale-110 max-md:hover:scale-100 duration-500 w-[314px] h-[300px] 300px bg-white mr-5 px-3 grid grid-rows-[120px_auto]">
                                        {" "}
                                        <h5 className="text-[#1C2347] text-[22px] pt-10">
                                            Rigorous Testing for Perfection
                                        </h5>
                                        <p className="font-[400] text-[18px] leading-[30px] text-[#6B6B6B]">
                                            We ensure your VR city is not only
                                            visually stunning but also intuitive
                                            and user-friendly, providing a
                                            seamless experience for all
                                            visitors.
                                        </p>
                                    </div>
                                    <div className="shadow-2xl rounded-lg my-10 max-md:mx-10 hover:scale-110 max-md:hover:scale-100 duration-500 w-[314px] h-[300px] 300px bg-white mr-5 px-3 grid grid-rows-[120px_auto]">
                                        {" "}
                                        <h5 className="text-[#1C2347] text-[22px] pt-10">
                                            Launch and Evolve
                                        </h5>
                                        <p className="font-[400] text-[18px] leading-[30px] text-[#6B6B6B]">
                                            We continue to support and enhance
                                            your creation, adapting and evolving
                                            with user feedback and the latest in
                                            VR technology.
                                        </p>
                                    </div>
                                </Carousel>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="max-w-screen-2xl mx-auto max-xl:px-2 text-[##1C2347] my-10">
                            <div>
                                <div className="rounded-lg bg-[#1C2347] w-full h-[400px] max-md:h-auto grid grid-cols-2 my-5 max-md:block">
                                    <div className="p-10 rounded-lg text-white">
                                        <h5 className="font-[600] text-[48px] leading-[56px] pb-10 max-md:pb-2 max-md:text-[36px] max-md:leading-[36px]">
                                            Dynamic Environment Customization
                                        </h5>
                                        <p className="font-[400] text-[18px]">
                                            Explore an array of tools to
                                            customize landscapes, weather
                                            conditions, and urban layouts,
                                            making each metaverse city uniquely
                                            yours.
                                        </p>
                                        <button
                                            onClick={props.demoController}
                                            className="mt-10 max-md:mt-5 bg-blue-100 font-[700] px-10 py-3 text-white transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-gradient-to-l rounded-md mr-4 bg-gradient-to-r from-blue-500 to-purple-600"
                                        >
                                            Book a Demo
                                        </button>
                                    </div>
                                    <div className="relative">
                                        <video
                                            autoPlay
                                            muted
                                            loop
                                            playsInline
                                            className="absolute rounded-lg top-0 left-0 w-full h-full object-cover"
                                        >
                                            <source
                                                src="./images/vrworld/background.webm"
                                                type="video/webm"
                                            />
                                            Your browser does not support the
                                            video tag.
                                        </video>
                                        <div className="absolute inset-0 bg-black opacity-50 rounded-lg"></div>
                                    </div>
                                </div>
                                <div className="rounded-lg bg-[#1C2347] w-full h-[400px] max-md:h-auto grid grid-cols-2 my-5 max-md:block">
                                    <div className="relative">
                                        <video
                                            autoPlay
                                            muted
                                            loop
                                            playsInline
                                            className="absolute rounded-lg top-0 left-0 w-full h-full object-cover"
                                        >
                                            <source
                                                src="./images/vrworld/background.webm"
                                                type="video/webm"
                                            />
                                            Your browser does not support the
                                            video tag.
                                        </video>
                                        <div className="absolute inset-0 bg-black opacity-50 rounded-lg"></div>
                                    </div>
                                    <div className="p-10 rounded-lg text-white">
                                        <h5 className="font-[600] text-[48px] leading-[56px] pb-10 max-md:pb-2 max-md:text-[36px] max-md:leading-[36px]">
                                            Advanced Building Tools
                                        </h5>
                                        <p className="font-[400] text-[18px]">
                                            Utilize state-of-the-art building
                                            tools to construct intricate
                                            architectures, from skyscrapers to
                                            historical landmarks, with ease and
                                            precision.
                                        </p>
                                        <button
                                            onClick={props.demoController}
                                            className="mt-10 max-md:mt-5 bg-blue-100 font-[700] px-10 py-3 text-white transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-gradient-to-l rounded-md mr-4 bg-gradient-to-r from-blue-500 to-purple-600"
                                        >
                                            Book a Demo
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div
                        className="bg-cover p-24 max-md:p-10 text-center"
                        style={{
                            backgroundImage: `url('./images/vrworld/backdrop2.webp')`,
                        }}
                    >
                        <div className="text-white max-w-screen-2xl mx-auto max-md:px-2 p-36 max-md:p-4">
                            <h3 className="text-3xl px-24 max-xl:px-0">
                                Explore, Learn, and Get Inspired
                            </h3>
                            <p className="py-10 px-36 max-xl:px-2 max-xl:py-5 font-['roboto']">
                                View a gallery of metaverse cities designed by
                                our users, showcasing the versatility and
                                creativity that VR World enables. Access a
                                wealth of resources, tutorials, and community
                                tips to inspire and guide your creations.
                            </p>

                            <button
                                onClick={props.demoController}
                                className="mt-10 bg-blue-100 font-[700] px-10 py-3 text-white transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-gradient-to-l rounded-md mr-4 bg-gradient-to-r from-blue-500 to-purple-600"
                            >
                                Get your own city
                            </button>
                        </div>
                    </div>
                    <div className="bg-[#F5FAFF]">
                        <div className="max-w-screen-2xl mx-auto max-md:px-2">
                            <h5 className="py-24 max-md:py-20 text-center leading-[36px] text-[48px] font-[600]">
                                Seamless User Experience
                            </h5>
                            <div className="grid grid-cols-2 py-10 max-md:py-5 px-2 max-lg:block">
                                <div className="shadow-xl rounded-lg mr-5 max-2xl:w-auto max-lg:mb-5 max-lg:mr-0 h-[300px] py-5 px-4 bg-white hover:scale-110 max-md:hover:scale-100 duration-500 text-[#1C2347]">
                                    <img
                                        src="./images/vrworld/i1.webp"
                                        className="pb-5 max-md:pb-2"
                                        alt=""
                                    />
                                    <h5 className="font-[600] text-[30px] max-md:text-[20px] pb-7 max-md:pb-2">
                                        Intuitive Interface for All Skill Levels
                                    </h5>
                                    <p className="font-[400] text-[18px] max-md:text-[14px]">
                                        Whether you are a seasoned designer or
                                        new to virtual world creation, VR
                                        World’s user-friendly interface makes
                                        the design process intuitive and
                                        enjoyable.
                                    </p>
                                </div>
                                <div className="shadow-xl rounded-lg max-2xl:w-auto h-[300px] py-5 px-4 bg-white hover:scale-110 max-md:hover:scale-100 duration-500 text-[#1C2347]">
                                    <img
                                        src="./images/vrworld/i2.webp"
                                        className="pb-5 max-md:pb-2"
                                        alt=""
                                    />
                                    <h5 className="font-[600] text-[30px] max-md:text-[20px] pb-7 max-md:pb-2">
                                        Real-Time Collaboration Tools
                                    </h5>
                                    <p className="font-[400] text-[18px] max-md:text-[14px]">
                                        Collaborate in real-time with team
                                        members or clients within the virtual
                                        space, enhancing teamwork and project
                                        development.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div
                        className="bg-cover font-['roboto'] h-screen max-md:h-auto"
                        style={{
                            backgroundImage: `url('./images/dashboardsectionfive/backdrop.webp')`,
                        }}
                    >
                        <div className="max-md:mx-2 max-w-screen-2xl px-2 mx-auto py-20 max-md:py-5">
                            <div className="grid grid-cols-2 max-md:grid-cols-1 items-center text-left">
                                <div className="py-20 max-md:py-5">
                                    <h5 className="text-white font-[700] text-[48px] max-md:text-[35px] max-md:leading-[45px] leading-[72px] pb-10 pr-24 max-md:pr-2 max-md:pb-2">
                                        Connect with the Creators of Tomorrow:
                                        Reach Out to Invincible Meta Today"
                                    </h5>
                                </div>
                                <div>
                                    <div className="py-20 flex justify-center max-md:block max-md:px-2 max-md:py-5 max-xl:px-5 max-md:px-1 max-xl:py-5">
                                        <div className="border shadow-2xl bg-white">
                                            <div className="pt-10">
                                                <h5 className="text-[#1C2347] text-[30px] leading-[30px] text-left px-5 max-md:px-2 pb-5 max-md:pb-2">
                                                    Send us your query
                                                </h5>
                                            </div>
                                            <form>
                                                <div className="flex max-md:block">
                                                    <div className="w-full md:w-1/2 pl-5 max-md:px-2">
                                                        <label
                                                            className="text-xs font-medium text-[#1C2347] text-[14px] leading-[16px] font-[500]"
                                                            htmlFor="name"
                                                        >
                                                            Name
                                                            <span className="text-xs text-pink">
                                                                *
                                                            </span>
                                                        </label>
                                                        <input
                                                            className="w-full text-grey bg-[#E7E7E7DB] text-sm font-normal border border-grey-500 rounded-md py-3 px-5 max-md:px-2 mb-3 focus:outline-none"
                                                            id="grid-name"
                                                            type="text"
                                                            placeholder="Enter name"
                                                            required
                                                        />
                                                    </div>
                                                    <div className="w-full md:w-1/2 pl-2 pr-5 max-md:px-2">
                                                        <label
                                                            className="text-xs font-medium text-[#1C2347] text-[14px] leading-[16px] font-[500]"
                                                            htmlFor="grid-companyName"
                                                        >
                                                            Company name
                                                        </label>
                                                        <input
                                                            className="w-full text-grey bg-[#E7E7E7DB] text-sm font-normal border border-grey-500 rounded-md py-3 px-5 max-md:px-2 focus:outline-none"
                                                            id="grid-companyName"
                                                            type="text"
                                                            placeholder="Enter company name"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="flex max-md:block">
                                                    <div className="w-full md:w-1/2 pl-5 max-md:px-2">
                                                        <label
                                                            className="text-xs font-medium text-[#1C2347] text-[14px] leading-[16px] font-[500]"
                                                            htmlFor="grid-email"
                                                        >
                                                            E mail
                                                            <span className="text-xs text-pink">
                                                                *
                                                            </span>
                                                        </label>
                                                        <input
                                                            className="w-full bg-[#E7E7E7DB] text-grey border text-sm font-normal border-grey-500 rounded-md py-3 px-5 max-md:px-2 mb-3 focus:outline-none"
                                                            id="grid-email"
                                                            type="text"
                                                            placeholder="Enter email"
                                                            required
                                                        />
                                                    </div>
                                                    <div className="w-full md:w-1/2 pl-2 pr-5">
                                                        <label
                                                            className="text-xs font-medium text-[#1C2347] text-[14px] leading-[16px] font-[500]"
                                                            htmlFor="grid-phone"
                                                        >
                                                            Phone no.
                                                            <span className="text-xs text-pink">
                                                                *
                                                            </span>
                                                        </label>
                                                        <input
                                                            className="w-full text-grey bg-[#E7E7E7DB] text-sm font-normal border border-grey-500 rounded-md py-3 px-5 max-md:px-2 focus:outline-none"
                                                            id="grid-phone"
                                                            type="text"
                                                            placeholder="Enter Phone no."
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="px-4">
                                                    <label className="text-xs font-medium text-[#1C2347] text-[14px] leading-[16px] font-[500]">
                                                        Message
                                                        <span className="text-xs text-pink">
                                                            *
                                                        </span>
                                                    </label>
                                                    <textarea
                                                        className="w-full py-3 px-5 max-md:px-2 border bg-[#E7E7E7DB] border-grey-500 rounded-md h-40 max-md:h-20 text-sm focus:outline-none"
                                                        placeholder="Enter message here"
                                                        required
                                                    />
                                                </div>
                                                <div className="px-4">
                                                    <button className="bg-blue-100 px-16 font-[700] py-3 my-5 text-white transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-gradient-to-l rounded-md mr-4 bg-gradient-to-r from-blue-500 to-purple-600">
                                                        Explore
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section
                    className="bg-cover p-24 max-md:p-10 text-center"
                    style={{
                        backgroundImage: `url('./images/vrworld/backdrop.webp')`,
                    }}
                >
                    <div className="bg-gradient-to-b from-[#0B0B0BAB] via-[#3D324391] to-[#0B0B0BAB] rounded-xl border-[1px] border-white text-white max-w-screen-2xl mx-auto max-md:px-2 p-24 max-md:p-4">
                        <h3 className="text-3xl px-24 max-xl:px-0">
                            Transform Your Vision into a Virtual Reality
                        </h3>
                        <p className="py-10 px-36 max-xl:px-2 max-xl:py-5 font-['roboto']">
                            Ready to create your own VR city? Schedule a
                            consultation with our experts and embark on an
                            unforgettable journey of innovation and creativity.
                        </p>

                        <button
                            onClick={props.loginController}
                            className="border-2 bg-[#FFF8F840] text-white max-md:mt-2 transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-[#FFF8F860] px-8 py-3 rounded-md"
                        >
                            Login to Eternity City
                            <i className="fa fa-long-arrow-right ml-4"></i>
                        </button>
                    </div>
                </section>
            </div>
        </>
    );
}
