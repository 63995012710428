import { IMessageViewProps } from "./Message.interfaces";

export default function MessageView(props: IMessageViewProps) {
    return (
        <>
            {props.messageText && (
                <div className="message-container">
                    {props.messageClass === "error" && (
                        <div className="error-message-box">
                            <div>
                                <img
                                    src="../images/circles.svg"
                                    alt="circle-times-o"
                                />
                                <div>
                                    <h4>Error</h4>
                                    <h5>{props.messageText}</h5>
                                </div>
                                <p onClick={() => props.demoController()}>
                                    Close
                                </p>
                            </div>
                        </div>
                    )}
                    {props.messageClass === "success" && (
                        <div className="success-message-box">
                            <div>
                                <img
                                    src="../images/check.svg"
                                    alt="circle-check-o"
                                />
                                <div>
                                    <h4>Success</h4>
                                    <h5>{props.messageText}</h5>
                                </div>
                                <p onClick={() => props.demoController()}>
                                    Close
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
