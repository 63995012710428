import { Link } from "react-router-dom";
import { SuperAdminMetaverseViewProps } from "./SuperAdminMetaverseUser.interface";
export default function SuperAdminMetaverseUserView(
    props: SuperAdminMetaverseViewProps
) {
    return (
        <>
            <div className="text-left m-10">
                <Link to="/super-admin/metaverse-users">
                    <span className="font-['roboto'] text-sm font-semibold text-[#489BF6]">
                        {"<< Back"}
                    </span>
                </Link>
            </div>
            <div className="font-['roboto']">
                <h5 className="font-[600]">Metaverse User</h5>
                <div className="text-left mx-10">
                    <div>
                        <span className="font-[600]">Name :</span>
                        {props.user.name}
                    </div>
                    <div>
                        <span className="font-[600]">Email :</span>
                        {props.user.email}
                    </div>
                    <div
                        className={`${
                            props.user.phoneNumber
                                ? "text-black"
                                : "text-[#808080] text-[14px]"
                        }`}
                    >
                        <span className="font-[600]">Phone Number: </span>
                        {props.user.phoneNumber
                            ? props.user.phoneNumber
                            : "not available"}
                    </div>
                    <div className="my-4">
                        <span className="text-[18px] font-[600]">
                            Metaverses
                        </span>
                        {props.user.metaverses &&
                            props.user.metaverses.map(
                                (metaverse: any, index: number) => (
                                    <Link
                                        to={`/super-admin/metaverse/${metaverse.metaverseID._id}`}
                                    >
                                        <div
                                            key={index}
                                            className="w-full border-2 gap-2 p-4"
                                        >
                                            <div>
                                                <span className="font-[600]">
                                                    Name:
                                                </span>{" "}
                                                {metaverse.metaverseID.name}
                                            </div>
                                            <div>
                                                <span className="font-[600]">
                                                    Description:
                                                </span>{" "}
                                                {
                                                    metaverse.metaverseID
                                                        .description
                                                }
                                            </div>
                                        </div>
                                    </Link>
                                )
                            )}
                    </div>
                </div>
            </div>
        </>
    );
}
