import Navigation from "../Navigation";
import { IVrRetailViewProps } from "./VrRetail.interfaces";
import BookDemo from "../BookDemo";
import UserLoginBox from "../UserLoginBox";
export default function VrRetailView(props: IVrRetailViewProps) {
    return (
        <>
            {props.showlogin === true && (
                <UserLoginBox
                    redirectionURL="/metaverse/65b741def8309762720ad53f"
                    loginController={props.loginController}
                /> // Since the line 122, says Login to Eternity City, but can be changed later => TP | 2024-01-30 14:39:44
            )}
            {props.showDemo === true && (
                <BookDemo demoController={props.demoController} />
            )}
            <div className="font-['roboto']">
                <section className="relative h-auto">
                    <video
                        autoPlay
                        muted
                        loop
                        playsInline
                        className="absolute top-0 left-0 w-full h-full object-cover"
                    >
                        <source
                            src="./images/vrretail/vrretailheadervideo.webm"
                            type="video/webm"
                        />
                        Your browser does not support the video tag.
                    </video>
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                    <Navigation />
                    <div className="relative z-10 flex flex-col items-center justify-center h-full text-white">
                        <div className="text-left max-w-screen-2xl mx-auto max-md:px-2">
                            <div className="text-center py-72 max-md:py-48">
                                <div className="mx-20 max-md:m-2 max-md:text-center">
                                    <h3 className="my-10 max-md:mt-10 max-md:mb-5 text-5xl max-md:text-2xl text-white">
                                        <span className="text-[#136CC5] bg-[#136CC5]/[0.30] shadow-inner">
                                            VR Retail
                                        </span>{" "}
                                        : Elevating the Retail Experience
                                    </h3>
                                    <p className="mb-20 max-md:mb-2 text-white mx-20 max-md:mx-2 max-md:text-xs text-[18px]">
                                        Welcome to VR Retail, where the shopping
                                        experience reaches new heights. We're
                                        excited to introduce you to a
                                        revolutionary way of exploring and
                                        purchasing products. Step into the
                                        virtual world of VR Retail and discover
                                        a fresh era of retail that's immersive,
                                        interactive, and designed to elevate
                                        your shopping journey.
                                    </p>
                                    <button
                                        onClick={props.loginController}
                                        className="border-2 bg-[#FFF8F840] text-white max-md:mt-2 transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-[#FFF8F860] px-8 py-3 rounded-md"
                                    >
                                        Login to Eternity City
                                        <i className="fa fa-long-arrow-right ml-4"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="bg-gradient-to-br from-blue-300/[.5] to-yellow-300/[.5] bg-opacity-10">
                        <div className="grid grid-cols-2 max-md:grid-cols-1 my-36 max-md:my-5 justify-items-center max-w-screen-2xl mx-auto max-md:px-2">
                            <div>
                                <img
                                    className="py-10 -mt-28 max-md:mt-2"
                                    src="./images/vrretail/woman.webp"
                                    alt=""
                                />
                            </div>
                            <div className="m-10">
                                <h3 className="text-4xl text-[#1C2347] max-md:text-center text-left">
                                    Retail Convenience Redefined
                                </h3>
                                <ol className="py-10 text-[#1C2347] font-light list-disc text-left">
                                    <li className="retail-list">
                                        Access VR Retail from anywhere, anytime.
                                        Shop without the limitations of physical
                                        store hours or locations. Your desired
                                        products are just a virtual visit away.
                                    </li>
                                    <li className="retail-list">
                                        Customize and order products directly
                                        from the virtual store
                                    </li>
                                    <li className="retail-list">
                                        Choose colors, sizes, and features to
                                        match your preferences.
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-white max-xl:mx-2 my-14 max-md:m-4 max-w-screen-xl mx-auto text-left">
                    <h1 className="text-4xl font-bold text-center text-[#1C2347]">
                        Why VR Retail?
                    </h1>
                    <p className="text-center py-5 text-[#1C2347] font-['roboto'] font-medium">
                        VR Retail isn't just another online shopping platform;
                        it's a game-changer for the retail industry. Here's why
                        you should immerse yourself in VR Retail
                    </p>
                    <div className="grid grid-cols-2 max-md:grid-cols-1 max-md:my-5 my-10">
                        <div>
                            <img
                                src="./images/vrretail/productexplore.webp"
                                alt=""
                            />
                        </div>
                        <div className="p-14 max-md:p-4">
                            <h5 className="text-2xl font-bold text-[#1C2347] py-5">
                                Immersive Product Exploration
                            </h5>
                            <p className="font-['roboto'] text-[#1C2347] font-medium">
                                Say goodbye to flat product images. VR Retail
                                brings products to life in captivating 3D
                                environments, allowing you to examine every
                                detail from every angle.
                            </p>
                            <button
                                onClick={props.demoController}
                                className="mt-10 bg-blue-100 font-[700] px-10 py-3 text-white transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-gradient-to-l rounded-md mr-4 bg-gradient-to-r from-blue-500 to-purple-600"
                            >
                                Book a Demo
                            </button>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 max-md:grid-cols-1 max-md:my-5 my-10">
                        <div className="p-14 max-md:p-4">
                            <h5 className="text-2xl font-bold text-[#1C2347] py-5">
                                Personalized Shopping Assistance
                            </h5>
                            <p className="font-['roboto'] text-[#1C2347] font-medium">
                                Say goodbye to flat product images. VR Retail
                                brings products to life in captivating 3D
                                environments, allowing you to examine every
                                detail from every angle.
                            </p>
                            <button
                                onClick={props.demoController}
                                className="mt-10 bg-blue-100 font-[700] px-10 py-3 text-white transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-gradient-to-l rounded-md mr-4 bg-gradient-to-r from-blue-500 to-purple-600"
                            >
                                Book a Demo
                            </button>
                        </div>
                        <div>
                            <img
                                src="./images/vrretail/personalizebuisness.webp"
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 max-md:grid-cols-1 max-md:my-5 my-10">
                        <div>
                            <img
                                src="./images/vrretail/interactiveshopping.webp"
                                alt=""
                            />
                        </div>
                        <div className="p-14 max-md:p-4">
                            <h5 className="text-2xl font-bold text-[#1C2347] py-5">
                                Interactive Shopping
                            </h5>
                            <p className="font-['roboto'] text-[#1C2347] font-medium">
                                Interact with products as if you were holding
                                them in your hands. Try on virtual clothing,
                                inspect electronics up close, and get a feel for
                                how products fit into your life
                            </p>
                            <button
                                onClick={props.demoController}
                                className="mt-10 bg-blue-100 font-[700] px-10 py-3 text-white transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-gradient-to-l rounded-md mr-4 bg-gradient-to-r from-blue-500 to-purple-600"
                            >
                                Book a Demo
                            </button>
                        </div>
                    </div>
                </section>
                <section className="">
                    <div className="my-10 bg-gradient-to-br from-red-500/[.7] to-blue-600/[.7]">
                        <div className="max-w-screen-2xl mx-auto max-md:px-2 grid grid-cols-2 max-md:grid-cols-1">
                            <div className="border-r-[12px] border-r-[#1C2347]">
                                <img
                                    className="border-r-[20px] border-r-[#136CC5] w-full"
                                    src="./images/vrretail/retailrev.png"
                                    alt=""
                                />
                            </div>
                            <div className="p-14 text-left">
                                <h5 className="text-4xl font-bold text-white py-5">
                                    Join the Retail Revolution
                                </h5>
                                <p className="font-['roboto'] text-white font-medium">
                                    VR Retail is more than just an online
                                    shopping platform; it's a retail experience
                                    that's redefining how you discover and
                                    purchase products. Join us on this exciting
                                    journey into the virtual retail world, where
                                    convenience and informed decision-making are
                                    our top priorities.
                                </p>
                                <button
                                    onClick={props.demoController}
                                    className="mt-10 bg-blue-100 font-[700] px-10 py-3 text-white transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-gradient-to-l rounded-md mr-4 bg-gradient-to-r from-blue-500 to-purple-600"
                                >
                                    Book a Demo
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="m-20 max-md:m-4 bg-white text-[#1C2347]">
                    <div className="text-center">
                        <h3 className="text-4xl pt-10">
                            Your Virtual Shopping Experience
                        </h3>
                        <p className="mx-48 my-5 max-md:mx-4 font-['roboto']">
                            With VR Retail, you'll experience
                        </p>
                    </div>
                    <div className="grid xl:grid-rows-3 xl:grid-cols-[auto_auto] grid-cols-1 justify-items-center justify-center">
                        <div className="div-cards-vrretail">
                            <img
                                src="./images/vrretail/logos/virtualstore.svg"
                                alt=""
                            />
                            <h5>Virtual Store Visits</h5>
                            <p>
                                Explore virtual stores filled with a wide range
                                of products from top brands
                            </p>
                        </div>
                        <div className="div-cards-vrretail">
                            <img
                                src="./images/vrretail/logos/tryon.svg"
                                alt=""
                            />
                            <h5>Virtual Try-On</h5>
                            <p>
                                Try on clothing, accessories, and cosmetics
                                virtually to find your perfect style
                            </p>
                        </div>
                        <div className="div-cards-vrretail">
                            <img
                                src="./images/vrretail/logos/productin.svg"
                                alt=""
                            />
                            <h5>Interactive Product Interaction</h5>
                            <p>
                                Interact with products to get a sense of their
                                functionality and features.
                            </p>
                        </div>
                        <div className="div-cards-vrretail">
                            <img
                                src="./images/vrretail/logos/productinfo.svg"
                                alt=""
                            />
                            <h5>Instant Product Information </h5>
                            <p>
                                Access detailed product information, pricing,
                                and reviews right when you need it
                            </p>
                        </div>
                        <div className="div-cards-vrretail">
                            <img
                                src="./images/vrretail/logos/livechat.svg"
                                alt=""
                            />
                            <h5>Live Chat Support</h5>
                            <p>
                                Chat with virtual sales representatives for
                                real-time assistance and guidance.
                            </p>
                        </div>
                        <div className="div-cards-vrretail">
                            <img
                                src="./images/vrretail/logos/shopper.svg"
                                alt=""
                            />
                            <h5>Virtual Shopper Community</h5>
                            <p>
                                Connect with fellow shoppers in the virtual
                                community, share shopping experiences, and get
                                recommendations.
                            </p>
                        </div>
                    </div>
                </section>
                <section className="max-md:p-4 text-center">
                    <div className="bg-gradient-to-t from-[#136CC5]/[67%] to-[#136CC5]/[77%] text-white">
                        <div className="max-w-screen-2xl mx-auto max-md:px-2 p-24 max-md:p-4">
                            <h3 className="text-3xl px-24 max-md:px-4">
                                Ready to Elevate Your Shopping Experience?
                            </h3>
                            <p className="p-10 px-36 max-md:p-4 font-['roboto']">
                                Take the first step into the future of retail
                                with VR Retail. Start your virtual shopping
                                adventure today and discover a new dimension of
                                retail therapy. Your elevated retail experience
                                begins here, at VR Retail.
                            </p>

                            <button
                                onClick={props.loginController}
                                className="border-2 bg-[#FFF8F840] text-white max-md:mt-2 transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-[#FFF8F860] px-8 py-3 rounded-md"
                            >
                                Login to Eternity City
                                <i className="fa fa-long-arrow-right ml-4"></i>
                            </button>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}
