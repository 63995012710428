import BookDemo from "../BookDemo";
import { INavigationViewProps } from "./Navigation.interfaces";
import { Link } from "react-router-dom";
export default function NavigationView(props: INavigationViewProps) {
    return (
        <>
            {props.showDemo === true && (
                <BookDemo demoController={props.demoController} />
            )}
            {props.showMenu === true && (
                <div className="font-['roboto'] h-screen w-screen hidden max-md:block fixed z-[9999]">
                    <div
                        className="bg-[rgba(0,0,0,.8)] h-screen w-screen z-[99999] absolute"
                        onClick={props.menuController}
                    >
                        <div className="flex text-white justify-end">
                            <div
                                className="w-[30px] bg-[#6B6B6B7a] my-10 mx-2 max-[330px]:mx-1 rounded-full flex justify-center cursor-pointer"
                                onClick={props.menuController}
                            >
                                <i className="fa fa-close text-xl cursor-pointer"></i>
                            </div>
                        </div>
                    </div>
                    <div className="absolute bg-gradient-to-b from-blue-500 to-purple-600 h-screen z-[999999] py-2 w-[280px]">
                        <div className="bg-white rounded-xl mx-2 my-[1rem] flex justify-center">
                            <Link onClick={() => props.scrollToTop} to="/">
                                <img
                                    src="../images/darklogo.webp"
                                    className="max-w-[200px] py-[1rem] cursor-pointer"
                                    alt=""
                                />
                            </Link>
                        </div>
                        <div className="h-full mt-10 text-left text-white font-roboto font-semibold">
                            <ul>
                                <li className="py-4 border-b-[1px] border-b-[#3798b5cc]">
                                    <Link
                                        onClick={() => props.scrollToTop}
                                        to="/"
                                    >
                                        <h5 className="cursor-pointer px-2 font-[400] cursor-pointer">
                                            <i className="fa fa-home text-[16px] pr-2"></i>{" "}
                                            Home
                                        </h5>
                                    </Link>
                                </li>

                                <li className="py-4 border-b-[1px] border-b-[#3798b5cc] group">
                                    <div
                                        className="flex items-center justify-between cursor-pointer"
                                        onClick={props.dropdownController}
                                    >
                                        <h5 className="px-2 font-[400]">
                                            <i className="fa fa-gears text-[16px] pr-2"></i>{" "}
                                            Products
                                        </h5>
                                        {!props.showDropdown && (
                                            <i className="fa fa-angle-down pr-2"></i>
                                        )}
                                        {props.showDropdown && (
                                            <i className="fa fa-angle-up pr-2"></i>
                                        )}
                                    </div>
                                    {props.showDropdown && (
                                        <ul className="p-4">
                                            <Link
                                                onClick={() =>
                                                    props.scrollToTop
                                                }
                                                to="/vrworld"
                                            >
                                                <li className="py-3 cursor-pointer border-b-[1px] border-b-[#3798b5cc] mx-2 hover:bg-[#0B0B0BAB] hover:rounded-xl px-2 font-[400]">
                                                    <i className="fa fa-globe pr-2 text-[14px]"></i>{" "}
                                                    Vr World
                                                </li>
                                            </Link>
                                            <Link
                                                onClick={() =>
                                                    props.scrollToTop
                                                }
                                                to="/vrbank"
                                            >
                                                <li className="py-3 cursor-pointer border-b-[1px] border-b-[#3798b5cc] mx-2 hover:bg-[#0B0B0BAB] hover:rounded-xl px-2 font-[400]">
                                                    <i className="fa fa-bank pr-2 text-[14px]"></i>
                                                    Vr Bank
                                                </li>
                                            </Link>
                                            <Link
                                                onClick={() =>
                                                    props.scrollToTop
                                                }
                                                to="/vrauto"
                                            >
                                                <li className="py-3 cursor-pointer border-b-[1px] border-b-[#3798b5cc] mx-2 hover:bg-[#0B0B0BAB] hover:rounded-xl px-2 font-[400]">
                                                    <i className="fa fa-automobile text-[14px] pr-2"></i>{" "}
                                                    Vr Auto
                                                </li>
                                            </Link>
                                            <Link
                                                onClick={() =>
                                                    props.scrollToTop
                                                }
                                                to="/vrretail"
                                            >
                                                <li className="py-3 cursor-pointer mx-2 hover:bg-[#0B0B0BAB] hover:rounded-xl px-2 font-[400]">
                                                    <i className="fa fa-shopping-bag pr-2 text-[14px]"></i>
                                                    Vr Retail
                                                </li>
                                            </Link>
                                        </ul>
                                    )}
                                </li>
                                <li className="py-4 border-b-[1px] border-b-[#3798b5cc]">
                                    <Link
                                        onClick={() => props.scrollToTop}
                                        to="/industries"
                                    >
                                        <h5 className="px-2 font-[400] cursor-pointer">
                                            <i className="fa fa-industry text-[14px] pr-2"></i>{" "}
                                            Industries
                                        </h5>
                                    </Link>
                                </li>
                                <li className="py-4 border-b-[1px] border-b-[#3798b5cc]">
                                    <Link
                                        onClick={() => props.scrollToTop}
                                        to="/aboutus"
                                    >
                                        <h5 className="px-2 font-[400] cursor-pointer">
                                            <i className="fa fa-group text-[14px] pr-2"></i>
                                            About Us
                                        </h5>
                                    </Link>
                                </li>
                                <li className="py-4 border-b-[1px] border-b-[#3798b5cc]">
                                    <Link
                                        onClick={() => props.scrollToTop}
                                        to="/contactus"
                                    >
                                        <h5 className="px-2 font-[400] cursor-pointer">
                                            <i className="fa fa-phone text-[16px] pr-2"></i>{" "}
                                            Contact Us
                                        </h5>
                                    </Link>
                                </li>
                                <li className="py-4">
                                    <Link
                                        onClick={() => props.scrollToTop}
                                        to="/faq"
                                    >
                                        <h5 className="px-2 font-[400] cursor-pointer">
                                            {" "}
                                            <i className="fa fa-question-circle-o text-[16px] pr-2"></i>{" "}
                                            FAQ
                                        </h5>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )}
            <div
                className={`fixed w-full z-[999] ${
                    props.showProducts
                        ? "bg-black"
                        : props.scroll
                          ? "bg-black"
                          : ""
                }`}
                id="navbar"
            >
                <div
                    className={`font-['roboto'] flex max-w-screen-2xl px-2 mx-auto max-md:mx-2 pt-10 pb-10 justify-between font-[600] max-lg:text-[14px] items-center text-white`}
                >
                    <div>
                        <Link onClick={() => props.scrollToTop} to="/">
                            <img
                                src="../images/whitelogo.webp"
                                className="max-w-[250px]"
                                alt=""
                            />
                        </Link>
                    </div>
                    <div className="flex max-md:hidden">
                        <div
                            className="px-4 max-lg:px-2 flex items-center cursor-pointer"
                            onClick={() => props.productController()}
                        >
                            <p
                                className={`pr-2 ${
                                    props.showProducts
                                        ? "border-b-[1px] border-b-white"
                                        : ``
                                }`}
                            >
                                Products
                            </p>
                            {!props.showProducts && (
                                <i className="fa fa-angle-down"></i>
                            )}
                            {props.showProducts && (
                                <i className="fa fa-angle-up -mt-1"></i>
                            )}
                        </div>
                        <Link onClick={() => props.scrollToTop} to="/aboutus">
                            <p className="px-4 max-lg:px-2 cursor-pointer">
                                About us
                            </p>
                        </Link>
                        <Link onClick={() => props.scrollToTop} to="/contactus">
                            <p className="px-4 max-lg:px-2 cursor-pointer">
                                Contact us
                            </p>
                        </Link>
                        <Link onClick={() => props.scrollToTop} to="/faq">
                            <p className="px-4 max-lg:px-2 cursor-pointer">
                                FAQs
                            </p>
                        </Link>
                        <Link
                            onClick={() => props.scrollToTop}
                            to="/industries"
                        >
                            <p className="px-4 max-lg:px-2 cursor-pointer">
                                Industries
                            </p>
                        </Link>
                    </div>
                    <div className="flex items-center max-md:hidden cursor-pointer">
                        <button
                            className="bg-white rounded text-black px-8 max-lg:px-4 py-3 transition duration-300 ease-in-out hover:scale-[1.05] rounded-md"
                            onClick={props.demoController}
                        >
                            Enroll to Eternity
                        </button>
                    </div>
                    <div
                        className="hidden max-md:block cursor-pointer"
                        onClick={props.menuController}
                    >
                        <i className="fa fa-bars"></i>
                    </div>
                </div>
            </div>
            {props.showProducts === true && (
                <div
                    className="h-screen fixed w-screen z-[999999]"
                    onClick={props.productController}
                >
                    <div className="w-full max-md:hidden text-white text-left font-['roboto'] justify-between fixed mt-28 z-20">
                        <div className="bg-black border-t-[1px] border-t-[#3C3C3C] left-0 right-0 px-2">
                            <div className="flex max-w-screen-2xl px-4 max-lg:px-0 mx-auto max-md:mx-2">
                                <Link
                                    onClick={() => props.scrollToTop}
                                    to="/vrworld"
                                >
                                    <div className="py-3 max-[1000px]:px-1">
                                        <div className="border-r-[1px] border-r-[#6B6B6B] px-4 max-lg:px-1">
                                            <div className="grid grid-cols-[60px_auto] items-center hover:rounded-lg px-2 hover:bg-[#262525] py-1">
                                                <div className="px-1">
                                                    <img
                                                        src="../images/navigation/p1.webp"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="px-5 max-lg:px-2">
                                                    <h5 className="font-[600] pb-2 max-lg:pb-1 text-[18px] max-lg:text-[18px] leading-[25px]">
                                                        VR World
                                                    </h5>
                                                    <p className="font-[300] text-[14px] max-lg:text-[12px] leading-[18px]">
                                                        Explore a limitless
                                                        virtual cityscape in VR
                                                        World.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                <Link
                                    onClick={() => props.scrollToTop}
                                    to="/vrbank"
                                >
                                    <div className="py-3 max-[1000px]:px-1">
                                        <div className="border-r-[1px] border-r-[#6B6B6B] px-4 max-lg:px-1">
                                            <div className="grid grid-cols-[60px_auto] items-center hover:rounded-lg px-2 pl-2 hover:bg-[#262525] py-1">
                                                <div className="px-1">
                                                    <img
                                                        src="../images/navigation/p2.webp"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="px-5 max-lg:px-2">
                                                    <h5 className="font-[600] pb-2 max-lg:pb-1 text-[18px] max-lg:text-[18px] leading-[25px]">
                                                        VR Bank
                                                    </h5>
                                                    <p className="font-[300] text-[14px] max-lg:text-[12px] leading-[18px]">
                                                        Bank seamlessly in an
                                                        immersive virtual
                                                        setting with VR Bank.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                <Link
                                    onClick={() => props.scrollToTop}
                                    to="/vrauto"
                                >
                                    <div className="py-3 max-[1000px]:px-1">
                                        <div className="border-r-[1px] border-r-[#6B6B6B] px-4 max-lg:px-1">
                                            <div className="grid grid-cols-[60px_auto] items-center hover:rounded-lg px-2 pl-2 hover:bg-[#262525] py-1">
                                                <div className="px-1">
                                                    <img
                                                        src="../images/navigation/p3.webp"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="px-5 max-lg:px-2">
                                                    <h5 className="font-[600] pb-2 max-lg:pb-1 text-[18px] max-lg:text-[18px] leading-[25px]">
                                                        VR Auto
                                                    </h5>
                                                    <p className="font-[300] text-[14px] max-lg:text-[12px] leading-[18px]">
                                                        Experience virtual car
                                                        showrooms like never
                                                        before in VR Auto.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                <Link
                                    onClick={() => props.scrollToTop}
                                    to="/vrretail"
                                >
                                    <div className="py-3 max-[1000px]:px-1">
                                        <div className="px-4 max-lg:px-1">
                                            <div className="grid grid-cols-[60px_auto] items-center hover:rounded-lg px-2 pl-2 hover:bg-[#262525] py-1">
                                                <div className="px-1">
                                                    <img
                                                        src="../images/navigation/p4.webp"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="px-5 max-lg:px-2">
                                                    <h5 className="font-[600] pb-2 max-lg:pb-1 text-[18px] max-lg:text-[18px] leading-[25px]">
                                                        VR Retail
                                                    </h5>
                                                    <p className="font-[300] text-[14px] max-lg:text-[12px] leading-[18px]">
                                                        Shop in a dynamic
                                                        virtual marketplace with
                                                        VR Retail.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
